import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  isAllInputNotTouched,
  toNumber,
  toString,
} from "../../shared/utils/InputUtil";
import {
  getAllFoods,
  getAllMedidaCaseiraForFood,
} from "../../shared/services/AutocompleteService";
import NumberInput from "../../shared/components/NumberInput";
import AutocompleteCustomInput from "../../shared/components/AutocompleteCustomInput";
import { DietaRefeicaoRequest } from "../models/IDietaRequest";
import { v4 as uuidv4 } from "uuid";
import DividerWithMiddleText from "../../shared/components/DividerWithMiddleText";

export interface IDietaCardapioDialogDto {
  id?: string;
  dietaRefeicaoId: string;
  alimento: any;
  medidaCaseira?: any;
  qtdMedCas?: number;
  qtdGramas: number;
}

interface DietaRefeicaoAlimentoDialogProps {
  open: boolean;
  tipo: string;
  refeicao: DietaRefeicaoRequest;
  alimento?: IDietaCardapioDialogDto;
  onAdd: (alimento: IDietaCardapioDialogDto) => void;
  onClose: () => void;
}

interface IFormValues {
  tipo: string;
  alimentoId: any | null;
  medidaCaseiraId: any | null;
  tamanho: string;
  qtdGramas: string;
}

const schema = yup.object().shape({
  alimentoId: yup.object().required("Campo obrigatório"),
  qtdGramas: yup.string(),
  tamanho: yup.string(),
  medidaCaseiraId: yup.object().nullable(),
});

const DietaRefeicaoAlimentoDialog: React.FC<
  DietaRefeicaoAlimentoDialogProps
> = ({ open, tipo, refeicao, alimento, onAdd, onClose }) => {
  const onSubmit = (values: IFormValues) => {
    if (!values.alimentoId) {
      return;
    }

    onAdd({
      id: alimento ? alimento.id! : uuidv4(),
      dietaRefeicaoId: refeicao.id!,
      alimento: values.alimentoId!,
      medidaCaseira: values.medidaCaseiraId,
      qtdMedCas: values.tamanho ? toNumber(values.tamanho) : undefined,
      qtdGramas: toNumber(values.qtdGramas),
    });
    formik.resetForm();
  };

  const initialValues: IFormValues = {
    tipo: tipo,
    alimentoId: alimento
      ? { id: alimento.alimento.id, nome: alimento.alimento.nome }
      : null,
    qtdGramas:
      alimento && alimento.qtdGramas ? toString(alimento.qtdGramas) : "",
    medidaCaseiraId:
      alimento && alimento.medidaCaseira
        ? { id: alimento.medidaCaseira.id, nome: alimento.medidaCaseira.nome }
        : null,
    tamanho: alimento && alimento.qtdMedCas ? toString(alimento.qtdMedCas) : "",
  };

  const formik = useFormik<IFormValues>({
    initialValues,
    validationSchema: schema,
    onSubmit,
  });

  const onAddAndClose = async () => {
    await formik.submitForm();
    onClose();
    formik.resetForm();
  };

  useEffect(() => {
    formik.setFieldValue("tipo", tipo);
  }, [tipo]);

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        {refeicao &&
          `Refeição - ${refeicao.nome} - ${refeicao.horario} - Alimentos`}
      </DialogTitle>
      <DialogContent>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12}>
            <AutocompleteCustomInput
              name="alimentoId"
              label="Alimento"
              required
              formik={formik}
              resource={() => getAllFoods()}
              onChange={(oldValue, newValue) =>
                formik.setFieldValue("medidaCaseiraId", null)
              }
            />
          </Grid>
          {formik.values.tipo !== "LIVRE" && (
            <Grid item md={4} xs={12} mt={5}>
              <NumberInput
                label="Tamanho"
                name="tamanho"
                formik={formik}
                disabled={!formik.values.alimentoId}
              />
            </Grid>
          )}
          {formik.values.tipo !== "LIVRE" && (
            <Grid item md={8} xs={12} mt={5}>
              <AutocompleteCustomInput
                name="medidaCaseiraId"
                label="Medida caseira"
                formik={formik}
                disabled={!formik.values.alimentoId}
                resource={() =>
                  getAllMedidaCaseiraForFood(formik.values.alimentoId!.id)
                }
              />
            </Grid>
          )}
          {formik.values.tipo !== "LIVRE" && (
            <Grid item xs={12}>
              <DividerWithMiddleText>
                <Typography variant="body2" color="text.secondary">
                  OU
                </Typography>
              </DividerWithMiddleText>
            </Grid>
          )}
          <Grid item md={4} xs={12}>
            <NumberInput
              label="Quantidade"
              name="qtdGramas"
              formik={formik}
              disabled={!formik.values.alimentoId}
              endAdornment="g"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isAllInputNotTouched(formik) || !formik.isValid}
          onClick={() => onAddAndClose()}
          style={{ color: "white" }}
        >
          Adicionar e fechar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isAllInputNotTouched(formik) || !formik.isValid}
          onClick={() => formik.submitForm()}
          style={{ color: "white" }}
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DietaRefeicaoAlimentoDialog;
