import { Checkbox, Paper, TableCell, TableRow } from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import ContainerCustom from "../../shared/components/ContainerCustom";
import React, { useEffect } from "react";
import TableCustom from "../../shared/components/TableCustom";
import ListAppBar from "../../medidaCaseira/components/ListAppBar";
import { useNavigate } from "react-router-dom";
import { AgrupadorRecomendacao } from "../models/AgrupadorRecomendacao";
import {
  deleteAllAgrupadorRecomendacao,
  getAllAgrupadorRecomendacao,
} from "../services/OrientationService";
import {
  CADASTRAR_CONFIGURACAO_ORIENTACAO,
  EDITAR_CONFIGURACAO_ORIENTACAO,
} from "../../shared/components/AppRoutes";
import { useTableFilter } from "../../shared/hooks/TableFilterHook";
import { ODataResult } from "../../shared/models/ODataResult";
import { usePage } from "../../shared/hooks/PageHook";
import FilterRecomendacaoDialog, {
  IRecomendacaoFilter,
} from "../dialogs/FilterRecomendacaoDialog";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";
import { getEntry } from "../../shared/utils/CrudService";

const OrientationListPage = () => {
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const [recomendacoes, setRecomendacoes] = React.useState<
    AgrupadorRecomendacao[]
  >([]);
  const [isFilterDialogOpened, setIsFilterDialogOpened] =
    React.useState<boolean>(false);
  const [isSelectingMode, setIsSelectingMode] = React.useState<boolean>(false);
  const { filters, onAddFilter, onRemoveFilter, generateFilterString } =
    useTableFilter();
  const [selecteds, setSelecteds] = React.useState<string[]>([]);

  const { page, count, rowsPerPage, setRowsPerPage, setCount, setPage } =
    usePage();

  const handleSelect = (id: string) => {
    let newSelecteds: string[] = [];

    if (selecteds.includes(id)) {
      newSelecteds = selecteds.filter((item) => item !== id);
      setSelecteds(newSelecteds);
    } else {
      newSelecteds = [...selecteds, id];
      setSelecteds(newSelecteds);
    }

    if (newSelecteds.length === 0) {
      isSelectingMode && setIsSelectingMode(false);
    } else {
      !isSelectingMode && setIsSelectingMode(true);
    }
  };

  const handleSelectOrDesselectAll = (checked: boolean) => {
    if (checked) {
      setSelecteds(recomendacoes.map((recomendacao) => recomendacao.id!));
      !isSelectingMode && setIsSelectingMode(true);
    } else {
      setSelecteds([]);
      isSelectingMode && setIsSelectingMode(false);
    }
  };

  const handleEdit = () => {
    navigate(EDITAR_CONFIGURACAO_ORIENTACAO(selecteds[0]!));
  };

  const handleDelete = () => {
    if (selecteds.length === 0) return;

    deleteAllAgrupadorRecomendacao(selecteds)
      .then(() => {
        notifySuccess("Recomendações excluídas com sucesso.");
        setPage(1);
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  const loadPage = (filter?: IRecomendacaoFilter) => {
    const searchParams = new URLSearchParams();

    filter?.nome && searchParams.append("nome", filter.nome);

    searchParams.append("skip", (page * rowsPerPage).toString());
    searchParams.append("take", rowsPerPage.toString());

    getEntry<ODataResult<AgrupadorRecomendacao[]>>(
      "agrupadorRecomendacao",
      searchParams
    )
      .then((data: ODataResult<AgrupadorRecomendacao[]>) => {
        if (data.count !== count) {
          setCount(data.count);
        }

        setRecomendacoes(data.data);
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  useEffect(() => {
    loadPage();
  }, [page, rowsPerPage, filters]);

  useEffect(() => {
    loadPage();
  }, []);

  return (
    <ContainerCustom>
      <Paper elevation={0}>
        <ListAppBar
          title="Minhas Recomendações"
          isEditEnabled={isSelectingMode}
          numSelecteds={selecteds.length}
          removeTitle="Deseja realmente excluir as orientações selecionadas?"
          removeMessage="Essa operação não poderá ser desfeita."
          onDelete={handleDelete}
          onFilter={() => setIsFilterDialogOpened(true)}
          menuActions={selecteds.length === 1 ? [
            {
              title: "Adicionar",
              onClick: () => {
                navigate(CADASTRAR_CONFIGURACAO_ORIENTACAO);
              },
              icon: <Add color="action" />,
            },
            {
              title: "Editar",
              onClick: () => handleEdit(),
              icon: <Edit color="action" />,
            },
          ] : [
            {
              title: "Adicionar",
              onClick: () => {
                navigate(CADASTRAR_CONFIGURACAO_ORIENTACAO);
              },
              icon: <Add color="action" />,
            },
          ]}
        />

        <TableCustom
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(page) => setPage(page)}
          onRowsPerPageChange={(page) => {
            setRowsPerPage(page);
          }}
          rowCount={recomendacoes.length}
          selectedCouunt={selecteds.length}
          emptyText={"Nenhuma orientação encontrada."}
          headers={[
            { id: "nome", label: "Nome" },
            { id: "descricao", label: "Descrição" },
          ]}
          onDesselectAll={() => handleSelectOrDesselectAll(false)}
          onSelectAll={() => handleSelectOrDesselectAll(true)}
        >
          {recomendacoes.map((recomendacao) => {
            const isItemSelected = selecteds.includes(recomendacao.id!);

            return (
              <TableRow
                key={recomendacao.id}
                hover
                onClick={(event) => handleSelect(recomendacao.id!)}
                selected={isItemSelected}
                role="checkbox"
                sx={{ cursor: "pointer" }}
              >
                <TableCell>
                  <Checkbox color="primary" checked={isItemSelected} />
                </TableCell>
                <TableCell>{recomendacao.titulo}</TableCell>
                <TableCell>{recomendacao.subTitulo || "-"}</TableCell>
              </TableRow>
            );
          })}
        </TableCustom>
      </Paper>

      <FilterRecomendacaoDialog
        open={isFilterDialogOpened}
        onClose={() => setIsFilterDialogOpened(false)}
        onFilter={(filter) => {
          loadPage(filter);
        }}
      />
    </ContainerCustom>
  );
};

export default OrientationListPage;
