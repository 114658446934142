import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import TextInput from "../../shared/components/TextInput";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";

interface FilterPatientDialogProps {
  id?: string;
  open: boolean;
  onFilter: (filter: IPatientFilter) => void;
  onClose: () => void;
}

export interface IPatientFilter {
  nome?: string;
  sexo?: string;
  dataNascimento?: string;
}

const FilterPatientDialog: React.FC<FilterPatientDialogProps> = ({
  id,
  open,
  onFilter,
  onClose,
}) => {
  const [filterForm, setFilterForm] = React.useState<{
    nome: string;
    sexo: string;
    dataNascimento: string;
  }>({
    nome: "",
    sexo: "",
    dataNascimento: "",
  });

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => onClose()}>
      <DialogTitle variant="h6" color="secondary">
        Filtre por:
      </DialogTitle>
      <DialogContent>
        <Grid container p={1} spacing={1} rowSpacing={2}>
          <Grid item xs={12}>
            <TextInput
              name="nome"
              label="Nome"
              required={false}
              value={filterForm.nome}
              onChange={(e) => setFilterForm({ ...filterForm, nome: e })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Sexo</FormLabel>
              <RadioGroup
                value={filterForm.sexo}
                onChange={(e, newValue) =>
                  setFilterForm({ ...filterForm, sexo: newValue })
                }
              >
                <FormControlLabel
                  label="Feminino"
                  value="FEMININO"
                  control={<Radio />}
                />
                <FormControlLabel
                  label="Masculino"
                  value="MASCULINO"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <DesktopDatePicker
              name="nome"
              label="Data de nascimento"
              format="DD/MM/YYYY"
              value={
                filterForm.dataNascimento
                  ? moment(filterForm.dataNascimento)
                  : null
              }
              onChange={(e) =>
                setFilterForm({
                  ...filterForm,
                  dataNascimento: e ? e.toISOString() : "",
                })
              }
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onFilter({});
            setFilterForm({
              ...filterForm,
              nome: "",
              sexo: "",
              dataNascimento: "",
            });
            onClose();
          }}
        >
          Limpar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onFilter({
              nome: filterForm.nome,
              sexo: filterForm.sexo,
              dataNascimento: filterForm.dataNascimento,
            });
            setFilterForm({
              ...filterForm,
              nome: "",
              sexo: "",
              dataNascimento: "",
            });
            onClose();
          }}
          style={{ color: "white" }}
        >
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterPatientDialog;
