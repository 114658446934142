import React, { useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { AppBar, CssBaseline, InputBase } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
// import AppBar from "../components/AppBar";
import { Outlet } from "react-router-dom";
import { Search } from "@mui/icons-material";

import UserMenu from "../components/UserMenu";
import BreadcrumbCustom from "../components/BreadcrumbCustom";
import Drawer from "../components/Drawer";
import AdBanner from "../components/AdBanner";
import { useDetectAdBlock } from "adblock-detect-react";
import AdBlockDialog from "../dialogs/AdBlockDialog";

interface IActionMenu {
  key: string;
  isDivider?: boolean;
  icon?: JSX.Element;
  title?: string;
  path?: string;
  children?: IActionMenu[];
}

export default function Dashboard() {
  const [isDrawerOpened, setIsDrawerOpened] = React.useState(false);
  const [isAdBlockModalOpened, setIsAdBlockModalOpened] = useState(false);

  const adBlockDetected = useDetectAdBlock();

  const toggleDrawer = () => {
    setIsDrawerOpened(!isDrawerOpened);
  };

  if (adBlockDetected) {
    if (!isAdBlockModalOpened) {
      setIsAdBlockModalOpened(true);
    }
  }

  if (!adBlockDetected) {
    if (isAdBlockModalOpened) {
      setIsAdBlockModalOpened(false);
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CssBaseline />
      <AppBar position="absolute" elevation={0} color="primary">
        <Toolbar>
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(isDrawerOpened && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ color: "white" }} />
          </IconButton>
          <InputBase
            placeholder="Pesquise no aplicativo..."
            sx={{ color: "white" }}
          />
          <IconButton sx={{ ml: 2 }}>
            <Search sx={{ color: "white" }} />
          </IconButton>
          <div style={{ flexGrow: 1 }}></div>
          {/* <Typography
            component="h1"
            variant="h6"
            color="secondary"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {currentTitle}
          </Typography> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UserMenu />
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="primary">
                <NotificationsIcon color="secondary" />
              </Badge>
            </IconButton> */}
          </div>
        </Toolbar>
      </AppBar>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "calc(100vh - 64px)",
          overflowY: "auto",
          overflowX: "hidden",
          width: "100%",
          mt: "64px",
          backgroundColor: (theme) => theme.palette.grey[100],
        }}
      >
        {/* <Toolbar /> */}
        <BreadcrumbCustom />
        <Box
          sx={{
            mt: 2,
            position: "relative",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        >
          <Outlet />
          <AdBanner />
        </Box>
        {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3} marginLeft={0.5}>
            
          </Grid>
        </Container> */}
      </Box>

      <AdBlockDialog open={isAdBlockModalOpened} />

      <Drawer
        open={isDrawerOpened}
        setOpen={(open) => setIsDrawerOpened(open)}
        toggleDrawer={toggleDrawer}
      />
    </Box>
  );
}
