import { useAuth } from "../hooks/AuthHook";
import { redirect } from "react-router-dom";

interface RoleGuardProps {
    permit: string[];
    redirectIfNotPermit?: string;
    children: React.ReactNode;
}

export function RoleGuard({ children, permit, redirectIfNotPermit }: RoleGuardProps) {
  const { userLogged } = useAuth();

  const hasRole = (): boolean => {
    const user = userLogged();

    if (!user) {
      return false;
    }

    for (const role of permit) {
      if (user.roles.includes(role)) {
        return true;
      }
    }
    
    return false;
  }

  if (!hasRole()) {
    if (redirectIfNotPermit) {
        redirect(redirectIfNotPermit);
        return <></>;
    }
    else {
        return <></>;
    }
  }

  return <>{children}</>;
}