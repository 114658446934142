import { ODataResult } from "../../shared/models/ODataResult";
import api from "../../shared/utils/Api";
import { AvaliacaoType } from "../components/NovaAvaliacaoButton";
import { IAnamneseResponse } from "../models/IAnamneseResponse";
import { ICreateAntropometriaRequest } from "../models/ICreateAntropometriaRequest";
import { DietaRequest } from "../models/IDietaRequest";
import { DietaResponse } from "../models/IDietaResponse";
import { ExameQuimicoReferencia } from "../models/IExameQuimicoReferencia";
import { IGetAllExameFisicoSintomasResponse } from "../models/IGetAllExameFisicoSintomasResponse";
import { IGetAvaliacaoConsultaDashboardResponse } from "../models/IGetAvaliacaoConsultaDashboardResponse";
import { IUpdateExameFisicoRequest } from "../models/IUpdateExameFisicoRequest";
import {
  ExameQuimicoSolicitadoODataResponse,
  ExameQuimicoSolicitadoRequest,
  UpdateExameQuimicoRequest,
} from "../models/IUpdateExameQuimicoRequest";
import { IAnamneseResposta } from "../pages/EvaluationAnamnesePage";

export const getAllEvalutions = async (
  consultaId: string
): Promise<IGetAvaliacaoConsultaDashboardResponse[]> => {
  try {
    const response = await api.get<IGetAvaliacaoConsultaDashboardResponse[]>(
      `/avaliacao/${consultaId}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Falha ao buscar avaliações");
  }
};

export const createRecomendacao = async (request: {
  consultaId: string;
  agregadorRecomendacaoId: string;
}): Promise<string> => {
  try {
    const response = await api.post<string>(`recomendacao`, request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao salvar recomendacao");
  }
};

export const getRecomendacaoById = async (id: string) => {
  try {
    const response = await api.get<any>(`recomendacao/${id}/odata`, {
      params: {
        $expand: `agrupadorRecomendacao($expand=recomendacoes)`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Falha ao salvar recomendacao");
  }
};

export const createAntropometria = async (consultaId: string) => {
  try {
    const response = await api.post<string>(`antropometria`, {
      consultaId: consultaId,
    });
    return response.data;
  } catch (error) {
    throw new Error("Falha ao criar antropometria");
  }
};

export const saveAntropometria = async (
  id: string,
  request: ICreateAntropometriaRequest
) => {
  try {
    const response = await api.put(`antropometria/${id}`, {
      ...request,
      id: id,
    });
    return response.data;
  } catch (error) {
    throw new Error("Falha ao salvar antropometria");
  }
};

export const deleteEvaluation = async (type: AvaliacaoType, id: string) => {
  if (type === AvaliacaoType.ANAMNESE) {
    try {
      const response = await api.delete(`anamnese/${id}`);
      return response.data;
    } catch (error) {
      throw new Error("Falha ao deletar anamnese");
    }
  } else if (type === AvaliacaoType.EXAME_FISICO) {
    try {
      console.log("ExameFísico");
      const response = await api.delete(`exameFisico/${id}`);
      return response.data;
    } catch (error) {
      throw new Error("Falha ao deletar exame físico");
    }
  } else if (type === AvaliacaoType.EXAME_LABORATORIAL) {
    try {
      const response = await api.delete(`exameQuimico/${id}`);
      return response.data;
    } catch (error) {
      throw new Error("Falha ao deletar exame químico");
    }
  } else if (type === AvaliacaoType.COMPOSICAO_CORPORAL) {
    try {
      const response = await api.delete(`antropometria/${id}`);
      return response.data;
    } catch (error) {
      throw new Error("Falha ao deletar antropometria");
    }
  } else if (type === AvaliacaoType.PLANO_ALIMENTAR_LIVRE || type === AvaliacaoType.PLANO_ALIMENTAR_EQUIVALENTES) {
    try {
      const response = await api.delete(`dieta/${id}`);
      return response.data;
    } catch (error) {
      throw new Error("Falha ao deletar dieta");
    }
  } else if (type === AvaliacaoType.RECOMENDACOES) {
    try {
      const response = await api.delete(`recomendacao/${id}`);
      return response.data;
    } catch (error) {
      throw new Error("Falha ao deletar recomendação");
    }
  }
};

export const deleteAntropometria = async (id: string) => {
  try {
    const response = await api.delete(`antropometria/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao deletar antropometria");
  }
};

export const getAntropometriaById = async (id: string) => {
  try {
    const response = await api.get<ICreateAntropometriaRequest>(
      `antropometria/${id}/odata`
    );
    return response.data;
  } catch (error) {
    throw new Error("Falha ao consultar antropometria");
  }
};

export const createDieta = async (consultaId: string, tipo: string) => {
  try {
    const response = await api.post<string>(`dieta`, {
      consultaId: consultaId,
      tipo: tipo,
    });
    return response.data;
  } catch (error) {
    throw new Error("Falha ao criar dieta");
  }
};

export const saveDieta = async (id: string, request: DietaRequest) => {
  try {
    const response = await api.put(`dieta/${id}`, {
      ...request,
      id: id,
    });
    return response.data;
  } catch (error) {
    throw new Error("Falha ao salvar dieta");
  }
};

export const getDietaById = async (id: string) => {
  try {
    const response = await api.get<DietaResponse>(`dieta/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao consultar dieta");
  }
};

export const createAnamnese = async (request: {
  consultaId: string;
  agregadorPerguntaId: string;
}): Promise<string> => {
  try {
    const response = await api.post<string>(`anamnese`, request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao salvar anamnese");
  }
};

export const updateAnamnese = async (request: {
  id: string;
  respostas: IAnamneseResposta[];
}) => {
  try {
    const response = await api.put(`anamnese/${request.id}`, {
      id: request.id,
      respostas: request.respostas,
    });
    return response.data;
  } catch (error) {
    throw new Error("Falha ao salvar anamnese");
  }
};

export const getAnamneseById = async (id: string) => {
  try {
    const response = await api.get<IAnamneseResponse>(`anamnese/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao consultar analmese");
  }
};

export const createExameQuimico = async (request: {
  consultaId: string;
}): Promise<string> => {
  try {
    const response = await api.post<string>(`exameQuimico`, request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao criar exame laboratorial");
  }
};

export const createExameFisico = async (request: {
  consultaId: string;
}): Promise<string> => {
  try {
    const response = await api.post<string>(`exameFisico`, request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao criar exame fisico");
  }
};

export const updateExameFisico = async (
  id: string,
  request: IUpdateExameFisicoRequest
) => {
  try {
    const response = await api.put(`exameFisico/${id}`, request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao atualizar exame fisico");
  }
};

export const getAllExameFisicoSintomas = async (exameFisicoId: string) => {
  try {
    const response = await api.get<IGetAllExameFisicoSintomasResponse>(
      `exameFisico/${exameFisicoId}/sintomas`,
      {
        params: {
          exameFisicoId: exameFisicoId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Falha ao consultar exame fisico sintomas");
  }
};

export const getAllExameQuimicoReferencia = async () => {
  try {
    const response = await api.get<ExameQuimicoReferencia[]>(
      `exameQuimicoReferencia`
    );
    return response.data;
  } catch (error) {
    throw new Error("Falha ao consultar exame quimico referencia");
  }
};

export const getAllExameQuimico = async (id: string) => {
  try {
    const response = await api.get<
      ODataResult<ExameQuimicoSolicitadoODataResponse[]>
    >(`exameQuimicoSolicitado/odata`, {
      params: {
        $filter: `exameQuimicoId eq ${id}`,
        $expand: `exameQuimicoReferencia`,
      },
    });
    return response.data.data;
  } catch (error) {
    throw new Error("Falha ao consultar exame quimico");
  }
};

export const updateExameQuimico = async (
  id: string,
  request: UpdateExameQuimicoRequest
) => {
  try {
    const response = await api.put(`exameQuimico/${id}`, {
      ...request,
      id: id,
    });
    return response.data;
  } catch (error) {
    throw new Error("Falha ao atualizar exame quimico");
  }
};

export const deleteExameQuimico = async (
  id: string
) => {
  try {
    const response = await api.delete(`exameQuimicoSolicitado/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao remover exame quimico");
  }
};
