import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IRegisterUserRequest } from "../models/RegisterUserRequest";
import AuthService from "../services/AuthService";
import { APP, LOGIN } from "../../shared/components/AppRoutes";
import ErrorDialog from "../../shared/dialogs/ErrorDialog";
import React from "react";
import YupPassword from "yup-password";
import { Divider } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useAuth } from "../../shared/hooks/AuthHook";
import { Login } from "@mui/icons-material";

YupPassword(Yup); // extend yup

const schema = Yup.object().shape({
  primeiroNome: Yup.string().required("Campo obrigatório"),
  ultimoNome: Yup.string().required("Campo obrigatório"),
  email: Yup.string().email().required("Campo obrigatório"),
  senha: Yup.string()
    .password()
    .required("Campo obrigatória")
    .min(6, "A senha deve ter no mínimo 6 caracteres")
    .minLowercase(1, "A senha deve ter no mínimo 1 letra minúscula")
    .minUppercase(1, "A senha deve ter no mínimo 1 letra maiúscula")
    .minSymbols(1, "A senha deve ter no mínimo 1 caractere especial")
    .minNumbers(1, "A senha deve ter no mínimo 1 número"),
  marketing: Yup.boolean(),
});

export default function SignUpPage() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<string | null>(null);
  const { singin, isAuthenticated, isRemembered } = useAuth();

  const navigate = useNavigate();

  const openDialog = (message: string) => {
    setDialogMessage(message);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogMessage(null);
    setDialogOpen(false);
  };

  const onSubmit = (values: IRegisterUserRequest) => {
    AuthService.register(values)
      .then(() => {
        navigate(LOGIN);
      })
      .catch((error: Error) => {
        openDialog(error.message);
      });
  };

  const validate = (values: IRegisterUserRequest) => {};

  const intitialValues: IRegisterUserRequest = {
    email: "",
    senha: "",
    primeiroNome: "",
    ultimoNome: "",
    marketing: false,
  };

  const formik = useFormik<IRegisterUserRequest>({
    validationSchema: schema,
    initialValues: intitialValues,
    onSubmit: onSubmit,
    validate: validate,
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      AuthService.loginGoogle(codeResponse.access_token)
        .then((response) => {
          singin({
            email: response.email,
            primeiroNome: response.primeiroNome,
            ultimoNome: response.ultimoNome,
            token: response.token,
            expiresIn: new Date(response.expiresIn),
            isRemembered: false,
            roles: response.roles,
          });

          navigate(APP);
        })
        .catch((error: Error) => {
          openDialog(error.message);
        });
    },
    onError: (error) => {
      openDialog(error.error || "Erro ao fazer login");
    },
  });

  return (
    // <Box
    //   sx={{
    //     height: "100vh",
    //   }}
    // >
    //   <Container component="main" maxWidth="xs">
    //     <Box
    //       sx={{
    //         display: "flex",
    //         flexDirection: "column",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Box
    //         sx={{
    //           marginTop: 8,
    //           display: "flex",
    //           flexDirection: "column",
    //           alignItems: "center",
    //         }}
    //       >
    //         <img
    //           src={process.env.PUBLIC_URL + "/diety-logo.png"}
    //           alt="diety-logo"
    //           style={{ width: "10rem" }}
    //         />
    //       </Box>
    //       <Box
    //         component="form"
    //         noValidate
    //         onSubmit={formik.handleSubmit}
    //         sx={{ mt: 1 }}
    //       >
    // <Grid container spacing={2}>
    //   <Grid item xs={12} sm={6}>
    //     <TextField
    //       variant="outlined"
    //       autoComplete="given-name"
    //       name="primeiroNome"
    //       required
    //       fullWidth
    //       label="Primeiro nome"
    //       color="primary"
    //       value={formik.values.primeiroNome}
    //       onBlur={formik.handleBlur}
    //       onChange={formik.handleChange}
    //       error={
    //         formik.touched.primeiroNome &&
    //         Boolean(formik.errors.primeiroNome)
    //       }
    //       helperText={
    //         formik.touched.primeiroNome && formik.errors.primeiroNome
    //       }
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6}>
    //     <TextField
    //       variant="outlined"
    //       required
    //       fullWidth
    //       label="Último nome"
    //       name="ultimoNome"
    //       color="primary"
    //       autoComplete="family-name"
    //       value={formik.values.ultimoNome}
    //       onBlur={formik.handleBlur}
    //       onChange={formik.handleChange}
    //       error={
    //         formik.touched.ultimoNome &&
    //         Boolean(formik.errors.ultimoNome)
    //       }
    //       helperText={
    //         formik.touched.ultimoNome && formik.errors.ultimoNome
    //       }
    //     />
    //   </Grid>
    //   <Grid item xs={12}>
    //     <TextField
    //       variant="outlined"
    //       required
    //       fullWidth
    //       label="E-mail"
    //       color="primary"
    //       name="email"
    //       autoComplete="email"
    //       value={formik.values.email}
    //       onBlur={formik.handleBlur}
    //       onChange={formik.handleChange}
    //       error={formik.touched.email && Boolean(formik.errors.email)}
    //       helperText={formik.touched.email && formik.errors.email}
    //     />
    //   </Grid>
    //   <Grid item xs={12}>
    //     <TextField
    //       variant="outlined"
    //       required
    //       fullWidth
    //       name="senha"
    //       label="Senha"
    //       type="password"
    //       color="primary"
    //       autoComplete="new-password"
    //       value={formik.values.senha}
    //       onBlur={formik.handleBlur}
    //       onChange={formik.handleChange}
    //       error={formik.touched.senha && Boolean(formik.errors.senha)}
    //       helperText={formik.touched.senha && formik.errors.senha}
    //     />
    //   </Grid>
    //   <Grid item xs={12}>
    //     <FormControlLabel
    //       control={
    //         <Checkbox value="allowExtraEmails" color="primary" />
    //       }
    //       label="Quero receber inspirações, promoções e atualizações via email."
    //       style={{ textAlign: "justify" }}
    //     />
    //   </Grid>
    // </Grid>
    // <Button
    //   type="submit"
    //   fullWidth
    //   disabled={!formik.isValid}
    //   variant="contained"
    //   sx={{ mt: 3, mb: 2, mr: 2 }}
    //   style={{ boxShadow: "none" }}
    // >
    //   <Typography color="white">Registre-se</Typography>
    // </Button>
    // <Grid container justifyContent="flex-end">
    //   <Grid item>
    //     <Link
    //       component={RouterLink}
    //       to={LOGIN}
    //       variant="body2"
    //       color="secondary"
    //     >
    //       Já possui uma conta? Faça o login
    //     </Link>
    //   </Grid>
    // </Grid>
    //       </Box>
    //     </Box>
    //   </Container>
    // <ErrorDialog
    //   open={dialogOpen}
    //   message={dialogMessage ?? ""}
    //   onClose={closeDialog}
    // />
    // </Box>
    <Box sx={{ height: "100vh", backgroundColor: "#fffbfb" }}>
      <Grid container component="main" sx={{ height: "100%" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              pr: 10,
              pl: 10,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                mt: "7vh",
                mb: "4vh",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/diety-logo.png"}
                alt="login-logo"
                style={{ width: "10rem" }}
              />
            </Box>
            <Box
              sx={{
                mt: "3vh",
                mb: "7vh",
              }}
            >
              <Typography variant="h4" fontWeight="bold" textAlign="center">
                Crie sua conta.
              </Typography>
            </Box>
            <Button
              // fullWidth
              variant="text"
              style={{
                boxShadow: "none",
                padding: "0.5rem 5rem",
                border: "solid 0.5px #8F8F8F",
                borderRadius: "14px",
              }}
              // startIcon={<GoogleIcon color="error" />}
              onClick={() => googleLogin()}
            >
              <Typography sx={{ color: "#0D0D0D" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: "100%",
                    flexWrap: "nowrap",
                    gap: 4,
                  }}
                >
                  <span style={{ marginRight: "0.5rem" }}>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M31.6879 16.3686C31.6879 15.2809 31.5997 14.1874 31.4115 13.1174H16.3198V19.2787H24.9621C24.6035 21.2659 23.4512 23.0237 21.7639 24.1408V28.1386H26.9199C29.9476 25.3519 31.6879 21.2365 31.6879 16.3686Z"
                        fill="#4285F4"
                      />
                      <path
                        d="M16.32 32.0011C20.6352 32.0011 24.2744 30.5842 26.9259 28.1385L21.7699 24.1407C20.3354 25.1166 18.4835 25.6693 16.3259 25.6693C12.1517 25.6693 8.61246 22.8532 7.34257 19.067H2.02197V23.1883C4.73812 28.5912 10.2704 32.0011 16.32 32.0011Z"
                        fill="#34A853"
                      />
                      <path
                        d="M7.33654 19.0671C6.66632 17.08 6.66632 14.9282 7.33654 12.9411V8.81982H2.02183C-0.24751 13.3409 -0.24751 18.6673 2.02183 23.1884L7.33654 19.0671Z"
                        fill="#FBBC04"
                      />
                      <path
                        d="M16.32 6.33288C18.6011 6.29761 20.8057 7.15596 22.4578 8.73156L27.0258 4.16349C24.1333 1.44734 20.2943 -0.0459547 16.32 0.00107822C10.2704 0.00107822 4.73812 3.41096 2.02197 8.81974L7.33669 12.941C8.6007 9.14897 12.1458 6.33288 16.32 6.33288Z"
                        fill="#EA4335"
                      />
                    </svg>
                  </span>
                  <div style={{ fontSize: "1.25rem" }}>
                    <span style={{ textTransform: "capitalize" }}>Entrar</span>{" "}
                    <span style={{ textTransform: "lowercase" }}>com o</span>{" "}
                    <span style={{ textTransform: "capitalize" }}>Google</span>
                  </div>
                </div>
              </Typography>
            </Button>
            <Box
              component="form"
              noValidate
              onSubmit={formik.handleSubmit}
              sx={{ mt: 1 }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  my: 3,
                }}
              >
                <Divider
                  sx={{
                    flexGrow: 1,
                    borderColor: "#8F8F8F",
                    borderWidth: 0.5,
                  }}
                />
                <Typography
                  sx={{
                    mx: 2,
                    fontSize: "1rem",
                    color: "#8F8F8F",
                  }}
                >
                  OU
                </Typography>
                <Divider
                  sx={{
                    flexGrow: 1,
                    borderColor: "#8F8F8F",
                    borderWidth: 0.5,
                  }}
                />
              </Box>
              {/* <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="E-mail"
                name="email"
                color="primary"
                autoComplete="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextInput
                required
                name="password"
                label="Senha"
                password
                margin="normal"
                formik={formik}
              />
              <Grid container mt={1} alignItems="center">
                <Grid item xs>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={rememberMe}
                        onChange={(e, checked) => setRememberMe(checked)}
                        color="primary"
                      />
                    }
                    label="Salvar credenciais"
                  />
                </Grid>
                <Grid item>
                  <Link
                    to={FORGOT_PASSWORD}
                    component={RouterLink}
                    variant="body2"
                    // color="secondary"
                    style={{ textDecoration: "none" }}
                  >
                    <span style={{ color: "#0D0D0D", fontSize: "1rem" }}>
                      Esqueceu a senha?
                    </span>
                  </Link>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!formik.isValid}
                sx={{ mt: 2, mb: 2 }}
                style={{ boxShadow: "none" }}
                startIcon={<Login sx={{ color: "white" }} />}
              >
                <Typography color="white">Entrar</Typography>
              </Button>
              <Grid container mt={1} textAlign="center">
                <Grid item xs>
                  <Link
                    component={RouterLink}
                    to={REGISTER}
                    variant="body2"
                    // color="#0D0D0D"
                    style={{ textDecoration: "none", fontSize: "1rem" }}
                  >
                    <span style={{ color: "#0D0D0D" }}>Não tem uma conta?</span>
                    <span> Registre-se</span>
                  </Link>
                </Grid>
              </Grid> */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    autoComplete="given-name"
                    name="primeiroNome"
                    required
                    fullWidth
                    label="Primeiro nome"
                    color="primary"
                    value={formik.values.primeiroNome}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.primeiroNome &&
                      Boolean(formik.errors.primeiroNome)
                    }
                    helperText={
                      formik.touched.primeiroNome && formik.errors.primeiroNome
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Último nome"
                    name="ultimoNome"
                    color="primary"
                    autoComplete="family-name"
                    value={formik.values.ultimoNome}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.ultimoNome &&
                      Boolean(formik.errors.ultimoNome)
                    }
                    helperText={
                      formik.touched.ultimoNome && formik.errors.ultimoNome
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="E-mail"
                    color="primary"
                    name="email"
                    autoComplete="email"
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="senha"
                    label="Senha"
                    type="password"
                    color="primary"
                    autoComplete="new-password"
                    value={formik.values.senha}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.senha && Boolean(formik.errors.senha)}
                    helperText={formik.touched.senha && formik.errors.senha}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox value="allowExtraEmails" color="primary" />
                    }
                    label="Quero receber inspirações, promoções e atualizações via email."
                    style={{ textAlign: "justify" }}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                disabled={!formik.isValid}
                variant="contained"
                sx={{ mt: 3, mb: 2, mr: 2 }}
                style={{ boxShadow: "none" }}
                startIcon={<Login sx={{ color: "white" }} />}
              >
                <Typography color="white">Registre-se</Typography>
              </Button>
              <Grid container justifyContent="center">
                <Grid item>
                  <Link
                    component={RouterLink}
                    to={LOGIN}
                    variant="body2"
                    // color="secondary"
                    style={{ textDecoration: "none", fontSize: "1rem" }}
                  >
                    <span style={{ color: "#0D0D0D" }}>
                      Já possui uma conta?
                    </span>
                    <span> Faça o login</span>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            display: { xs: "none", lg: "flex" },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/sign-up-image.png"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Grid>
      </Grid>
      <ErrorDialog
        open={dialogOpen}
        message={dialogMessage ?? ""}
        onClose={closeDialog}
      />
    </Box>
  );
}
