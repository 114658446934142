import { Box, Typography } from "@mui/material";
import React from "react";

interface AntropometriaResultItemProps {
  result: string;
  title: string;
  dimension: string;
}

const AntropometriaResultItem: React.FC<AntropometriaResultItemProps> = ({
  result,
  title,
  dimension,
}) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Typography variant="body1">{title}</Typography>
      <Typography variant="body1" color="primary">
        {result} {dimension}
      </Typography>
    </Box>
  );
};

export default AntropometriaResultItem;
