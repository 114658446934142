import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import EditDietaVetDialog, {
  IEditDietaVetDialogFormValues,
} from "./EditDietaVetDialog";
import { toNumber, toString } from "../../shared/utils/InputUtil";
import NumberInput from "../../shared/components/NumberInput";
import {
  IAdequacaoGeral,
  IParametroAdequacao,
} from "../pages/EvaluationDietPage";
import { IDietaCardapioDialogDto } from "./DietaRefeicaoAlimentoDialog";

interface DietaAdequacaoGeralDialogProps {
  open: boolean;
  currentVet: number;
  currentVetAjuste: number;
  currentSinalAcum: number;
  currentVetTotal: number;
  currentParametroAdequacao: IParametroAdequacao;
  currentAdequacaoGeral: IAdequacaoGeral;
  currentAlimentos: Map<string, IDietaCardapioDialogDto[]>;
  onClose: (result: DietaAdequacaoGeralDialogResult) => void;
}

export interface DietaAdequacaoGeralDialogResult {
  vet: number;
  vetAjuste: number;
  sinalAcum: number;
  vetTotal: number;
  parametroAdequacao: IParametroAdequacao;
  adequacaoGeral: IAdequacaoGeral;
}

const DietaAdequacaoGeralDialog: React.FC<DietaAdequacaoGeralDialogProps> = ({
  open,
  currentVet,
  currentVetAjuste,
  currentSinalAcum,
  currentVetTotal,
  currentParametroAdequacao,
  currentAdequacaoGeral,
  currentAlimentos,
  onClose,
}) => {
  const [vet, setVet] = React.useState<number>(currentVet);
  const [sinalAcum, setSinalAcum] = React.useState<number>(currentSinalAcum);
  const [vetAjuste, setVetAjuste] = React.useState<number>(currentVetAjuste);
  const [vetTotal, setVetTotal] = React.useState<number>(currentVetTotal);

  const [parametroAdequacao, setParametroAdequacao] =
    React.useState<IParametroAdequacao>(currentParametroAdequacao);

  const [adequacaoGeral, setAdequacaoGeral] = React.useState<IAdequacaoGeral>(
    currentAdequacaoGeral
  );

  const [alimentos, setAlimentos] = React.useState<
    Map<string, IDietaCardapioDialogDto[]>
  >(currentAlimentos);

  const [isEditVetDialogOpened, setIsEditVetDialogOpened] =
    React.useState(false);

  const getFormValuesFromeditVet = (): IEditDietaVetDialogFormValues => {
    const formValues: IEditDietaVetDialogFormValues = {
      vet: vet ? toString(vet) : "",
      vetAjuste: vetAjuste ? toString(vetAjuste) : "",
      sinalAcum: sinalAcum
        ? sinalAcum === 0
          ? "NULL"
          : sinalAcum === 1
          ? "ADD"
          : "SUB"
        : "NULL",
    };

    return formValues;
  };

  const handleEditVet = (form: IEditDietaVetDialogFormValues) => {
    const vetConverted = form.vet ? toNumber(form.vet) : 0;
    const vetAjusteConverted = form.vetAjuste ? toNumber(form.vetAjuste) : 0;
    const sinalAcumConverted =
      form.sinalAcum === "NULL" ? 0 : form.sinalAcum === "ADD" ? 1 : -1;

    setVet(vetConverted);
    setVetAjuste(vetAjusteConverted);
    setSinalAcum(vetAjusteConverted);
    setVetTotal(vetConverted + vetAjusteConverted * sinalAcumConverted);
  };

  const onAddAndClose = async () => {
    onClose({
      vet: vet ? vet : 0,
      vetAjuste: vetAjuste ? vetAjuste : 0,
      sinalAcum: sinalAcum ? sinalAcum : 0,
      vetTotal: vetTotal ? vetTotal : 0,
      parametroAdequacao: parametroAdequacao,
      adequacaoGeral: adequacaoGeral,
    });
  };

  useEffect(() => {
    currentVet && currentVet !== vet && setVet(currentVet);
    currentVetAjuste &&
      currentVetAjuste !== vetAjuste &&
      setVetAjuste(currentVetAjuste);
    currentSinalAcum &&
      currentSinalAcum !== sinalAcum &&
      setSinalAcum(currentSinalAcum);
    currentVetTotal &&
      currentVetTotal !== vetTotal &&
      setVetTotal(currentVetTotal);
    currentParametroAdequacao &&
      currentParametroAdequacao !== parametroAdequacao &&
      setParametroAdequacao(currentParametroAdequacao);
    currentAdequacaoGeral &&
      currentAdequacaoGeral !== adequacaoGeral &&
      setAdequacaoGeral(currentAdequacaoGeral);
    currentAlimentos && currentAlimentos !== alimentos && setAlimentos(currentAlimentos);
  }, [
    currentVet,
    currentVetAjuste,
    currentSinalAcum,
    currentVetTotal,
    currentParametroAdequacao,
    currentAdequacaoGeral,
    currentAlimentos,
  ]);

  useEffect(() => {
    const alimentosList: IDietaCardapioDialogDto[] = [];

    alimentos.forEach((value: IDietaCardapioDialogDto[], key: string) => {
      alimentosList.push(...value);
    });

    let kcalTotal = 0;
    let lipTotal = 0;
    let ptnTotal = 0;
    let choTotal = 0;

    alimentosList.forEach((alim) => {
      // De acordo com as gramas do alimento vamos calcular quanto de kcal, lip, ptn e cho ele tem
      const qtdGrama = alim.medidaCaseira
        ? alim.medidaCaseira.equivalenteGramas * alim.qtdMedCas!
        : alim.qtdGramas;
      kcalTotal += (alim.alimento.kcal * qtdGrama) / 100;
      lipTotal += (alim.alimento.lipidio * qtdGrama) / 100;
      ptnTotal += (alim.alimento.proteina * qtdGrama) / 100;
      choTotal += (alim.alimento.carboidrato * qtdGrama) / 100;
    });

    const lip = (vetTotal * parseFloat(parametroAdequacao.lip)) / 100;
    const ptn = (vetTotal * parseFloat(parametroAdequacao.ptn)) / 100;
    const cho = (vetTotal * parseFloat(parametroAdequacao.cho)) / 100;

    const perKcalCalculated =
      vetTotal > 0 ? (kcalTotal / vetTotal) * 100 : 0;
    const perLipCalculated = lip > 0 ? (lipTotal / lip) * 100 : 0;
    const perPtnCalculated = ptn > 0 ? (ptnTotal / ptn) * 100 : 0;
    const perChoCalculated = cho > 0 ? (choTotal / cho) * 100 : 0;

    const newAdequacaoGeral = {
      ...adequacaoGeral,
      lip: lip,
      ptn: ptn,
      cho: cho,
      perKcal: perKcalCalculated,
      perLip: perLipCalculated,
      perPtn: perPtnCalculated,
      perCho: perChoCalculated,
    };

    setAdequacaoGeral(newAdequacaoGeral);
  }, [vetTotal, parametroAdequacao, alimentos]);

  return (
    <Dialog open={open} onClose={onAddAndClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        Adequação geral
      </DialogTitle>
      <DialogContent>
        <Grid container mt={1}>
          <Grid item xs={12}>
            <Card style={{ boxShadow: "none" }}>
              <CardHeader
                title="Parâmetros Utilizados"
                action={
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsEditVetDialogOpened(true)}
                    sx={{ width: "150px" }}
                  >
                    Editar
                  </Button>
                }
              />
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.disabled">
                      VET
                    </Typography>
                    <Typography variant="body2" color="text.disabled">
                      Ajuste
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" align="right">
                      {vet ? vet : "-"} kcal
                    </Typography>
                    <Typography variant="body1" align="right">
                      {sinalAcum === 0 ? "" : sinalAcum === -1 ? "- " : "+ "}
                      {vetAjuste ? vetAjuste : "-"} kcal
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mt={1} mb={1}>
                    <Divider color="text" />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.disabled">
                      =
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" align="right">
                      {vetTotal ? vetTotal : "-"} kcal
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Card style={{ boxShadow: "none" }}>
              <CardHeader title="Porcentagem da distribuição" />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <NumberInput
                      name="lip"
                      label="Lipídeos"
                      value={parametroAdequacao.lip}
                      required
                      endAdornment="%"
                      onChange={(e) =>
                        setParametroAdequacao({ ...parametroAdequacao, lip: e })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberInput
                      name="ptn"
                      label="Proteínas"
                      value={parametroAdequacao.ptn}
                      required
                      endAdornment="%"
                      onChange={(e) =>
                        setParametroAdequacao({ ...parametroAdequacao, ptn: e })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberInput
                      name="cho"
                      label="Carboidratos"
                      value={parametroAdequacao.cho}
                      required
                      endAdornment="%"
                      onChange={(e) =>
                        setParametroAdequacao({ ...parametroAdequacao, cho: e })
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
              <CardHeader title="Meta" />
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1">Carboidratos</Typography>
                    <Typography variant="body1">Lipídeos</Typography>
                    <Typography variant="body1">Proteínas</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" align="left">
                      {adequacaoGeral.cho ? adequacaoGeral.cho : "-"} kcal
                    </Typography>
                    <Typography variant="body1" align="left">
                      {adequacaoGeral.lip ? adequacaoGeral.lip : "-"} kcal
                    </Typography>
                    <Typography variant="body1" align="left">
                      {adequacaoGeral.ptn ? adequacaoGeral.ptn : "-"} kcal
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" align="right">
                      {adequacaoGeral.cho ? adequacaoGeral.cho / 4 : "-"} g
                    </Typography>
                    <Typography variant="body1" align="right">
                      {adequacaoGeral.lip ? adequacaoGeral.lip : "-"} g
                    </Typography>
                    <Typography variant="body1" align="right">
                      {adequacaoGeral.ptn ? adequacaoGeral.ptn : "-"} g
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
              <CardHeader title="Adequação Geral" />
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1">Carboidratos</Typography>
                    <Typography variant="body1">Lipídeos</Typography>
                    <Typography variant="body1">Proteínas</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" align="right">
                      {adequacaoGeral.perCho
                        ? adequacaoGeral.perCho.toFixed(2)
                        : "-"}
                      %
                    </Typography>
                    <Typography variant="body1" align="right">
                      {adequacaoGeral.perLip
                        ? adequacaoGeral.perLip.toFixed(2)
                        : "-"}
                      %
                    </Typography>
                    <Typography variant="body1" align="right">
                      {adequacaoGeral.perPtn
                        ? adequacaoGeral.perPtn.toFixed(2)
                        : "-"}
                      %
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1">Kcal</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" align="right">
                      {adequacaoGeral.perKcal
                        ? adequacaoGeral.perKcal.toFixed(2)
                        : "-"}
                      %
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onAddAndClose}
          sx={{ width: "150px" }}
        >
          OK
        </Button>
      </DialogActions>

      <EditDietaVetDialog
        open={isEditVetDialogOpened}
        formValues={getFormValuesFromeditVet()}
        onAdd={handleEditVet}
        onClose={() => setIsEditVetDialogOpened(false)}
      />
    </Dialog>
  );
};

export default DietaAdequacaoGeralDialog;
