import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { isAllInputNotTouched } from "../../shared/utils/InputUtil";
import InputSelect from "../../shared/components/InputSelect";
import NumberInput from "../../shared/components/NumberInput";

export interface IEditDietaVetDialogFormValues {
  vet: string;
  vetAjuste: string;
  sinalAcum: string;
}

interface EditDietaVetDialogProps {
  open: boolean;
  formValues?: IEditDietaVetDialogFormValues;
  onAdd: (form: IEditDietaVetDialogFormValues) => void;
  onClose: () => void;
}

const schema = yup.object().shape({
  vet: yup.string().required("Campo obrigatório"),
  vetAjuste: yup.string(),
  sinalAcum: yup.string(),
});

const EditDietaVetDialog: React.FC<EditDietaVetDialogProps> = ({
  open,
  formValues,
  onAdd,
  onClose,
}) => {
  const onSubmit = (values: IEditDietaVetDialogFormValues) => {
    onAdd(values);
    onClose();
    formik.resetForm();
  };

  const initialValues: IEditDietaVetDialogFormValues = {
    vet: "",
    vetAjuste: "",
    sinalAcum: "NULL",
  };

  const formik = useFormik<IEditDietaVetDialogFormValues>({
    initialValues,
    validationSchema: schema,
    onSubmit,
  });

  useEffect(() => {
    if (open && formValues) {
      formik.setValues(formValues);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        Parametros utilizados
      </DialogTitle>
      <DialogContent>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12}>
            <NumberInput
              label="Vet"
              name="vet"
              required
              formik={formik}
              endAdornment="kcal/dia"
            />
          </Grid>
          <Grid item xs={12}>
            <InputSelect
              label=""
              name="sinalAcum"
              formik={formik}
              options={[
                {
                  value: "NULL",
                  label: "Selecione",
                },
                {
                  value: "ADD",
                  label: "+",
                },
                {
                  value: "SUB",
                  label: "-",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <NumberInput
              label="Diferença"
              name="vetAjuste"
              formik={formik}
              endAdornment="kcal/dia"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isAllInputNotTouched(formik) || !formik.isValid}
          onClick={() => formik.submitForm()}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDietaVetDialog;
