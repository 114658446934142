import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import AutocompleteInput from "../../shared/components/AutocompleteInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isAllInputNotTouched } from "../../shared/utils/InputUtil";
import TextInput from "../../shared/components/TextInput";
import { createAgendamento } from "../services/ScheduleService";
import DateTimeInput from "../../shared/components/DateTimeInput";

interface ScheduleCreateModalProps {
  open: boolean;
  onClose: (reload: boolean) => void;
}

interface IFormValue {
  paciente: any;
  data: string;
  observacoes: string;
}

const schema = Yup.object().shape({
  paciente: Yup.object().required("Paciente é obrigatório"),
  data: Yup.string().required("Data é obrigatória"),
  observacoes: Yup.string(),
});

const initialValues: IFormValue = {
  paciente: "",
  data: "",
  observacoes: "",
};

const ScheduleCreateModal: React.FC<ScheduleCreateModalProps> = ({
  open,
  onClose,
}) => {
  const onSubmit = (data: IFormValue) => {
    createAgendamento({
      pacienteId: data.paciente.id,
      dataHora: data.data,
      obs: data.observacoes,
    })
      .then(() => {
        formik.resetForm();
        onClose(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formik = useFormik<IFormValue>({
    initialValues,
    validationSchema: schema,
    onSubmit,
  });

  const justClose = () => {
    formik.resetForm();
    onClose(false);
  };

  return (
    <Dialog open={open} onClose={justClose}>
      <DialogTitle>Agendar Consulta</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <AutocompleteInput
              label="Paciente"
              name="paciente"
              formik={formik}
              required
              url="paciente"
              isOData={true}
            />
          </Grid>
          <Grid item xs={12}>
            <DateTimeInput
              label="Data e hora"
              name="data"
              formik={formik}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Observações"
              name="observacoes"
              formik={formik}
              required
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={justClose} color="primary">
          Fechar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => formik.handleSubmit()}
          disabled={isAllInputNotTouched(formik) || !formik.isValid}
          style={{ color: "white" }}
        >
          Agendar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleCreateModal;
