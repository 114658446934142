import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
  },
  header: {
    margin: 10,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 5,
  },
  headerLogoContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 5,
  },
  headerLogo: {
    width: 25,
    height: 25,
  },
  headerTitle: {
    position: "relative",
    left: 0,
    right: 0,
    top: 0,
    paddingTop: 7,
    paddingBottom: 7,
    marginTop: 20,
    textAlign: "center",
    backgroundColor: "lightgrey",
    fontWeight: "bold",
  },
  section: {
    margin: 10,
    fontSize: 12,
    fontWeight: "normal",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

interface ExportRecomendacaoPdfProps {
  sexo: string;
  nome: string;
  idade: string;
  recomendacoes: string[];
}

export const ExportRecomendacaoPdf = ({
  sexo,
  nome,
  idade,
  recomendacoes,
}: ExportRecomendacaoPdfProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerContainer}>
            <View style={styles.headerLogoContainer}>
              <Image src="/diety-logo.png" style={styles.headerLogo} />
              <Text>Diety</Text>
            </View>
            <Text style={{ fontSize: 10 }}>
              Data: {new Date().toLocaleDateString()}
            </Text>
          </View>
          <Text style={styles.headerTitle}>
            Recomendações
          </Text>
        </View>

        <View
          style={{
            margin: 10,
            padding: 10,
            border: "2px solid #bbbbbb",
            flexDirection: "row",
            fontSize: 11,
            gap: 10,
          }}
        >
          <View style={{flexGrow: 1}}>
            <Text>Paciente</Text>
            <Text style={{ color: "#a3a3a3", fontSize: 12, }}>
              {nome}
            </Text>
          </View>
          <View>
            <Text>Idade</Text>
            <Text style={{ color: "#a3a3a3", fontSize: 12, }}>
              {idade} anos
            </Text>
          </View>
          <View>
            <Text>Sexo</Text>
            <Text style={{ color: "#a3a3a3", fontSize: 12, }}>
              {sexo === "M" ? "Masculino" : "Feminino"}
            </Text>
          </View>
        </View>

        {recomendacoes.map((recomendacao) => (
          <View style={styles.section}>
            <Text>- {recomendacao}</Text>
          </View>
        ))}
      </Page>
    </Document>
  );
};
