import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import { toNumber, toString } from "../../shared/utils/InputUtil";
import NumberInput from "../../shared/components/NumberInput";

interface ExameQuimicoResultadoDialogProps {
  open: boolean;
  exame?: string;
  dimension?: string;
  value?: number;
  onSave: (value: number) => void;
  onClose: () => void;
}

const ExameQuimicoResultadoDialog: React.FC<ExameQuimicoResultadoDialogProps> = ({
  open,
  value,
  exame,
  dimension,
  onSave,
  onClose,
}) => {
  const [resultado, setResultado] = React.useState<string>(toString(value));
  const [dim, setDim] = React.useState<string>(dimension || "");

  const handleSave = () => {
    onSave(toNumber(resultado));
    setResultado("");
    onClose();
  };

  useEffect(() => {
    setResultado(toString(value));
  }, [value]);

  useEffect(() => {
    setDim(dimension || "");
  }, [dimension]);

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        Resultado do exame - {exame || ""}
      </DialogTitle>
      <DialogContent>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12}>
            <NumberInput
              label="Resultado"
              name="resultado"
              value={resultado}
              onChange={(e) => setResultado(e)}
              required
              endAdornment={dim || ""}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={resultado === "" || resultado === "0"}
          onClick={handleSave}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExameQuimicoResultadoDialog;
