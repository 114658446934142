import { Box } from "@mui/material";
import React from "react";

interface IAdBannerProps {}

const AdBanner: React.FC<IAdBannerProps> = () => {
  return (
    <Box
      mt={2}
      mb={2}
      sx={{ display: { sm: "none", md: "block" }, width: "100%", mx: "auto" }}
    >
      <div id="container-9c60527158ac52456233fa189f96b7d7"></div>
    </Box>
  );
};

export default AdBanner;
