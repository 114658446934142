import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import React from "react";

export enum AvaliacaoType {
  ANAMNESE = 0,
  EXAME_FISICO = 1,
  EXAME_LABORATORIAL = 2,
  COMPOSICAO_CORPORAL = 3,
  PLANO_ALIMENTAR_LIVRE = 4,
  PLANO_ALIMENTAR_EQUIVALENTES = 5,
  RECOMENDACOES = 6,
}

interface NovaAvaliacaoButtonProps {
  onClick: (avaliacao: AvaliacaoType) => void;
}

const NovaAvaliacaoButton: React.FC<NovaAvaliacaoButtonProps> = ({
  onClick,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const menuOpen = Boolean(menuAnchorEl);

  const openMenuHandle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closeMenuHandle = () => {
    setMenuAnchorEl(null);
  };

  const handleClick = (avaliacao: AvaliacaoType) => {
    onClick(avaliacao);
    closeMenuHandle();
  };

  return (
    <Box display="inline">
      <Button
        variant="contained"
        onClick={openMenuHandle}
        style={{ color: "white" }}
      >
        Nova Avaliação
      </Button>
      <Menu open={menuOpen} anchorEl={menuAnchorEl} onClose={closeMenuHandle}>
        <MenuList>
          <MenuItem onClick={() => handleClick(AvaliacaoType.ANAMNESE)}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="Anamnese" />
          </MenuItem>
          <MenuItem onClick={() => handleClick(AvaliacaoType.EXAME_FISICO)}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="Exame Físico" />
          </MenuItem>
          <MenuItem
            onClick={() => handleClick(AvaliacaoType.EXAME_LABORATORIAL)}
          >
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="Exame Laboratorial" />
          </MenuItem>
          <MenuItem
            onClick={() => handleClick(AvaliacaoType.COMPOSICAO_CORPORAL)}
          >
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="Composição Corporal" />
          </MenuItem>
          <MenuItem
            onClick={() => handleClick(AvaliacaoType.PLANO_ALIMENTAR_LIVRE)}
          >
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="Plano Alimentar - Livre" />
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleClick(AvaliacaoType.PLANO_ALIMENTAR_EQUIVALENTES)
            }
          >
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="Plano Alimentar - Equivalentes" />
          </MenuItem>
          <MenuItem onClick={() => handleClick(AvaliacaoType.RECOMENDACOES)}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="Recomendações" />
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default NovaAvaliacaoButton;
