import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextInput from "../../shared/components/TextInput";
import NumberInput from "../../shared/components/NumberInput";
import { isAllInputNotTouched } from "../../shared/utils/InputUtil";
import { LISTAR_MEDIDA_CASEIRA } from "../../shared/components/AppRoutes";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  createMedidaCaseira,
  editMedidaCaseira,
  getMedidaCaseiraById,
} from "../services/MedidaCaseiraService";
import AutocompleteCustomInput from "../../shared/components/AutocompleteCustomInput";
import { getAllAutocomplete } from "../../shared/services/AutocompleteService";
import ContainerCustom from "../../shared/components/ContainerCustom";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";

interface IFormInput {
  id?: string;
  nome: string;
  tamanhoMedida: string;
  alimento: any;
  equivalenteGramas: string;
}

const schema = Yup.object().shape({
  nome: Yup.string().required("Medida caseira é obrigatória"),
  tamanhoMedida: Yup.string().required("Tamanho da medida é obrigatório"),
  alimento: Yup.object().required("Alimento é obrigatório"),
  equivalenteGramas: Yup.string().required(
    "Equivalente em gramas é obrigatório"
  ),
});

const defaultValues: IFormInput = {
  nome: "",
  tamanhoMedida: "1",
  alimento: undefined,
  equivalenteGramas: "",
};

const MedidaCaseiraFormPage: React.FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const onSubmit = (data: IFormInput) => {
    if (id) {
      editMedidaCaseira(id, {
        alimentoId: data.alimento.id,
        nome: data.nome,
        equivalenteGramas: parseFloat(data.equivalenteGramas),
        tamanhoMedida: data.tamanhoMedida,
      })
        .then(() => {
          notifySuccess("Medida caseira editada com sucesso!");
          navigate(LISTAR_MEDIDA_CASEIRA);
        })
        .catch((error) => {
          notifyError(error);
        });
    } else {
      createMedidaCaseira({
        alimentoId: data.alimento.id,
        nome: data.nome,
        equivalenteGramas: parseFloat(data.equivalenteGramas),
        tamanhoMedida: data.tamanhoMedida,
      })
        .then(() => {
          notifySuccess("Medida caseira cadastrada com sucesso!");
          navigate(LISTAR_MEDIDA_CASEIRA);
        })
        .catch((error) => {
          notifyError(error);
        });
    }
  };

  const formik = useFormik<IFormInput>({
    initialValues: defaultValues,
    validationSchema: schema,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (id) {
      getMedidaCaseiraById(id)
        .then((response) => {
          formik.setValues({
            id: response.id,
            nome: response.nome,
            alimento: response.alimento,
            equivalenteGramas: response.equivalenteGramas.toString(),
            tamanhoMedida: response.tamanhoMedida.toString(),
          });
        })
        .catch((error) => {
          notifyError(error);
        });
    }
  }, [id]);

  return (
    <ContainerCustom>
      <form onSubmit={formik.handleSubmit}>
        <Card style={{ boxShadow: "none" }}>
          <CardHeader
            title={id ? "Editar Medida Caseira" : "Cadastrar Medida Caseira"}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AutocompleteCustomInput
                  name="alimento"
                  label="Alimento"
                  required
                  formik={formik}
                  resource={() => getAllAutocomplete("alimento")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              {/* <Grid item xs={12} md={4}>
                <NumberInput
                  name="tamanhoMedida"
                  label="Tamanho da medida"
                  formik={formik}
                  required
                />
              </Grid> */}
              <Grid item xs={12} md={4}>
                <TextInput
                  name="nome"
                  label="Medida caseira"
                  formik={formik}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <NumberInput
                  name="equivalenteGramas"
                  label="Equivalente em gramas"
                  formik={formik}
                  required
                  endAdornment="g"
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isAllInputNotTouched(formik) || !formik.isValid}
            >
              Cadastrar
            </Button>
            <Button
              variant="text"
              color="primary"
              component={RouterLink}
              to={LISTAR_MEDIDA_CASEIRA}
              sx={{ mr: 2 }}
            >
              Voltar
            </Button>
          </CardActions>
        </Card>
      </form>
    </ContainerCustom>
  );
};

export default MedidaCaseiraFormPage;
