import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthService from "../services/AuthService";
import { useAuth } from "../../shared/hooks/AuthHook";
import ErrorDialog from "../../shared/dialogs/ErrorDialog";
import React from "react";
import {
  APP,
  FORGOT_PASSWORD,
  REGISTER,
} from "../../shared/components/AppRoutes";
import { useGoogleLogin } from "@react-oauth/google";
import { Divider } from "@mui/material";
import { Login } from "@mui/icons-material";
import TextInput from "../../shared/components/TextInput";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("E-mail deve ser válido")
    .required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatória"),
});

interface ILoginUserRequest {
  email: string;
  password: string;
}

export default function SignInSide() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<string | null>(null);
  const [rememberMe, setRememberMe] = React.useState(false);

  const { singin, isAuthenticated, isRemembered } = useAuth();
  const navigate = useNavigate();

  const openDialog = (message: string) => {
    setDialogMessage(message);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogMessage(null);
    setDialogOpen(false);
  };

  React.useEffect(() => {
    if (isAuthenticated() && isRemembered()) {
      navigate(APP);
    }
  }, []);

  const onSubmit = (values: ILoginUserRequest) => {
    AuthService.login(values.email, values.password)
      .then((response) => {
        singin({
          email: response.email,
          primeiroNome: response.primeiroNome,
          ultimoNome: response.ultimoNome,
          token: response.token,
          expiresIn: new Date(response.expiresIn),
          isRemembered: rememberMe,
          roles: response.roles,
        });

        navigate(APP);
      })
      .catch((error: Error) => {
        openDialog(error.message);
      });
  };

  const validate = (values: ILoginUserRequest) => {};

  const intitialValues: ILoginUserRequest = {
    email: "",
    password: "",
  };

  const formik = useFormik<ILoginUserRequest>({
    validationSchema: schema,
    initialValues: intitialValues,
    onSubmit: onSubmit,
    validate: validate,
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      AuthService.loginGoogle(codeResponse.access_token)
        .then((response) => {
          singin({
            email: response.email,
            primeiroNome: response.primeiroNome,
            ultimoNome: response.ultimoNome,
            token: response.token,
            expiresIn: new Date(response.expiresIn),
            isRemembered: rememberMe,
            roles: response.roles,
          });

          navigate(APP);
        })
        .catch((error: Error) => {
          openDialog(error.message);
        });
    },
    onError: (error) => {
      openDialog(error.error || "Erro ao fazer login");
    },
  });

  return (
    // <Box
    //   sx={{
    //     height: "100vh",
    //   }}
    // >
    //   <Grid container component="main" sx={{ height: "100%" }}>
    //     <Grid item xs={12} md={6}>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           flexDirection: "column",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           height: "100%",
    //           pr: 10,
    //           pl: 10,
    //         }}
    //       >
    //         <Box
    //           sx={{
    //             display: "flex",
    //             flexDirection: "column",
    //             justifyContent: "center",
    //             alignItems: "center",
    //           }}
    //         >
    //           <img
    //             src={process.env.PUBLIC_URL + "/diety-logo.png"}
    //             alt="login-logo"
    //             style={{ width: "10rem" }}
    //           />
    //         </Box>
    //         <Box
    //           component="form"
    //           noValidate
    //           onSubmit={formik.handleSubmit}
    //           sx={{ mt: 1 }}
    //         >
    //           <TextField
    //             variant="outlined"
    //             margin="normal"
    //             required
    //             fullWidth
    //             label="E-mail"
    //             name="email"
    //             color="primary"
    //             autoComplete="email"
    //             value={formik.values.email}
    //             onBlur={formik.handleBlur}
    //             onChange={formik.handleChange}
    //             error={formik.touched.email && Boolean(formik.errors.email)}
    //             helperText={formik.touched.email && formik.errors.email}
    //           />
    //           <TextField
    //             variant="outlined"
    //             margin="normal"
    //             required
    //             fullWidth
    //             name="password"
    //             label="Senha"
    //             type="password"
    //             color="primary"
    //             autoComplete="current-password"
    //             value={formik.values.password}
    //             onBlur={formik.handleBlur}
    //             onChange={formik.handleChange}
    //             error={
    //               formik.touched.password && Boolean(formik.errors.password)
    //             }
    //             helperText={formik.touched.password && formik.errors.password}
    //           />
    //           <FormControlLabel
    //             control={
    //               <Checkbox
    //                 value={rememberMe}
    //                 onChange={(e, checked) => setRememberMe(checked)}
    //                 color="primary"
    //               />
    //             }
    //             label="Salvar credenciais"
    //           />
    //           <Button
    //             type="submit"
    //             fullWidth
    //             variant="contained"
    //             disabled={!formik.isValid}
    //             sx={{ mt: 1, mb: 1 }}
    //             style={{ boxShadow: "none" }}
    //             startIcon={<Login sx={{ color: "white" }} />}
    //           >
    //             <Typography color="white">Entrar</Typography>
    //           </Button>
    //           <Button
    //             type="submit"
    //             fullWidth
    //             variant="text"
    //             color="error"
    //             style={{ boxShadow: "none" }}
    //             startIcon={<GoogleIcon color="error" />}
    //             onClick={() => googleLogin()}
    //           >
    //             <Typography color="error">Entrar com o Google</Typography>
    //           </Button>
    //           <Grid container mt={1}>
    //             <Grid item xs>
    //               <Link
    //                 to={FORGOT_PASSWORD}
    //                 component={RouterLink}
    //                 variant="body2"
    //                 color="secondary"
    //               >
    //                 Esqueceu a senha?
    //               </Link>
    //             </Grid>
    //             <Grid item>
    //               <Link
    //                 component={RouterLink}
    //                 to={REGISTER}
    //                 variant="body2"
    //                 color="secondary"
    //               >
    //                 Não tem uma conta? Registre-se
    //               </Link>
    //             </Grid>
    //           </Grid>
    //         </Box>
    //       </Box>
    //     </Grid>
    //     <Grid
    //       item
    //       xs={0}
    //       md={6}
    //       sx={{
    //         display: { xs: "none", lg: "flex" },
    //         flexDirection: "column",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <img
    //         src={process.env.PUBLIC_URL + "/login-image.png"}
    //         alt="Login logo"
    //       />
    //     </Grid>
    //   </Grid>
    //   <ErrorDialog
    //     open={dialogOpen}
    //     message={dialogMessage ?? ""}
    //     onClose={closeDialog}
    //   />
    // </Box>
    <Box sx={{ height: "100vh", backgroundColor: "#fffbfb" }}>
      <Grid container component="main" sx={{ height: "100%" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              pr: 10,
              pl: 10,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                mt: "7vh",
                mb: "3vh",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/diety-logo.png"}
                alt="login-logo"
                style={{ width: "10rem" }}
              />
            </Box>
            <Box
              sx={{
                mt: "3vh",
                mb: "7vh",
              }}
            >
              <Typography variant="h4" fontWeight="bold" textAlign="center">
                Bem-vindo de volta!
              </Typography>
            </Box>
            <Button
              // fullWidth
              variant="text"
              style={{
                boxShadow: "none",
                padding: "0.5rem 5rem",
                border: "solid 0.5px #8F8F8F",
                borderRadius: "14px",
              }}
              // startIcon={<GoogleIcon color="error" />}
              onClick={() => googleLogin()}
            >
              <Typography sx={{ color: "#0D0D0D" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: "100%",
                    flexWrap: "nowrap",
                    gap: 4,
                  }}
                >
                  <span style={{ marginRight: "0.5rem" }}>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M31.6879 16.3686C31.6879 15.2809 31.5997 14.1874 31.4115 13.1174H16.3198V19.2787H24.9621C24.6035 21.2659 23.4512 23.0237 21.7639 24.1408V28.1386H26.9199C29.9476 25.3519 31.6879 21.2365 31.6879 16.3686Z"
                        fill="#4285F4"
                      />
                      <path
                        d="M16.32 32.0011C20.6352 32.0011 24.2744 30.5842 26.9259 28.1385L21.7699 24.1407C20.3354 25.1166 18.4835 25.6693 16.3259 25.6693C12.1517 25.6693 8.61246 22.8532 7.34257 19.067H2.02197V23.1883C4.73812 28.5912 10.2704 32.0011 16.32 32.0011Z"
                        fill="#34A853"
                      />
                      <path
                        d="M7.33654 19.0671C6.66632 17.08 6.66632 14.9282 7.33654 12.9411V8.81982H2.02183C-0.24751 13.3409 -0.24751 18.6673 2.02183 23.1884L7.33654 19.0671Z"
                        fill="#FBBC04"
                      />
                      <path
                        d="M16.32 6.33288C18.6011 6.29761 20.8057 7.15596 22.4578 8.73156L27.0258 4.16349C24.1333 1.44734 20.2943 -0.0459547 16.32 0.00107822C10.2704 0.00107822 4.73812 3.41096 2.02197 8.81974L7.33669 12.941C8.6007 9.14897 12.1458 6.33288 16.32 6.33288Z"
                        fill="#EA4335"
                      />
                    </svg>
                  </span>
                  <div style={{ fontSize: "1.25rem" }}>
                    <span style={{ textTransform: "capitalize" }}>Entrar</span>{" "}
                    <span style={{ textTransform: "lowercase" }}>com o</span>{" "}
                    <span style={{ textTransform: "capitalize" }}>Google</span>
                  </div>
                </div>
              </Typography>
            </Button>
            <Box
              component="form"
              noValidate
              onSubmit={formik.handleSubmit}
              sx={{ mt: 1 }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  my: 3,
                }}
              >
                <Divider
                  sx={{
                    flexGrow: 1,
                    borderColor: "#8F8F8F",
                    borderWidth: 0.5,
                  }}
                />
                <Typography
                  sx={{
                    mx: 2,
                    fontSize: "1rem",
                    color: "#8F8F8F",
                  }}
                >
                  OU
                </Typography>
                <Divider
                  sx={{
                    flexGrow: 1,
                    borderColor: "#8F8F8F",
                    borderWidth: 0.5,
                  }}
                />
              </Box>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="E-mail"
                name="email"
                color="primary"
                autoComplete="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextInput
                required
                name="password"
                label="Senha"
                password
                margin="normal"
                formik={formik}
              />
              <Grid container mt={1} alignItems="center">
                <Grid item xs>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={rememberMe}
                        onChange={(e, checked) => setRememberMe(checked)}
                        color="primary"
                      />
                    }
                    label="Salvar credenciais"
                  />
                </Grid>
                <Grid item>
                  <Link
                    to={FORGOT_PASSWORD}
                    component={RouterLink}
                    variant="body2"
                    // color="secondary"
                    style={{ textDecoration: "none" }}
                  >
                    <span style={{ color: "#0D0D0D", fontSize: "1rem" }}>
                      Esqueceu a senha?
                    </span>
                  </Link>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!formik.isValid}
                sx={{ mt: 2, mb: 2 }}
                style={{ boxShadow: "none" }}
                startIcon={<Login sx={{ color: "white" }} />}
              >
                <Typography color="white">Entrar</Typography>
              </Button>
              <Grid container mt={1} textAlign="center">
                <Grid item xs>
                  <Link
                    component={RouterLink}
                    to={REGISTER}
                    variant="body2"
                    // color="#0D0D0D"
                    style={{ textDecoration: "none", fontSize: "1rem" }}
                  >
                    <span style={{ color: "#0D0D0D" }}>Não tem uma conta?</span>
                    <span> Registre-se</span>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            display: { xs: "none", lg: "flex" },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/login-image.png"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Grid>
      </Grid>

      <ErrorDialog
        open={dialogOpen}
        message={dialogMessage ?? ""}
        onClose={closeDialog}
      />
    </Box>
  );
}
