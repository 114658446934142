import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import NumberInput from "../../shared/components/NumberInput";

interface ParametroIngestaoHidricaDialogProps {
  open: boolean;
  initialValue?: string;
  onClose: (value?: string) => void;
}

const ParametroIngestaoHidricaDialog: React.FC<
  ParametroIngestaoHidricaDialogProps
> = ({ open, initialValue, onClose }) => {
  const [valor, setValor] = useState<string>(initialValue || "");

  const toNumber = (value: string): number => {
    return Number(value.replace(".", "").replace(",", "."));
  };

  return (
    <Dialog
      open={open}
      onClose={
        toNumber(valor) <= 0 ? undefined : () => onClose(valor)
      }
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" ml={1}>
          Selecione um valor de referencia
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <NumberInput
                name="valor"
                label="Valor"
                value={valor}
                onChange={(e) => setValor(e)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onClose(valor)}
          disabled={!valor || toNumber(valor) <= 0}
        >
          Selecionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParametroIngestaoHidricaDialog;
