import { ODataResult } from "../../shared/models/ODataResult";
import api from "../../shared/utils/Api";
import { ICreatePacienteRequest } from "../models/ICreatePacienteRequest";
import { IGetAllPacienteResponse } from "../models/IGetAllPacienteResponse";
import { IGetByIdPacienteResponse } from "../models/IGetByIdPacienteResponse";

export class PatientService {
  public static async getById(id: string): Promise<IGetByIdPacienteResponse> {
    try {
      const response = await api.get<IGetByIdPacienteResponse>(
        `paciente/${id}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Falha ao buscar informações do paciente");
    }
  }

  public static async getAll(
    filter: {
      nome?: string;
      sexo?: string;
      dataNascimento?: string;
      page: number;
      numPerPage: number;
    },
  ): Promise<ODataResult<IGetAllPacienteResponse[]>> {
    try {
      const response = await api.get<ODataResult<IGetAllPacienteResponse[]>>(
        `paciente`,
        {
          params: {
            nome: filter.nome,
            sexo: filter.sexo,
            dataNascimento: filter.dataNascimento,
            take: filter.numPerPage,
            skip: filter.page * filter.numPerPage,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error("Falha ao buscar informações do paciente");
    }
  }

  public static async create(request: ICreatePacienteRequest): Promise<void> {
    try {
      await api.post(`paciente`, request);
      return;
    } catch (error) {
      throw new Error("Falha ao criar paciente");
    }
  }

  public static async edit(
    id: string,
    request: ICreatePacienteRequest
  ): Promise<void> {
    try {
      await api.put(`paciente/${id}`, request);
      return;
    } catch (error) {
      throw new Error("Falha ao editar paciente");
    }
  }

  public static async deleteAll(ids: string[]): Promise<void> {
    try {
      await api.delete(`paciente`, {
        params: {
          ids,
        },
      });
      return;
    } catch (error) {
      throw new Error("Falha ao deletar pacientes");
    }
  }
}
