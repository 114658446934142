import { FormikProps } from "formik";
import { UseFormStateReturn } from "react-hook-form";

export const errorText = (formik: FormikProps<any>, name: string): string => {
  if (!getNestedProperty(formik.touched, name)) {
    return "";
  }

  const text = getNestedProperty(formik.errors, name);
  if (!text) {
    return "";
  }

  return text as string;
};

export const errorTextFormHook = (formState: UseFormStateReturn<any>, name: string, isTouched: boolean): string => {
  if (!isTouched) {
    return "";
  }

  const text = formState.errors[name];
  if (!text) {
    return "";
  }

  return text.message as string;
};

export const isAllInputNotTouched = (formik: FormikProps<any>): boolean => {
  return Object.keys(formik.touched).length === 0;
}

export const getNestedProperty = (obj: any, propertyPath: string) => {
  return propertyPath.split('.').reduce((acc, part) => acc && acc[part], obj);
}

export const toNumber = (value: string): number => {
  return Number(value.replace(".", "").replace(",", "."));
};

export const toString = (value: number | undefined): string => {
  if (!value) {
    return "";
  }

  return value.toFixed(2).replace(".", ",");
};