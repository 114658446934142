import { ODataResult } from "../../shared/models/ODataResult";
import api from "../../shared/utils/Api";
import { ICreateFoodRequest } from "../models/ICreateFoodRequest";
import { IGetAllFoodResponse } from "../models/IGetAllFoodResponse";
import { IGetByIdFoodResponse } from "../models/IGetByIdFoodResponse";

export class FoodService {
  public static async getById(id: string): Promise<IGetByIdFoodResponse> {
    try {
      const response = await api.get<IGetByIdFoodResponse>(`alimento/${id}`);
      return response.data;
    } catch (error) {
      throw new Error("Falha ao buscar informações do paciente");
    }
  }

  public static async getAll(filter: {
    nome?: string;
    grupo?: string;
    page: number;
    numPerPage: number;
  }): Promise<ODataResult<IGetAllFoodResponse[]>> {
    try {
      const response = await api.get<ODataResult<IGetAllFoodResponse[]>>(
        `alimento`,
        {
          params: {
            nome: filter.nome ?? null,
            tipoAlimento: filter.grupo ?? null,
            take: filter.numPerPage,
            skip: filter.page * filter.numPerPage,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error("Falha ao buscar informações do paciente");
    }
  }

  public static async create(request: ICreateFoodRequest): Promise<void> {
    try {
      await api.post(`alimento`, request);
      return;
    } catch (error) {
      throw new Error("Falha ao criar paciente");
    }
  }

  public static async edit(
    id: string,
    request: ICreateFoodRequest
  ): Promise<void> {
    try {
      await api.put(`alimento/${id}`, { id, ...request });
      return;
    } catch (error) {
      throw new Error("Falha ao editar alimento");
    }
  }

  public static async deleteAll(ids: string[]): Promise<void> {
    try {
      await api.delete(`alimento`, {
        params: {
          ids,
        },
      });
      return;
    } catch (error) {
      throw new Error("Falha ao deletar os alimentos");
    }
  }
}
