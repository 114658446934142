import { Box, Button, List, ListSubheader, Typography } from "@mui/material";
import CardCustom from "../../shared/components/CardCustom";
import ContainerCustom from "../../shared/components/ContainerCustom";
import { useEffect, useState } from "react";
import { DownloadFileGroup } from "../models/DownloadResource";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";
import { Add } from "@mui/icons-material";
import { ODataResult } from "../../shared/models/ODataResult";
import { getEntry, removeEntry } from "../../shared/utils/CrudService";
import { RoleGuard } from "../../shared/components/RoleGuard";
import { useNavigate } from "react-router-dom";
import DeleteContentDialog from "../../shared/dialogs/DeleteContentDialog";
import { DownloadListItem } from "../components/DownloadListItem";

export const DownloadListPage = () => {
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useSnackbarCustom();
  const [downloads, setDownloads] = useState<DownloadFileGroup[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);

  const handleDelete = () => {
    if (!selected) return;

    removeEntry(`download/${selected}`)
      .then(() => {
        notifySuccess("Lâmina deletada com sucesso");
        load();
      })
      .catch((error) => {
        notifyError(error);
      })
      .finally(() => {
        setIsDeleteDialogOpen(false);
        setSelected(null);
      });
  };

  const load = () => {
    getEntry<ODataResult<DownloadFileGroup[]>>("download")
      .then((response) => {
        setDownloads(response.data);
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <ContainerCustom>
      <CardCustom
        title="Lâminas de atendimento"
        action={
          <RoleGuard permit={["Admin"]}>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => navigate("/downloads/criar")}
            >
              Adicionar
            </Button>
          </RoleGuard>
        }
      >
        {downloads.length > 0 ? (
          <List>
            {downloads.map((download, index) => (
              <div>
                <ListSubheader key={`list-header-${index}`}>
                  {download.title}
                </ListSubheader>
                {download.files.map((file, indexRow) => (
                  <DownloadListItem
                    key={`list-item-${indexRow}`}
                    title={file.title}
                    description={file.description || ""}
                    id={file.arquivoId}
                    arquivoId={file.arquivoId}
                    onRemove={(id) => {
                      setSelected(id);
                      setIsDeleteDialogOpen(true);
                    }}
                  />
                ))}
              </div>
            ))}
          </List>
        ) : (
          <Box>
            <Typography>Nenhuma lâmina encontra</Typography>
          </Box>
        )}
      </CardCustom>
      <DeleteContentDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onAccept={handleDelete}
        message="Deseja deleta esta lâmina?"
      />
    </ContainerCustom>
  );
};
