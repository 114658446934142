import { InputSelectItem } from "../components/InputSelect";

export class Enum {
  constructor(private values: Map<string, string>) {}

  public getForSelect(): InputSelectItem[] {
    if (!this.values) {
      return [];
    }

    let result: InputSelectItem[] = [];
    //result.push({ label: "Selecione", value: "NULL" });

    result = result.concat(
      Array.from(this.values).map(([key, value]) => ({
        label: value,
        value: key,
      }))
    );

    return result;
  }
}
