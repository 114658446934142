import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { Print, ExpandMore } from "@mui/icons-material";
import ContainerCustom from "../../shared/components/ContainerCustom";
import {
  ISintomaCausaResponse,
  ISintomaGrupoResponse,
  ISintomaResponse,
} from "../models/IGetAllExameFisicoSintomasResponse";
import {
  getAllExameFisicoSintomas,
  updateExameFisico,
} from "../services/EvalutionService";
import { useParams } from "react-router-dom";
import useNovaAvaliacao from "../hooks/NovaAvaliacaoHook";
import PatientSelectedCard from "../../shared/components/PatientSelectedCard";
import NovaAvaliacaoButton from "../components/NovaAvaliacaoButton";
import SelecionarRecomendacaoDialog from "../dialogs/SelecionarRecomendacaoDialog";
import SelecionarPerguntaDialog from "../dialogs/SelecionarPerguntaDialog";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";
import { Radar } from "react-chartjs-2";
import { number } from "yup";

const EvaluationBodyTestPage: React.FC = () => {
  const { id, consulta_id, avaliacaoId } = useParams();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [sintomas, setSintomas] = React.useState<ISintomaGrupoResponse[]>([]);
  const [causas, setCausas] = React.useState<ISintomaCausaResponse[]>([]);
  const [deficiencias, setDeficiencias] = React.useState<any>({
    labels: [
      "Gravidade",
      "Risco de depressão",
      "Risco de doença cardiovascular",
      "Risco de problema de circulação",
    ],
    datasets: [],
  });

  const {
    onNovaAvaliacao,
    isSelecionarAnamneseDialogOpen,
    setIsSelecionarAnamneseDialogOpen,
    isSelecionarRecomendacaoDialogOpen,
    setIsSelecionarRecomendacaoDialogOpen,
    onSelectAnamnese,
    onSelectRecomendacao,
  } = useNovaAvaliacao({ id: id!, consulta_id: consulta_id! });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddSintoma = (
    sintomas: ISintomaResponse[],
    causas: ISintomaCausaResponse[]
  ) => {
    const deficienciasMap = new Map<string, number | null>();

    for (let sintoma of sintomas) {
      const causasPossiveis = causas.filter(
        (causa) => causa.sintomaId === sintoma.id
      );

      if (sintoma.isSelected) {
        for (let causa of causasPossiveis) {
          const count = deficienciasMap.get(causa.causa) || 0;

          deficienciasMap.set(causa.causa, count + 1);
        }
      }
    }

    // Remover entradas onde o valor é null
    deficienciasMap.forEach((value, key) => {
      if (value === null) {
        deficienciasMap.delete(key);
      }
    });

    const datas = Array.from(deficienciasMap, ([key, value]) => {
      return {
        data: [
          value! * 10,
          calculateRicoDepressao(deficienciasMap),
          calculateRicoCardiovascular(deficienciasMap),
          calculateProblemaCirculacao(deficienciasMap),
        ],
        label: key,
        fill: true,
      };
    });

    console.log(deficienciasMap);

    setDeficiencias({
      labels: [
        "Gravidade",
        "Risco de depressão",
        "Risco de doença cardiovascular",
        "Risco de problema de circulação",
      ],
      datasets: datas,
    });
  };

  const calculateRicoDepressao = (
    sintomas: Map<string, number | null>
  ): number => {
    let ret: number = 0;

    sintomas.forEach((value, key) => {
      if (key === "B6") {
        ret += value! * 10;
      } else if (key === "B3" || key === "B9") {
        ret += value! * 8;
      }
    });

    return ret;
  };

  const calculateRicoCardiovascular = (
    sintomas: Map<string, number | null>
  ): number => {
    let ret: number = 0;

    sintomas.forEach((value, key) => {
      if (key === "B6") {
        ret += value! * 10;
      } else if (key === "B9") {
        ret += value! * 8;
      }
    });

    return ret;
  };

  const calculateProblemaCirculacao = (
    sintomas: Map<string, number | null>
  ): number => {
    let ret: number = 0;

    sintomas.forEach((value, key) => {
      if (key === "B12") {
        ret += value! * 10;
      } else if (key === "B6") {
        ret += value! * 8;
      } else if (key === "C") {
        ret += value! * 5;
      }
    });

    return ret;
  };

  const handleChangeIsSeleted = (
    grupo: string,
    sintomaId: string,
    isSelected: boolean
  ) => {
    const newSintomas = sintomas.map((grupoSintomas) => {
      if (grupoSintomas.grupo === grupo) {
        return {
          ...grupoSintomas,
          sintomas: grupoSintomas.sintomas.map((sintoma) => {
            if (sintoma.id === sintomaId) {
              return {
                ...sintoma,
                isSelected: isSelected,
              };
            }
            return sintoma;
          }),
        };
      }
      return grupoSintomas;
    });

    setSintomas(newSintomas);
  };

  const saveExameFisico = () => {
    updateExameFisico(avaliacaoId!, {
      id: avaliacaoId!,
      sintomas: sintomas
        .map((grupo) =>
          grupo.sintomas.map((sintoma) => ({
            id: sintoma.exameFisicoDadoId,
            sintomaId: sintoma.id,
            isSelected: sintoma.isSelected,
          }))
        )
        .flat(),
    })
      .then(() => {
        notifySuccess("Exame físico salvo com sucesso!");
        fetch();
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  const fetch = () => {
    getAllExameFisicoSintomas(avaliacaoId!)
      .then((response) => {
        const causas = response.causas;
        const sintomas = response.grupos;

        setCausas(causas);
        setSintomas(sintomas);

        const sintomasSelecionados = [];
        for (let grupo of sintomas) {
          for (let sintoma of grupo.sintomas) {
            sintomasSelecionados.push({ ...sintoma, isSelected: false });
          }
        }

        handleAddSintoma(sintomasSelecionados, causas);
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    const sintomasSelecionados = [];
    for (let grupo of sintomas) {
      for (let sintoma of grupo.sintomas) {
        sintomasSelecionados.push(sintoma);
      }
    }

    handleAddSintoma(sintomasSelecionados, causas);
  }, [sintomas]);

  return (
    <ContainerCustom>
      <PatientSelectedCard patientId={id} showPaginaInicialButton={false}>
        <NovaAvaliacaoButton onClick={(av) => onNovaAvaliacao(av)} />
        <Button
          variant="contained"
          color="secondary"
          onClick={saveExameFisico}
          sx={{ ml: 1 }}
        >
          Salvar
        </Button>
        {/* <Button variant="text" sx={{ ml: 1 }}>
          Imprimir
        </Button> */}
      </PatientSelectedCard>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
            <CardHeader title="Sintomas" />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuList>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Print />
                  </ListItemIcon>
                  <ListItemText primary="Imprimir" />
                </MenuItem>
              </MenuList>
            </Menu>
            <CardContent>
              <div>
                {sintomas.map((headers, index) => (
                  <Accordion key={`header-${index}`} elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      {headers.grupo}
                    </AccordionSummary>

                    <AccordionDetails>
                      <List>
                        {headers.sintomas.map((sintoma) => (
                          <ListItem key={`${sintoma.id}`}>
                            <FormControl>
                              <FormControlLabel
                                label={sintoma.nome}
                                control={
                                  <Checkbox
                                    checked={sintoma.isSelected}
                                    onChange={(e, checked) =>
                                      handleChangeIsSeleted(
                                        headers.grupo,
                                        sintoma.id,
                                        checked
                                      )
                                    }
                                  />
                                }
                              />
                            </FormControl>
                          </ListItem>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ boxShadow: "none" }}>
            <CardHeader title="Possíveis Deficiências" />
            <CardContent sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Radar
                data={deficiencias}
                options={{
                  elements: {
                    line: {
                      borderWidth: 2,
                    },
                  },
                  scales: {
                    r: {
                      suggestedMin: 0,
                    },
                  },
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <SelecionarRecomendacaoDialog
        open={isSelecionarRecomendacaoDialogOpen}
        onClose={() => setIsSelecionarRecomendacaoDialogOpen(false)}
        onSelect={onSelectRecomendacao}
      />

      <SelecionarPerguntaDialog
        open={isSelecionarAnamneseDialogOpen}
        onClose={() => setIsSelecionarAnamneseDialogOpen(false)}
        onSelect={onSelectAnamnese}
      />
    </ContainerCustom>
  );
};

export default EvaluationBodyTestPage;
