import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isAllInputNotTouched, toNumber } from "../../shared/utils/InputUtil";
import { LISTAR_MEDIDA_CASEIRA } from "../../shared/components/AppRoutes";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import AutocompleteCustomInput from "../../shared/components/AutocompleteCustomInput";
import { getAllAutocomplete } from "../../shared/services/AutocompleteService";
import ContainerCustom from "../../shared/components/ContainerCustom";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";
import {
  AddMedidaCaseiraDialog,
  IMedidaCaseiraInput,
} from "../dialogs/AddMedidaCaseiraDialog";
import { Add, Delete } from "@mui/icons-material";
import { createMedidaCaseiraLote } from "../services/MedidaCaseiraService";
import { IMedidaCaseiraAlimentoRequest } from "../models/ICreateMedidaCaseiraRequest";

interface IFormInput {
  alimento: any;
  medidas: IMedidaCaseiraInput[];
}

const schema = Yup.object().shape({
  alimento: Yup.object().required("Alimento é obrigatório"),
  medidas: Yup.array().min(1, "Equivalente em gramas é obrigatório"),
});

const defaultValues: IFormInput = {
  alimento: undefined,
  medidas: [],
};

const MedidaCaseiraLoteFormPage: React.FC = () => {
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useSnackbarCustom();
  const [isAddModalOpen, setIsAddModalOpen] = React.useState<boolean>(false);

  const onSubmit = (data: IFormInput) => {
    createMedidaCaseiraLote({
      alimentoId: data.alimento.id,
      medidas: data.medidas.map(
        (medida) =>
          ({
            nome: medida.nome,
            equivalenteGramas: toNumber(medida.equivalenteGramas),
            tamanhoMedida: toNumber(medida.tamanhoMedida),
          } as IMedidaCaseiraAlimentoRequest)
      ),
    })
      .then(() => {
        notifySuccess("Medida(s) caseira(s) cadastrada(s) com sucesso!");
        navigate(LISTAR_MEDIDA_CASEIRA);
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  const formik = useFormik<IFormInput>({
    initialValues: defaultValues,
    validationSchema: schema,
    onSubmit: onSubmit,
  });

  const handleAddMedida = (medida: IMedidaCaseiraInput) => {
    formik.setFieldValue("medidas", [...formik.values.medidas, medida]);
  };

  return (
    <ContainerCustom>
      <form onSubmit={formik.handleSubmit}>
        <Card style={{ boxShadow: "none" }}>
          <CardHeader title="Cadastrar medida caseira em lote" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AutocompleteCustomInput
                  name="alimento"
                  label="Alimento"
                  required
                  formik={formik}
                  resource={() => getAllAutocomplete("alimento")}
                />
              </Grid>
            </Grid>
            <Box mt={3}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h6">Medidas</Typography>
                <Button
                  startIcon={<Add />}
                  disabled={!formik.values.alimento}
                  onClick={() => setIsAddModalOpen(true)}
                >
                  Adicionar
                </Button>
              </Box>
              <List>
                {formik.values.medidas.map((medida, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={medida.nome}
                      secondary={`${medida.tamanhoMedida} medida(s) - ${medida.equivalenteGramas}g`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          formik.setFieldValue(
                            "medidas",
                            formik.values.medidas.filter((_, i) => i !== index)
                          );
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>
          </CardContent>
          <CardActions sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isAllInputNotTouched(formik) || !formik.isValid}
            >
              Cadastrar
            </Button>
            <Button
              variant="text"
              color="primary"
              component={RouterLink}
              to={LISTAR_MEDIDA_CASEIRA}
              sx={{ mr: 2 }}
            >
              Voltar
            </Button>
          </CardActions>
        </Card>
      </form>
      <AddMedidaCaseiraDialog
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAdd={handleAddMedida}
        alimento={formik.values.alimento?.nome}
      />
    </ContainerCustom>
  );
};

export default MedidaCaseiraLoteFormPage;
