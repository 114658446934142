import { useAuth } from "../hooks/AuthHook";
import { redirect } from "react-router-dom";

interface IPageRoleOptions {
    permit: string[];
    page: JSX.Element;
}
interface ChoosePageFromRoleProps {
    redirectIfNotPermit?: string;
    pageRoles: IPageRoleOptions[];
}

export function ChoosePageFromRole({ pageRoles, redirectIfNotPermit }: ChoosePageFromRoleProps): JSX.Element {
  const { userLogged } = useAuth();
  const currentUser = userLogged();

  const findPage = (): JSX.Element | undefined => {
    if (!currentUser) {
      return undefined;
    }

    for (const role of pageRoles) {
      for (const permit of role.permit) {
        if (currentUser.roles.includes(permit)) {
          return role.page;
        }
      }
    }
    
    return undefined;
  }

  const page = findPage();

  if (!page) {
    if (redirectIfNotPermit) {
        redirect(redirectIfNotPermit);
        return <></>;
    }
    else {
        return <></>;
    }
  }

  return page;
}