import {
  ArrowDropUp,
  ArrowDropDown,
  Apple,
  CalendarMonth,
  CalendarViewMonth,
  Checklist,
  Download,
  FreeBreakfast,
  Grading,
  Home,
  Person,
  Settings,
} from "@mui/icons-material";
import {
  SwipeableDrawer,
  Toolbar,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { IActionMenu } from "../states/SideMenuState";
import {
  APP,
  LISTAR_ALIMENTOS,
  LISTAR_MEDIDA_CASEIRA,
  LISTAR_PACIENTE,
  AGENDAMENTO,
  LISTAR_DOWNLOAD,
  CONFIGURACAO_ANAMNESE,
  CONFIGURACAO_ORIENTACAO,
  PERFIL,
} from "./AppRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../hooks/AuthHook";

export const drawerWidth: number = 300;

interface IDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  toggleDrawer: () => void;
}

const Drawer: React.FC<IDrawerProps> = ({ open, setOpen, toggleDrawer }) => {
  const { pathname } = useLocation();
  const { userLogged } = useAuth();
  const navigate = useNavigate();

  const rotasClient = (): IActionMenu[] => {
    return [
      {
        key: "inicio",
        icon: <Home color="action" />,
        title: "Início",
        path: APP,
      },
      {
        key: "paciente",
        icon: <Person color="action" />,
        title: "Paciente",
        path: LISTAR_PACIENTE,
      },
      {
        key: "agendamento",
        icon: <CalendarMonth color="action" />,
        title: "Agendamento",
        path: AGENDAMENTO,
      },
      {
        key: "downloads",
        icon: <Download color="action" />,
        title: "Downloads",
        path: LISTAR_DOWNLOAD,
      },
      {
        key: "divider",
        isDivider: true,
      },
      {
        key: "configuracoes",
        icon: <Settings color="action" />,
        title: "Configurações",
        children: [
          {
            key: "alimento",
            icon: <Apple color="action" />,
            title: "Alimento",
            children: [
              {
                key: "tabela_referencia",
                icon: <CalendarViewMonth color="action" />,
                title: "Referência",
                path: LISTAR_ALIMENTOS,
              },
              {
                key: "medida_caseira",
                icon: <FreeBreakfast color="action" />,
                title: "Medida Caseira",
                path: LISTAR_MEDIDA_CASEIRA,
              },
            ],
          },
          {
            key: "anamnese",
            icon: <Checklist color="action" />,
            title: "Anamnese",
            path: CONFIGURACAO_ANAMNESE,
          },
          {
            key: "perfil",
            icon: <Person color="action" />,
            title: "Perfil",
            path: PERFIL,
          },
          {
            key: "orientacao",
            icon: <Grading color="action" />,
            title: "Recomendação",
            path: CONFIGURACAO_ORIENTACAO,
          },
          // {
          //   key: "dieta",
          //   icon: <RamenDining style={{ color: theme.palette.primary.main }} />,
          //   title: "Dieta",
          //   path: "/app/configuracoes/2",
          // },
        ],
      },
    ];
  };

  const rotasAdmin = (): IActionMenu[] => {
    return [
      {
        key: "inicio",
        icon: <Home color="action" />,
        title: "Início",
        path: APP,
      },
      {
        key: "downloads",
        icon: <Download color="action" />,
        title: "Downloads",
        path: LISTAR_DOWNLOAD,
      },
      {
        key: "divider",
        isDivider: true,
      },
      {
        key: "configuracoes",
        icon: <Settings color="action" />,
        title: "Configurações",
        children: [
          {
            key: "alimento",
            icon: <Apple color="action" />,
            title: "Alimento",
            children: [
              {
                key: "tabela_referencia",
                icon: <CalendarViewMonth color="action" />,
                title: "Referência",
                path: LISTAR_ALIMENTOS,
              },
              {
                key: "medida_caseira",
                icon: <FreeBreakfast color="action" />,
                title: "Medida Caseira",
                path: LISTAR_MEDIDA_CASEIRA,
              },
            ],
          },
          {
            key: "anamnese",
            icon: <Checklist color="action" />,
            title: "Anamnese",
            path: CONFIGURACAO_ANAMNESE,
          },
          {
            key: "perfil",
            icon: <Person color="action" />,
            title: "Perfil",
            path: PERFIL,
          },
          {
            key: "orientacao",
            icon: <Grading color="action" />,
            title: "Recomendação",
            path: CONFIGURACAO_ORIENTACAO,
          },
          // {
          //   key: "dieta",
          //   icon: <RamenDining style={{ color: theme.palette.primary.main }} />,
          //   title: "Dieta",
          //   path: "/app/configuracoes/2",
          // },
        ],
      },
    ];
  };

  const actions: IActionMenu[] = userLogged()?.roles.includes("Admin")
    ? rotasAdmin()
    : rotasClient();

  const generateMenuCollapsedStatusFromActions = (
    actions: IActionMenu[]
  ): Map<string, boolean> => {
    let status = new Map<string, boolean>();

    actions.forEach((action) => {
      if (action.children) {
        status.set(action.key, false);
        let childrenStatus = generateMenuCollapsedStatusFromActions(
          action.children
        );
        status = new Map<string, boolean>([
          ...Array.from(status.entries()),
          ...Array.from(childrenStatus.entries()),
        ]);
      }
    });

    return status;
  };

  const [menuCollapsedStatus, setMenuCollapsedStatus] = React.useState<
    Map<string, boolean>
  >(generateMenuCollapsedStatusFromActions(actions));

  const handleMenuGroupClick = (key: string) => {
    const lastStatus = menuCollapsedStatus.get(key);

    if (lastStatus === undefined) return;

    const newMenuCollapsedStatus = new Map<string, boolean>();

    menuCollapsedStatus.forEach((value, key) => {
      newMenuCollapsedStatus.set(key, value);
    });

    newMenuCollapsedStatus.set(key, !lastStatus);

    setMenuCollapsedStatus(newMenuCollapsedStatus);
  };

  const isLinkSelected = (path?: string): boolean => {
    if (!path) {
      return false;
    }

    // const match = groupPaths.filter((group) => group.path === path);

    // if (match.length > 0) {
    //   return false;
    // }

    return path !== APP ? pathname.startsWith(path) : pathname === path;
  };

  const isGroupExpanded = (key: string): boolean => {
    return menuCollapsedStatus.get(key) || false;
  };

  const createListItem = (
    action: IActionMenu,
    index: number,
    level: number
  ): JSX.Element => {
    if (action.isDivider) {
      return (
        <Divider key={`divider-${index}`} sx={{ ml: 2, mr: 2, mt: 2, mb: 2 }} />
      );
    }

    return (
      <div key={action.key}>
        <ListItem
          disablePadding
          sx={{
            pl: 1 * level,
            pr: 1,
            mb: 0.5,
          }}
        >
          <ListItemButton
            selected={isLinkSelected(action.path)}
            onClick={() =>
              action.children
                ? handleMenuGroupClick(action.key)
                : navigate(action.path!)
            }
            component={RouterLink}
            to={action.path!}
            style={{ borderRadius: 2 }}
            sx={{
              "&.MuiListItemButton-root:hover": {
                backgroundColor: "primary.main",
                // borderRadius: 2,
                color: "white",
                ".MuiSvgIcon-colorAction": {
                  color: "white",
                },
              },
              "&.Mui-selected": {
                backgroundColor: "primary.main",
                // borderRadius: 2,
                color: "white",
                ".MuiSvgIcon-colorAction": {
                  color: "white",
                },
              },
            }}
          >
            <ListItemIcon color="secondary">{action.icon}</ListItemIcon>
            <ListItemText primary={action.title} />
            {action.children && (
              <ListItemSecondaryAction>
                {isGroupExpanded(action.key) ? (
                  <ArrowDropUp color="action" />
                ) : (
                  <ArrowDropDown color="action" />
                )}
              </ListItemSecondaryAction>
            )}
          </ListItemButton>
        </ListItem>
        {action.children && (
          <Collapse
            in={isGroupExpanded(action.key)}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {action.children.map((child, childIndex) => {
                return createListItem(child, childIndex, level + 1);
              })}
            </List>
          </Collapse>
        )}
      </div>
    );
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          width: drawerWidth,
        },
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            gap: 4,
          }}
          onClick={toggleDrawer}
        >
          <img
            src={process.env.PUBLIC_URL + "/diety-logo.png"}
            alt="logo-diety"
            width={100}
          />
        </div>
      </Toolbar>
      <Divider />
      <List component="nav">
        {
          actions.map((action, index) => {
            return createListItem(action, index, 1);
          })

          /* {
          if (action.isDivider) {
            return (
              <Divider
                key={`divider-${index}`}
                sx={{ ml: 2, mr: 2, mt: 2, mb: 2 }}
              />
            );
          }

          return (
            <div key={action.key}>
              <ListItem
                disablePadding
                sx={{
                  pl: 1,
                  pr: 1,
                  mb: 0.5,
                }}
              >
                <ListItemButton
                  selected={isLinkSelected(action.path)}
                  onClick={() =>
                    action.children
                      ? handleMenuGroupClick(action.key)
                      : navigate(action.path!)
                  }
                  component={RouterLink}
                  to={action.path!}
                  sx={{
                    "&.MuiListItemButton-root:hover": {
                      backgroundColor: grey[200],
                      borderRadius: 2,
                      color: "text.primary",
                      ".MuiSvgIcon-colorAction": {
                        color: "text.primary",
                      },
                    },
                    "&.Mui-selected": {
                      backgroundColor: "primary.main",
                      borderRadius: 2,
                      color: "white",
                      ".MuiSvgIcon-colorAction": {
                        color: "white",
                      },
                    },
                  }}
                >
                  <ListItemIcon color="secondary">{action.icon}</ListItemIcon>
                  <ListItemText primary={action.title} />
                  {action.children && (
                    <ListItemSecondaryAction>
                      {isGroupExpanded(action.key) ? (
                        <ArrowDropUp color="action" />
                      ) : (
                        <ArrowDropDown color="action" />
                      )}
                    </ListItemSecondaryAction>
                  )}
                </ListItemButton>
              </ListItem>
              {action.children && (
                <Collapse
                  in={isGroupExpanded(action.key)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {action.children.map((child) => (
                      <ListItem
                        disablePadding
                        key={child.key}
                        sx={{
                          pl: 1,
                          pr: 1,
                          mb: 0.5,
                        }}
                      >
                        <ListItemButton
                          selected={isLinkSelected(child.path)}
                          onClick={() => navigate(action.path!)}
                          component={RouterLink}
                          to={child.path!}
                          sx={{
                            pl: 4,
                            "&.MuiListItemButton-root:hover": {
                              backgroundColor: grey[200],
                              borderRadius: 2,
                              color: "text.primary",
                              ".MuiSvgIcon-colorAction": {
                                color: "text.primary",
                              },
                            },
                            "&.Mui-selected": {
                              backgroundColor: "primary.main",
                              borderRadius: 2,
                              color: "white",
                              ".MuiSvgIcon-colorAction": {
                                color: "white",
                              },
                            },
                          }}
                        >
                          <ListItemIcon>{child.icon}</ListItemIcon>
                          <ListItemText primary={child.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          );
        } */
        }
      </List>
    </SwipeableDrawer>
  );
};

export default Drawer;
