import {
  Delete,
  KeyboardArrowRight,
  MoreVert,
  Remove,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { IGetAvaliacaoConsultaDashboardResponse } from "../models/IGetAvaliacaoConsultaDashboardResponse";
import { getInitials } from "../../shared/utils/NameUtil";

interface EvaluationListItemProps {
  cores: Map<string, string>;
  avaliacao: IGetAvaliacaoConsultaDashboardResponse;
  onEdit: () => void;
  onDelete: () => void;
}

const EvaluationListItem: React.FC<EvaluationListItemProps> = ({
  cores,
  avaliacao,
  onDelete,
  onEdit,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const menuOpen = Boolean(menuAnchorEl);

  const openMenuHandle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closeMenuHandle = () => {
    setMenuAnchorEl(null);
  };

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          sx={{
            backgroundColor: cores.get(avaliacao.tipo),
            width: 45,
            height: 45,
          }}
        >
          <Typography variant="h6" color="white">
            {getInitials(avaliacao.tipo)}
          </Typography>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={avaliacao.tipo}
        secondary={`Criado em ${moment(avaliacao.data).format("DD/MM/YYYY")}`}
      />
      <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={closeMenuHandle}>
        <MenuList>
          <MenuItem onClick={onEdit}>
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            <ListItemText primary="Visualizar" />
          </MenuItem>
          <MenuItem onClick={onDelete}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary="Deletar" />
          </MenuItem>
        </MenuList>
      </Menu>
      <ListItemSecondaryAction>
        <IconButton onClick={openMenuHandle}>
          <MoreVert />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default EvaluationListItem;
