class Calculator {
  public static convertMeterToCentimeter(meter: number) {
    return meter * 100;
  }

  public static convertCentimeterToMeter(centimeter: number) {
    return centimeter / 100;
  }

  public static calculateImc(peso: number, altura: number) {
    return peso / (altura * altura);
  }

  public static calculatePesoIdeal(imcIdeal: number, altura: number) {
    return imcIdeal * altura * altura;
  }

  public static calculatePesoAjustado(imc: number, peso: number, pesoIdeal: number) {
    if (imc > 30) {
      return (peso - pesoIdeal) * 0.25 + pesoIdeal;
    } else if (imc < 18.5) {
      return (pesoIdeal - peso) * 0.25 + peso;
    } else {
      return peso;
    }
  }

  public static calculatePetroski4Dobras(
    idade: number,
    sexo: string,
    dct: number,
    subescapular: number,
    suprailiaca: number,
    panturrilha: number,
    peso: number,
    altura: number
  ) {
    if (idade >= 18 && idade <= 66) {
      if (sexo === "M") {
        const somatorioDobras = dct + subescapular + suprailiaca + panturrilha;
        return (
          1.10726863 -
          0.00081201 * somatorioDobras +
          0.00000212 * somatorioDobras * somatorioDobras -
          0.00041761 * idade
        );
      } else {
        const somatorioDobras = dct + subescapular + suprailiaca + panturrilha;
        return (
          1.02902361 -
          0.00067159 * somatorioDobras +
          0.00000242 * somatorioDobras * somatorioDobras -
          0.00026073 * idade -
          0.00056 * peso +
          0.00054649 * this.convertMeterToCentimeter(altura)
        );
      }
    }
  }

  // esta com problema
  public static calculateJacsonPollock3Dobras(
    idade: number,
    sexo: string,
    peitoral: number,
    abdominal: number,
    coxa: number
  ) {
    if (idade >= 18 && idade <= 66) {
      if (sexo === "M") {
        // const somatorioDobras = dct + subescapular + suprailiaca;
        // return (
        //   1.10938 -
        //   0.0008267 * somatorioDobras +
        //   0.0000016 * somatorioDobras * somatorioDobras -
        //   0.0002574 * idade
        // );
      } else {
        // const somatorioDobras = dct + subescapular + suprailiaca;
        // return (
        //   1.0994921 -
        //   0.0009929 * somatorioDobras +
        //   0.0000023 * somatorioDobras * somatorioDobras -
        //   0.0001392 * idade
        // );
      }
    }
  }

  // esta com problema
  public static calculateGuedes3Dobras(
    idade: number,
    sexo: string,
    peitoral: number,
    abdominal: number,
    coxa: number
  ) {
    if (idade >= 18 && idade <= 66) {
      if (sexo === "M") {
        // const somatorioDobras = dct + subescapular + suprailiaca;
        // return (
        //   1.1715 -
        //   0.0774 * somatorioDobras +
        //   0.00054 * somatorioDobras * somatorioDobras -
        //   0.0083 * idade
        // );
      } else {
        // const somatorioDobras = dct + subescapular + suprailiaca;
        // return (
        //   1.1665 -
        //   0.0706 * somatorioDobras +
        //   0.00046 * somatorioDobras * somatorioDobras -
        //   0.0138 * idade +
        //   0.064 * idade * idade
        // );
      }
    }
  }

  public static calculateFormulaBolso(
    peso: number,
    objetivo: string,
  ) : number {
    var multiplier = objetivo === "PERDA_PESO" ? 23 : objetivo === "MANTER_PESO" ? 27 : 34;

    return peso * multiplier;
  }

  public static calculateFormulaEER(
    sexo: string,
    idade: number,
    nivelAtividade: string,
    peso: number,
    altura: number
  ) {
    let eer;
    if (sexo === "M") {
      const valorNivelAtividade =
        nivelAtividade === "SED"
          ? 1.0
          : nivelAtividade === "LEV"
          ? 1.11
          : nivelAtividade === "MOD"
          ? 1.25
          : 1.48;
      eer =
        662 -
        9.53 * idade +
        valorNivelAtividade * 15.91 * peso +
        539.6 * altura;
    } else {
      const valorNivelAtividade =
        nivelAtividade === "SED"
          ? 1.0
          : nivelAtividade === "LEV"
          ? 1.12
          : nivelAtividade === "MOD"
          ? 1.27
          : 1.45;
      eer =
        354 -
        6.91 * idade +
        valorNivelAtividade * 9.36 * peso +
        726 * altura;
    }
    return eer;
  }

  public static calculateFormulaFAOWHO(
    sexo: string,
    idade: number,
    nivelAtividade: string,
    peso: number
  ) {
    let vet;
    if (sexo === "M") {
      const valorAtividadeFisica =
        nivelAtividade === "SED"
          ? 1.0
          : nivelAtividade === "LEV"
          ? 1.55
          : nivelAtividade === "MOD"
          ? 1.78
          : 2.1;

      if (idade >= 18 && idade <= 30) {
        const value = 15.3 * peso + 679;
        vet = value * valorAtividadeFisica;
      } else if (idade >= 31 && idade <= 60) {
        const value = 11.6 * peso + 879;
        vet = value * valorAtividadeFisica;
      } else {
        const value = 13.5 * peso + 487;
        vet = value * valorAtividadeFisica;
      }
    } else {
      const valorAtividadeFisica =
        nivelAtividade === "SED"
          ? 1.0
          : nivelAtividade === "LEV"
          ? 1.56
          : nivelAtividade === "MOD"
          ? 1.64
          : 1.82;

      if (idade >= 18 && idade <= 30) {
        const value = 14.7 * peso + 496;
        vet = value * valorAtividadeFisica;
      } else if (idade >= 31 && idade <= 60) {
        const value = 8.7 * peso + 829;
        vet = value * valorAtividadeFisica;
      } else {
        const value = 10.5 * peso + 596;
        vet = value * valorAtividadeFisica;
      }
    }
    return vet;
  }

  public static calculateFormulaFAOOMS(
    sexo: string,
    idade: number,
    nivelAtividade: string,
    peso: number
  ) {
    let vet;
    if (sexo === "M") {
      const valorAtividadeFisica =
        nivelAtividade === "SED"
          ? 1.2
          : nivelAtividade === "LEV"
          ? 1.5
          : nivelAtividade === "MOD"
          ? 1.8
          : 2.1;

      if (idade >= 18 && idade <= 30) {
        const value = 15.3 * peso + 679;
        vet = value * valorAtividadeFisica;
      } else if (idade >= 31 && idade <= 60) {
        const value = 11.6 * peso + 879;
        vet = value * valorAtividadeFisica;
      } else {
        const value = 13.5 * peso + 487;
        vet = value * valorAtividadeFisica;
      }
    } else {
      const valorAtividadeFisica =
        nivelAtividade === "SED"
          ? 1.2
          : nivelAtividade === "LEV"
          ? 1.5
          : nivelAtividade === "MOD"
          ? 1.8
          : 2.1;

      if (idade >= 18 && idade <= 30) {
        const value = 14.7 * peso + 496;
        vet = value * valorAtividadeFisica;
      } else if (idade >= 31 && idade <= 60) {
        const value = 8.7 * peso + 829;
        vet = value * valorAtividadeFisica;
      } else {
        const value = 10.5 * peso + 596;
        vet = value * valorAtividadeFisica;
      }
    }
    return vet;
  }

  public static calculateFormulaHarrisBenedict(
    sexo: string,
    idade: number,
    altura: number,
    peso: number,
    fatorAtividade: number
  ) {
    let vet;
    if (sexo === "M") {
      const value =
        66.5 +
        13.75 * peso +
        5.003 * altura -
        6.75 * idade;
      vet = value * fatorAtividade;
    } else {
      const value =
        655.1 +
        9.563 * peso +
        1.85 * altura -
        4.676 * idade;
      vet = value * fatorAtividade;
    }
    return vet;
  }

  public static calculatePesoGordo(peso: number, percentualGordura: number) {
    return peso * (percentualGordura / 100);
  }

  public static calculatePesoMuscular(peso: number, pesoGordo: number, pesoOsseo: number, pesoResidual: number) {
    return peso - pesoGordo - pesoOsseo - pesoResidual;
  }

  public static calculatePesoResidual(sexo: string, peso: number) {
    const value = sexo === "M" ? 0.241 : 0.209;
    
    return peso * value;
  }

  public static calculatePesoOsseo(altura: number, diametroPunho: number, diametroFemur: number) {
    return 3.02 * Math.pow(altura * altura * this.convertCentimeterToMeter(diametroPunho) * this.convertCentimeterToMeter(diametroFemur) * 400, 0.712);
  }

  public static calculatePesoMaximo(altura: number) {
    return altura * altura * 24.9;
  }

  public static calculatePesoMinimo(altura: number) {
    return altura * altura * 18.5;
  }

  public static calculatePercentagemPesoMuscular(peso: number, pesoMuscular: number) {
    return (pesoMuscular / peso) * 100;
  }

  public static calculateRelacaoCinturaQuadril(cc: number, cq: number) {
    return cc / cq;
  }
}

export default Calculator;
