import { hookstate } from "@hookstate/core";

export interface IPatientState {
  isLoading: boolean;
  id: string;
  nome: string;
  sexo: string;
  idade: number;
}

export const globalState = hookstate<IPatientState>({
  isLoading: false,
  id: "",
  nome: "Taylinne Pereira",
  sexo: "M",
  idade: 33,
});
