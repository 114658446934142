import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { errorText, getNestedProperty } from "../utils/InputUtil";
import { FormikProps } from "formik";
import { IAutocompleteResponse } from "../models/AutocompleteResponse";

interface AutocompleteCustomInputProps {
  name: string;
  label: string;
  formik?: FormikProps<any>;
  resource: () => Promise<any[]>;
  required?: boolean;
  disabled?: boolean;
  onError?: (error: string) => void;
  onChange?: (oldValue: any | null, newValue: any | null) => void;
  keyField?: string;
  nameField?: string;
  value?: string;
}

const AutocompleteCustomInput: React.FC<AutocompleteCustomInputProps> = ({
  name,
  label,
  formik,
  resource,
  required,
  disabled,
  onError,
  onChange,
  value,
  keyField = "id",
  nameField = "nome",
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const isLoading = isOpen && options.length === 0;

  const handleChange = (newValue: any | null) => {
    // Atualiza o Formik apenas se newValue não for null
    if (newValue) {
      formik && formik.setFieldValue(name!, newValue);
    } else {
      // Se newValue for null, você pode querer limpar o valor ou lidar de outra forma
      formik && formik.setFieldValue(name!, null);
    }

    if (onChange) {
      onChange(formik ? formik.values[name!] : value, newValue);
    }
  };

  useEffect(() => {
    if (!isLoading) return;

    resource()
      .then((response) => {
        setOptions(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [isLoading]);

  React.useEffect(() => {
    if (!isOpen) {
      setOptions([]);
    }
  }, [isOpen]);

  React.useEffect(() => {
    // Verifica se formik ou formik.values é undefined antes de tentar acessar formik.values[name!]
    const formikValues = formik ? formik.values : undefined;
    const initialValue =
      formikValues && formikValues[name!]
        ? {
            [keyField]: formikValues[name!][keyField],
            [nameField]: formikValues[name!][nameField],
          }
        : null; // ou um valor padrão apropriado

    // Se initialValue não é null e as opções ainda não incluem o valor inicial
    if (
      initialValue &&
      !options.find((option) => option[keyField] === initialValue[keyField])
    ) {
      // Define um placeholder de opção com o valor inicial
      setOptions((prevOptions) => [initialValue, ...prevOptions]);
    }
  }, [options, formik?.values, keyField, nameField]);

  return (
    <Autocomplete
      loading={isLoading}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => option[nameField] ?? ""}
      isOptionEqualToValue={(option, value) => {
        return (
          value[keyField] === undefined || option[keyField] === value[keyField]
        );
      }}
      // onInputChange={(_, newInputValue) => setCurrentText(newInputValue)}
      value={
        value ? value : formik ? getNestedProperty(formik.values, name!) : null
      }
      onChange={(event: any, newValue: any | null) => {
        handleChange(newValue);
      }}
      onBlur={formik ? formik.handleBlur : undefined}
      renderInput={(params) => (
        <TextField
          name={name}
          required={required}
          {...params}
          label={label}
          fullWidth
          error={
            formik
              ? formik.touched[name!] && Boolean(formik.errors[name!])
              : undefined
          }
          helperText={formik ? errorText(formik, name) : undefined}
        />
      )}
    />
  );
};

export default AutocompleteCustomInput;
