import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { ExpandMore } from "@mui/icons-material";
import { AgrupadorPerguntaRequest } from "../../pergunta/models/AgrupadorPerguntaRequest";
import { ExameQuimicoReferencia } from "../models/IExameQuimicoReferencia";

interface SolicitarExameQuimicoDialogProps {
  open: boolean;
  examesReferencia: ExameQuimicoReferencia[];
  onSelect: (exames: ExameQuimicoReferencia[]) => void;
  onClose: () => void;
}

const SolicitarExameQuimicoDialog: React.FC<
  SolicitarExameQuimicoDialogProps
> = ({ open, examesReferencia, onSelect, onClose }) => {
  const [exames, setExames] = React.useState<string[]>([]);

  const grouped = examesReferencia.reduce((grouped, referencia) => {
    grouped.set(
      referencia.tipo,
      (grouped.get(referencia.tipo) || []).concat(referencia)
    );

    return grouped;
  }, new Map<string, ExameQuimicoReferencia[]>());

  const handleSelecionar = () => {
    const selected = examesReferencia.filter((exame) =>
      exames.includes(exame.id)
    );
    onSelect(selected);
    setExames([]);
    onClose();
  };

  const handleToggleExame = (exameId: string, checked: boolean) => {
    if (checked) {
      setExames((prevExames) => [...prevExames, exameId]);
    } else {
      setExames((prevExames) => prevExames.filter((id) => id !== exameId));
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Typography variant="h6" color="secondary">
          Selecione os exames
        </Typography>
      </DialogTitle>
      <DialogContent>
        {Array.from(grouped.keys()).map((title, index) => (
          <Accordion key={`accordion-${index}`}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              {title}
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {grouped.get(title)!.map((exame, exameIndex) => {
                  const isSelected = exames.includes(exame.id);

                  return (
                    <ListItem key={exameIndex}>
                      <ListItemIcon>
                        <Checkbox checked={isSelected} onChange={(e, checked) => handleToggleExame(exame.id, checked)} />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${exame.nome} (${exame.dimensao})`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!exames.length}
          onClick={() => handleSelecionar()}
        >
          Selecionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SolicitarExameQuimicoDialog;
