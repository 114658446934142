import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CardCustom from "./CardCustom";
import ListRemoveActionAppBar from "../../medidaCaseira/components/ListRemoveActionAppBar";
import ListAppBar, {
  MenuAction,
} from "../../medidaCaseira/components/ListAppBar";

interface TableHeaderProps {
  id: string;
  label: string;
  align?: "left" | "right" | "center";
  minWidth?: number;
  format?: (value: number) => string;
}

interface TableCustomProps {
  headers: TableHeaderProps[];
  emptyText: string;
  children?: JSX.Element[];
  rowCount: number;
  selectedCouunt: number;
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (page: number) => void;
  onSelectAll: () => void;
  onDesselectAll: () => void;
}

const TableCustom: React.FC<TableCustomProps> = ({
  headers,
  children,
  emptyText,
  selectedCouunt,
  count,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowCount,
  onSelectAll,
  onDesselectAll,
}) => {
  const [isSeletedAll, setIsSeletedAll] = React.useState(false);

  const selectAll = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setIsSeletedAll(checked);

    if (checked) {
      onSelectAll();
    } else {
      onDesselectAll();
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    onPageChange(newPage);
  };

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  maxWidth: "30px",
                }}
              >
                <Checkbox
                  value={isSeletedAll}
                  onChange={selectAll}
                  color="primary"
                  indeterminate={
                    selectedCouunt > 0 && selectedCouunt !== rowCount
                  }
                  checked={selectedCouunt === rowCount && rowCount > 0}
                />
              </TableCell>
              {headers.map((header) => (
                <TableCell
                  key={header.id}
                  align={header.align}
                  sx={{
                    minWidth: header.minWidth,
                    fontWeight: "bold",
                    color: "text.secondary",
                  }}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {children?.length === 0 && (
              <TableRow>
                <TableCell colSpan={headers.length + 1}>
                  <Typography
                    textAlign="center"
                    variant="body1"
                    // color="secondary"
                  >
                    {emptyText}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {children}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        color="primary"
        rowsPerPageOptions={[5, 15, 25]}
        labelRowsPerPage="Itens por página"
        labelDisplayedRows={({ from, to }) => `${from} - ${to} de ${count}`}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={(e) => onRowsPerPageChange(Number(e.target.value))}
      />
    </Box>
  );
};

export default TableCustom;
