import { useFormik } from "formik";
import * as yup from "yup";
import { FormPage } from "../../shared/pages/FormPage";
import { Box, Button, Grid } from "@mui/material";
import { AttachFile, Close, Upload } from "@mui/icons-material";
import TextInput from "../../shared/components/TextInput";
import { createEntry } from "../../shared/utils/CrudService";
import { MuiFileInput } from "mui-file-input";
import { errorText, getNestedProperty } from "../../shared/utils/InputUtil";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";
import { RoleGuard } from "../../shared/components/RoleGuard";
import { useNavigate } from "react-router-dom";

interface DownloadFormPageProps {
  title: string;
  description: string;
  type: string;
  group: string;
  file?: File;
}

const schema = yup.object().shape({
  title: yup.string().required("Campo é obrigatório"),
  description: yup.string().required("Campo é obrigatório"),
  type: yup.string().required("Campo é obrigatório"),
  group: yup.string().required("Campo é obrigatório"),
  file: yup.mixed().required("Campo é obrigatório"),
});

export function DownloadFormPage(): JSX.Element {
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useSnackbarCustom();

  const onSubmit = (values: DownloadFormPageProps) => {
    const form = new FormData();
    form.append("title", values.title);
    form.append("description", values.description);
    form.append("type", values.type);
    form.append("group", values.group);
    form.append("file", values.file!);

    createEntry("download", form)
      .then(() => {
        notifySuccess("Lâmina criada com sucesso");
        navigate("/downloads");
      })
      .catch((error) => {
        notifyError(
          "Erro ao criar lâmina: " + error.message ?? "Erro desconhecido"
        );
      });
  };

  const formik = useFormik<DownloadFormPageProps>({
    initialValues: {
      title: "",
      description: "",
      type: "",
      group: "",
      file: undefined,
    },
    validationSchema: schema,
    onSubmit: onSubmit,
  });

  return (
    <RoleGuard permit={["Admin"]} redirectIfNotPermit="/app/downloads">
      <FormPage
        title="Criar lâmina de atendimento"
        formik={formik}
        actions={
          <Box mt={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<Upload />}
              disabled={!formik.isValid}
              sx={{ width: "150px" }}
            >
              Upload
            </Button>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput name="title" label="Título" formik={formik} required />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="description"
              label="Descrição"
              formik={formik}
              rows={3}
              multiline
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput name="type" label="Tipo" formik={formik} required />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput name="group" label="Grupo" formik={formik} required />
          </Grid>
          <Grid item md={6} xs={12}>
            <MuiFileInput
              label="Arquivo"
              required
              value={formik.values.file}
              onChange={(file: File | null) => formik.setFieldValue("file", file)}
              onBlur={formik.handleBlur}
              error={Boolean(getNestedProperty(formik.errors, "file"))}
              helperText={errorText(formik, "file")}
              placeholder="Selecione um arquivo png ou jpeg ou pdf"
              fullWidth
              InputProps={{
                inputProps: { accept: ".png,.jpeg,.jpg,.pdf" },
                startAdornment: <AttachFile />,
              }}
              clearIconButtonProps={{
                title: "Remover",
                children: <Close fontSize="small" />,
              }}
            />
            {/* <TextInput name="group" label="Grupo" formik={formik} required /> */}
          </Grid>
        </Grid>
      </FormPage>
    </RoleGuard>
  );
}
