import { Id } from './../../../node_modules/@types/react-beautiful-dnd/index.d';
import api from "../../shared/utils/Api";
import { ICreateConsultaAgendaRequest } from "../models/CreateConsultaAgendaRequest";
import { IGetSchedulingByMonthResponse } from "../models/GetSchedulingByMonthResponse";

export const createAgendamento = async (
  request: ICreateConsultaAgendaRequest
) => {
  try {
    const response = await api.post("consultaAgenda", request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao criar o agendamento");
  }
};

export const updateAgendamento = async (
  id: string,
  request: {
    id: string,
    dataHora: string,
    obs?: string,
    status: string,
  }
) => {
  try {
    const response = await api.put(`consultaAgenda/${id}`, request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao atualizar o agendamento");
  }
};

export const deleteAgendamento = async (id: string) => {
  try {
    const response = await api.delete(`consultaAgenda/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao deletar o agendamento");
  }
};

export const getAllAgendamentoByMes = async (mes: number, ano: number) => {
  try {
    const response = await api.get<IGetSchedulingByMonthResponse[]>(
      "consultaAgenda/get-by-month",
      {
        params: {
          mes,
          ano,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Falha ao consultar os agendamento");
  }
};
