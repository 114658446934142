import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { isAllInputNotTouched } from "../../shared/utils/InputUtil";
import TextInput from "../../shared/components/TextInput";
import InputSelect from "../../shared/components/InputSelect";
import { v4 as uuidv4 } from "uuid";
import { DietaRefeicaoRequest } from "../models/IDietaRequest";

interface DietaRefeicaoDialogProps {
  open: boolean;
  dietaId: string;
  refeicao?: DietaRefeicaoRequest;
  onAdd: (refeicao: DietaRefeicaoRequest) => void;
  onClose: () => void;
}

interface IFormValues {
  nome: string;
  horario: string;
}

const schema = yup.object().shape({
  nome: yup.string().required("Campo obrigatório"),
  horario: yup.string().required("Campo obrigatório"),
});

const DietaRefeicaoDialog: React.FC<DietaRefeicaoDialogProps> = ({
  open,
  refeicao,
  dietaId,
  onAdd,
  onClose,
}) => {
  const onSubmit = (values: IFormValues) => {
    onAdd({
      id: refeicao ? refeicao.id! : uuidv4(),
      dietaId: dietaId,
      nome: values.nome,
      horario: values.horario,
      cardapios: [],
    });
    onClose();
    formik.resetForm();
  };

  const initialValues: IFormValues = {
    nome: refeicao?.nome ?? "",
    horario: refeicao?.horario ?? "",
  };

  const formik = useFormik<IFormValues>({
    initialValues,
    validationSchema: schema,
    onSubmit,
  });

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        Refeição
      </DialogTitle>
      <DialogContent>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12}>
            <TextInput label="Nome" name="nome" required formik={formik} />
          </Grid>
          <Grid item xs={12}>
            <InputSelect
              label="Horário"
              name="horario"
              required
              formik={formik}
              options={[
                {
                  value: "4:00",
                  label: "4:00",
                },
                {
                  value: "4:30",
                  label: "4:30",
                },
                {
                  value: "5:00",
                  label: "5:00",
                },
                {
                  value: "5:30",
                  label: "5:30",
                },
                {
                  value: "6:00",
                  label: "6:00",
                },
                {
                  value: "6:30",
                  label: "6:30",
                },
                {
                  value: "7:00",
                  label: "7:00",
                },
                {
                  value: "7:30",
                  label: "7:30",
                },
                {
                  value: "8:00",
                  label: "8:00",
                },
                {
                  value: "8:30",
                  label: "8:30",
                },
                {
                  value: "9:00",
                  label: "9:00",
                },
                {
                  value: "9:30",
                  label: "9:30",
                },
                {
                  value: "10:00",
                  label: "10:00",
                },
                {
                  value: "10:30",
                  label: "10:30",
                },
                {
                  value: "11:00",
                  label: "11:00",
                },
                {
                  value: "11:30",
                  label: "11:30",
                },
                {
                  value: "12:00",
                  label: "12:00",
                },
                {
                  value: "12:30",
                  label: "12:30",
                },
                {
                  value: "13:00",
                  label: "13:00",
                },
                {
                  value: "13:30",
                  label: "13:30",
                },
                {
                  value: "14:00",
                  label: "14:00",
                },
                {
                  value: "14:30",
                  label: "14:30",
                },
                {
                  value: "15:00",
                  label: "15:00",
                },
                {
                  value: "15:30",
                  label: "15:30",
                },
                {
                  value: "16:00",
                  label: "16:00",
                },
                {
                  value: "16:30",
                  label: "16:30",
                },
                {
                  value: "17:00",
                  label: "17:00",
                },
                {
                  value: "17:30",
                  label: "17:30",
                },
                {
                  value: "18:00",
                  label: "18:00",
                },
                {
                  value: "18:30",
                  label: "18:30",
                },
                {
                  value: "19:00",
                  label: "19:00",
                },
                {
                  value: "19:30",
                  label: "19:30",
                },
                {
                  value: "20:00",
                  label: "20:00",
                },
                {
                  value: "20:30",
                  label: "20:30",
                },
                {
                  value: "21:00",
                  label: "21:00",
                },
                {
                  value: "21:30",
                  label: "21:30",
                },
                {
                  value: "22:00",
                  label: "22:00",
                },
                {
                  value: "22:30",
                  label: "22:30",
                },
                {
                  value: "23:00",
                  label: "23:00",
                },
                {
                  value: "23:30",
                  label: "23:30",
                },
              ]}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isAllInputNotTouched(formik) || !formik.isValid}
          onClick={() => formik.submitForm()}
          style={{ color: "white" }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DietaRefeicaoDialog;
