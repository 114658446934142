import { Enum } from "../models/Enum";

export class EscolaridadeEnum extends Enum {
  constructor() {
    super(
      new Map<string, string>([
        ["ENSINO_FUNDAMENTAL", "Ensino fundamental"],
        ["ENSINO_MEDIO", "Ensino médio"],
        ["ENSINO_TECNICO", "Ensino técnico"],
        ["ENSINO_SUPERIOR", "Ensino superior"],
      ]),
    );
  }
}
