import { InputAdornment, TextField } from "@mui/material";
import { useMask } from "@react-input/mask";
import { FormikProps } from "formik";
import React from "react";
import { errorText } from "../utils/InputUtil";

interface TextInputMaskedProps {
  label: string;
  name: string;
  mask: string;
  endAdornment?: string;
  required?: boolean;
  formik?: FormikProps<any>;
  value?: string;
  onChange?: (value: string) => void;
}

const TextInputMasked: React.FC<TextInputMaskedProps> = ({
  name,
  label,
  mask,
  endAdornment,
  ...other
}) => {
  const maskRef = useMask({
    mask: mask,
    replacement: "#",
  });

  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      required={other.required}
      fullWidth
      inputRef={maskRef}
      value={other.value ? other.value : other.formik ? other.formik.values[name] : ""}
      onChange={other.onChange ? e => other.onChange!(e.target.value) : other.formik ? other.formik.handleChange : undefined}
      onBlur={other.formik?.handleBlur}
      error={
        other.formik && other.formik.touched[name] && Boolean(other.formik.errors[name])
      }
      helperText={other.formik ? errorText(other.formik, name) : undefined}
      InputProps={
        endAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ),
            }
          : undefined
      }
    />
  );
};

export default TextInputMasked;
