import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

interface DeleteContentDialogProps {
  open: boolean;
  title?: string;
  message: string;
  onAccept: () => void;
  onClose: () => void;
}

const DeleteContentDialog: React.FC<DeleteContentDialogProps> = ({
  open,
  message,
  title,
  onAccept,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            onAccept();
            onClose();
          }}
        >
          Remover
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteContentDialog;
