import api from "../../shared/utils/Api";
import { IPerfilResponse } from "../models/IPerfilResponse";

export class PerfilService {
  public static async update(perfil: FormData) {
    try {
      await api.put(`perfil`, perfil);
      return;
    } catch (error) {
      throw new Error("Falha ao atualizar perfil");
    }
  }

  public static async getByEmail(email: string) {
    try {
      var response = await api.get<IPerfilResponse | null>(`perfil`, { params: { email } });
      return response.data;
    } catch (error) {
      throw new Error("Falha ao consultar perfil");
    }
  }
}
