import React, { useEffect } from "react";
import { TableRow, TableCell, Paper, Checkbox } from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  CADASTRAR_ALIMENTO,
  EDITAR_ALIMENTO,
} from "../../shared/components/AppRoutes";
import { IGetAllFoodResponse } from "../models/IGetAllFoodResponse";
import { FoodService } from "../services/FoodService";
import ContainerCustom from "../../shared/components/ContainerCustom";
import ListAppBar from "../../medidaCaseira/components/ListAppBar";
import { usePage } from "../../shared/hooks/PageHook";
import TableCustom from "../../shared/components/TableCustom";
import FilterFoodDialog, { IFoodFilter } from "../dialogs/FilterFoodDialog";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";

const FoodListPage: React.FC = () => {
  const [isSelectingMode, setIsSelectingMode] = React.useState<boolean>(false);
  const [selecteds, setSelecteds] = React.useState<string[]>([]);
  const { page, count, rowsPerPage, setRowsPerPage, setCount, setPage } =
    usePage();
  const [isFilterDialogOpened, setIsFilterDialogOpened] =
    React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<IFoodFilter | null>(null);

  const [listFood, setFoods] = React.useState<IGetAllFoodResponse[]>([]);

  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const handleEdit = () => {
    navigate(EDITAR_ALIMENTO(selecteds[0]!));
  };

  const handleDelete = () => {
    if (selecteds.length === 0) return;

    FoodService.deleteAll(selecteds)
      .then(() => {
        notifySuccess("Alimentos excluídos com sucesso!");
        setPage(0);
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  const handleSelect = (id: string) => {
    let newSelecteds: string[] = [];

    if (selecteds.includes(id)) {
      newSelecteds = selecteds.filter((item) => item !== id);
      setSelecteds(newSelecteds);
    } else {
      newSelecteds = [...selecteds, id];
      setSelecteds(newSelecteds);
    }

    if (newSelecteds.length === 0) {
      isSelectingMode && setIsSelectingMode(false);
    } else {
      !isSelectingMode && setIsSelectingMode(true);
    }
  };

  const handleSelectOrDesselectAll = (checked: boolean) => {
    if (checked) {
      setSelecteds(listFood.map((food) => food.id!));
      !isSelectingMode && setIsSelectingMode(true);
    } else {
      setSelecteds([]);
      isSelectingMode && setIsSelectingMode(false);
    }
  };

  const handleFilter = (filter: IFoodFilter) => {
    setFilter(filter);
    setPage(0);
  };

  const loadPage = () => {
    FoodService.getAll({
      page,
      numPerPage: rowsPerPage,
      nome: filter?.nome,
      grupo: filter
        ? filter.grupo !== "NULL"
          ? filter.grupo
          : undefined
        : undefined,
    })
      .then((response) => {
        if (response.count !== count) {
          setCount(response.count);
        }

        setFoods(response.data);
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  // useEffect(() => {
  //   loadPage();
  // }, []);

  useEffect(() => {
    loadPage();
  }, [page, rowsPerPage, filter]);

  return (
    <ContainerCustom>
      <Paper style={{ boxShadow: "none" }}>
        <ListAppBar
          title="Meus Alimentos"
          isEditEnabled={isSelectingMode}
          numSelecteds={selecteds.length}
          removeTitle="Deseja realmente excluir os alimentos selecionados?"
          removeMessage="Essa operação não poderá ser desfeita."
          onDelete={handleDelete}
          onFilter={() => setIsFilterDialogOpened(true)}
          menuActions={
            selecteds.length === 1
              ? [
                  {
                    title: "Adicionar",
                    onClick: () => {
                      navigate(CADASTRAR_ALIMENTO);
                    },
                    icon: <Add color="action" />,
                  },
                  {
                    title: "Editar",
                    onClick: () => handleEdit(),
                    icon: <Edit color="action" />,
                  },
                ]
              : [
                  {
                    title: "Adicionar",
                    onClick: () => {
                      navigate(CADASTRAR_ALIMENTO);
                    },
                    icon: <Add color="action" />,
                  },
                ]
          }
        />
        <TableCustom
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(page) => setPage(page)}
          onRowsPerPageChange={(page) => {
            setRowsPerPage(page);
          }}
          rowCount={listFood.length}
          selectedCouunt={selecteds.length}
          emptyText={"Nenhum alimento encontrado."}
          headers={[
            { id: "nome", label: "Nome" },
            { id: "kcal", label: "Kcal" },
            { id: "lip", label: "Lip (g)" },
            { id: "ptn", label: "Ptn (g)" },
            { id: "cho", label: "Cho (g)" },
            { id: "grupo", label: "Grupo" },
          ]}
          onDesselectAll={() => handleSelectOrDesselectAll(false)}
          onSelectAll={() => handleSelectOrDesselectAll(true)}
        >
          {listFood.map((food) => {
            const isItemSelected = selecteds.includes(food.id!);

            return (
              <TableRow
                key={food.id}
                hover
                onClick={(event) => handleSelect(food.id!)}
                selected={isItemSelected}
                role="checkbox"
                sx={{ cursor: "pointer" }}
              >
                <TableCell>
                  <Checkbox color="primary" checked={isItemSelected} />
                </TableCell>
                <TableCell>{food.nome}</TableCell>
                <TableCell>{food.kcal}</TableCell>
                <TableCell>{food.lipidio}</TableCell>
                <TableCell>{food.proteina}</TableCell>
                <TableCell>{food.carboidrato}</TableCell>
                <TableCell>{food.tipoAlimento}</TableCell>
              </TableRow>
            );
          })}
        </TableCustom>
      </Paper>

      <FilterFoodDialog
        open={isFilterDialogOpened}
        onClose={() => setIsFilterDialogOpened(false)}
        onFilter={handleFilter}
      />
    </ContainerCustom>
  );
};

export default FoodListPage;
