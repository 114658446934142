import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContainerCustom from "../../shared/components/ContainerCustom";
import { Box, Button, Grid, Typography } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextInput from "../../shared/components/TextInput";
import { createEntry } from "../../shared/utils/CrudService";
import { LOGIN } from "../../shared/components/AppRoutes";
import YupPassword from "yup-password";
import { display } from "@mui/system";
import { Login } from "@mui/icons-material";

YupPassword(Yup); // extend yup

interface IResetPasswordPageRequest {
  email: string;
  password: string;
  token: string;
}

const schema = Yup.object().shape({
  email: Yup.string().required("Campo obrigatório"),
  token: Yup.string().required("Campo obrigatório"),
  password: Yup.string()
    .password()
    .required("Campo obrigatória")
    .min(6, "A senha deve ter no mínimo 6 caracteres")
    .minLowercase(1, "A senha deve ter no mínimo 1 letra minúscula")
    .minUppercase(1, "A senha deve ter no mínimo 1 letra maiúscula")
    .minSymbols(1, "A senha deve ter no mínimo 1 caractere especial")
    .minNumbers(1, "A senha deve ter no mínimo 1 número"),
});

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const onSubmit = (values: IResetPasswordPageRequest) => {
    createEntry("user/reset-password", values)
      .then(() => {
        navigate(LOGIN);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const formik = useFormik<IResetPasswordPageRequest>({
    validationSchema: schema,
    initialValues: {
      email: urlParams.get("email") || "",
      token: urlParams.get("token") || "",
      password: "",
    },
    onSubmit: onSubmit,
  });

  return (
    // <ContainerCustom>
    //   <Box
    //     display="flex"
    //     flexDirection="column"
    //     justifyContent="center"
    //     height="100vh"
    //   >
    // <Grid container>
    //   <Grid
    //     item
    //     xs={12}
    //     md={6}
    //     sx={{
    //       display: "flex",
    //       flexDirection: "column",
    //       justifyContent: "center",
    //       alignItems: "flex-start",
    //     }}
    //   >
    //     <form onSubmit={formik.handleSubmit}>
    //       <Typography variant="h6">Recupere sua senha</Typography>

    //       <Typography color="text.secondary" marginTop={4}>
    //         Digite sua nova senha.
    //       </Typography>
    //       <Box marginTop={1} width="100%">
    //         <TextInput
    //           label="Senha"
    //           name="password"
    //           required
    //           password
    //           formik={formik}
    //         />
    //       </Box>
    //       <Button
    //         color="primary"
    //         variant="contained"
    //         type="submit"
    //         disabled={!formik.isValid}
    //         sx={{ width: "120px", mt: 2 }}
    //       >
    //         Salvar
    //       </Button>
    //     </form>
    //   </Grid>
    //       <Grid item xs={0} md={6}>
    //         <img
    //           src={process.env.PUBLIC_URL + "/login-logo.png"}
    //           alt="Login logo"
    //         />
    //       </Grid>
    //     </Grid>
    //   </Box>
    // </ContainerCustom>
    <Box sx={{ height: "100vh", backgroundColor: "#fffbfb" }}>
      <Grid container component="main" sx={{ height: "100%" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              pr: 10,
              pl: 10,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                my: "7vh",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/diety-logo.png"}
                alt="login-logo"
                style={{ width: "10rem" }}
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                mb: 3,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <Box
                    sx={{
                      mt: 2,
                      mb: 3,
                    }}
                  >
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      Recupere sua senha.
                    </Typography>
                  </Box>
                  <Typography style={{ color: "#0D0D0D" }}>
                    Digite sua nova senha.
                  </Typography>
                  <Box width="100%">
                    <TextInput
                      label="Senha"
                      name="password"
                      required
                      password
                      formik={formik}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      style={{ color: "white" }}
                      disabled={!formik.isValid}
                      sx={{ mt: 2 }}
                      startIcon={<Login sx={{ color: "white" }} />}
                    >
                      Salvar
                    </Button>
                  </Box>
                </form>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            display: { xs: "none", lg: "flex" },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/sign-up-image.png"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Grid>
      </Grid>
    </Box>
  );
}
