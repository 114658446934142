import { IAutocompleteResponse } from '../models/AutocompleteResponse';
import { ODataResult } from '../models/ODataResult';
import api from '../utils/Api';

export async function getAllFoods() {
    try {
        const response = await api.get<ODataResult<IAutocompleteResponse[]>>('alimento/odata', {
            params: {
                $select: 'id,nome,kcal,proteina,lipidio,carboidrato',
                $orderby: 'nome asc',
            },
        });

        return response.data.data;
    } catch (error) {
        throw error;
    }
}

export async function getAllMedidaCaseiraForFood(alimentoId: string) {
    try {
        const response = await api.get<ODataResult<IAutocompleteResponse[]>>('medidaCaseira/odata', {
            params: {
                $select: 'id,nome,equivalenteGramas,tamanhoMedida',
                $expand: 'alimento',
                $orderby: 'nome asc',
                $filter: `alimentoId eq ${alimentoId}`,
            },
        });

        return response.data.data;
    } catch (error) {
        throw error;
    }
}

export async function getAllAutocomplete(url: string, isOData = true) {
    try {
        if (!isOData) {
            const response = await api.get<IAutocompleteResponse[]>(url);
            return response.data;
        }

        const response = await api.get<ODataResult<IAutocompleteResponse[]>>(`${url}/odata`, {
            params: {
                $select: 'id,nome',
                $orderby: 'nome asc',
            },
        });

        return response.data.data;
    } catch (error) {
        throw error;
    }
}
