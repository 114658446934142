import { InputAdornment, TextField } from "@mui/material";
import { useNumberFormat } from "@react-input/number-format";
import { FormikProps } from "formik";
import React from "react";
import { errorText } from "../utils/InputUtil";

interface NumberInputProps {
  label: string;
  name: string;
  value?: string;
  endAdornment?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
  marginTop?: number;
  formik?: FormikProps<any>;
}

const NumberInput: React.FC<NumberInputProps> = ({ value, name, label, marginTop,  endAdornment, onChange, required, disabled, formik }) => {
  const maskRef = useNumberFormat({
    locales: "pt-BR",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  return (
    <TextField
      variant="outlined"
      label={label}
      required={required}
      disabled={disabled}
      name={name}
      fullWidth
      inputRef={maskRef}
      value={value ? value : formik ? formik.values[name] : ""}
      onChange={onChange ? e => onChange!(e.target.value) : formik ? formik.handleChange : undefined}
      onBlur={formik?.handleBlur}
      error={
        formik && formik.touched[name] && Boolean(formik.errors[name])
      }
      helperText={formik ? errorText(formik, name) : undefined}
      InputProps={endAdornment ? {
        inputProps: { style: { textAlign: "right" } },
        endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
      } : undefined}
      sx={{ marginTop: marginTop ? marginTop : 0 }}
    />
  );
};

export default NumberInput;
