import { hookstate } from "@hookstate/core";

export interface IApplicationColorsState {
  primaryColor: string;
  secondaryColor: string;
}

export const appStyleState = hookstate<IApplicationColorsState>({
  primaryColor: "#9D7DF0",
  secondaryColor: "#E1333D",
});
