import { Enum } from "../models/Enum";

export class SexoEnum extends Enum {
  constructor() {
    super(
      new Map<string, string>([
        ["MASCULINO", "Masculino"],
        ["FEMININO", "Feminino"],
      ]),
    );
  }
}
