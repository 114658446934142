import { useLocation, useNavigate } from "react-router-dom";
import ContainerCustom from "../../shared/components/ContainerCustom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { createEntry } from "../../shared/utils/CrudService";
import { LOGIN } from "../../shared/components/AppRoutes";
import { Login } from "@mui/icons-material";

interface IValidateEmailRequest {
  email: string;
  token: string;
}

export default function ValidateEmailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const onSubmit = () => {
    const request: IValidateEmailRequest = {
      email: urlParams.get("email") || "",
      token: urlParams.get("token") || "",
    };

    createEntry("user/validate-email", request)
      .then(() => {
        navigate(LOGIN);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  return (
    <Box sx={{ height: "100vh", backgroundColor: "#fffbfb" }}>
      <Grid container component="main" sx={{ height: "100%" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              pr: 10,
              pl: 10,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                my: "7vh",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/diety-logo.png"}
                alt="login-logo"
                style={{ width: "10rem" }}
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                mb: 3,
              }}
            >
              <Typography variant="h4" fontWeight="bold" textAlign="center">
                Valide sua conta.
              </Typography>
            </Box>
            <Typography style={{ color: "#0D0D0D" }}>
              Clique no botão abaixo para validar sua conta.
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={onSubmit}
              style={{ color: "white" }}
              sx={{ mt: 2 }}
              startIcon={<Login sx={{ color: "white" }} />}
            >
              Validar
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            display: { xs: "none", lg: "flex" },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/sign-up-image.png"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Grid>
      </Grid>
    </Box>
  );
}
