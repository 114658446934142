import { Enum } from "../models/Enum";

export class TipoRespostaEnum extends Enum {
  constructor() {
    super(
      new Map<string, string>([
        ["TEXTO", "Texto"],
        ["CHECKBOX", "Checkbox"],
        ["RADIO", "Radio"],
        ["SELECT", "Select"],
      ]),
    );
  }
}
