import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { Container, Box } from "@mui/system";
import React, { useEffect } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BarChartIcon from "@mui/icons-material/BarChart";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link as RouterLink } from "react-router-dom";
import {
  AGENDAMENTO,
  AVALIACAO_DASHBOARD,
  CADASTRAR_AGENDAMENTO,
  CADASTRAR_PACIENTE,
} from "../../shared/components/AppRoutes";
import {
  GetHomeResponse,
  HomeChartIdadeResponse,
} from "../models/GetHomeResponse";
import { getHome } from "../services/HomeService";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { iniciarConsulta } from "../../consulta/services/ConsultaService";
import { BarChart } from "@mui/x-charts";

type AgeGroup = {
  qtd: number;
  faixa: string;
};

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const [home, setHome] = React.useState<GetHomeResponse | null>(null);

  const iniciar = (pacienteId: string, id: string) => {
    iniciarConsulta(id)
      .then(() => {
        navigate(AVALIACAO_DASHBOARD(pacienteId, id));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const groupAges = (ages: HomeChartIdadeResponse[]): AgeGroup[] => {
    const ageCounts: { [faixa: string]: number } = {};

    for (let age of ages) {
      let group = Math.floor((age.idade - 18) / 10);
      if (group < 0) group = 0;
      if (group > 6) group = 6;

      let lowerBound = group * 10 + 18;
      let upperBound = lowerBound + 9;
      if (group === 6) upperBound = 80;

      let faixa = `${lowerBound}-${upperBound} anos`;

      if (ageCounts[faixa]) {
        ageCounts[faixa]++;
      } else {
        ageCounts[faixa] = 1;
      }
    }

    const ageGroups: AgeGroup[] = Object.entries(ageCounts).map(
      ([faixa, qtd]) => ({
        faixa,
        qtd,
      })
    );

    return ageGroups;
  };

  useEffect(() => {
    getHome().then((response) => {
      setHome(response);
    });
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
      <Box mt={4} display="flex" justifyContent="center">
        <Grid
          container
          spacing={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              style={{ width: 300, height: 100, color: "white" }}
              className="image-hover"
              component={RouterLink}
              to={CADASTRAR_AGENDAMENTO}
            >
              <CalendarMonthIcon
                className="home-icons"
                fontSize="large"
                style={{ marginRight: 15 }}
              />
              Agendar Consulta
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              style={{ width: 300, height: 100, color: "white" }}
              className="image-hover"
              component={RouterLink}
              to={AGENDAMENTO}
            >
              <BarChartIcon
                className="home-icons"
                fontSize="large"
                style={{ marginRight: 15 }}
              />
              Histórico de Consultas
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="info"
              variant="contained"
              style={{ width: 300, height: 100, color: "white" }}
              className="image-hover"
              component={RouterLink}
              to={CADASTRAR_PACIENTE}
            >
              <AccountCircleIcon
                className="home-icons"
                fontSize="large"
                style={{ marginRight: 15 }}
              />
              Cadastrar Paciente
            </Button>
          </Grid>
        </Grid>
      </Box>
      {home?.proximaConsulta && (
        <Card style={{ boxShadow: "none", marginTop: 70 }}>
          <CardContent>
            <Typography variant="h6">Próxima Consulta</Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <AccountCircleIcon
                  color="primary"
                  style={{ marginRight: 15, fontSize: 75 }}
                />
              </Box>
              <Box flexGrow={1}>
                <Typography variant="subtitle1">
                  {home.proximaConsulta?.paciente}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {home.proximaConsulta?.idade} anos
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" align="center">
                  {moment(home.proximaConsulta?.data).format("DD/MM/YYYY")} às{" "}
                  {moment(home.proximaConsulta?.data).format("HH:mm")}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ color: "white" }}
                  onClick={() =>
                    iniciar(
                      home.proximaConsulta?.pacienteId!,
                      home.proximaConsulta?.id!
                    )
                  }
                >
                  Iniciar Consulta
                  <ArrowForwardIcon
                    className="home-icons"
                    style={{ marginLeft: 10 }}
                  />
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Card style={{ boxShadow: "none" }}>
            <CardHeader title="Novos Pacientes e Consultas por Mês" />
            <CardContent>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {home && home.idades.length > 0 ? (
                  <BarChart
                    dataset={
                      home?.novosPacientes.map((item) => ({
                        mes: item.mes,
                        quantidade: item.quantidade,
                      })) || []
                    }
                    xAxis={[
                      {
                        scaleType: "band",
                        dataKey: "mes",
                        valueFormatter: (value: number) =>
                          moment()
                            .month(value - 1)
                            .format("MMM"),
                      },
                    ]}
                    series={[{ dataKey: "quantidade", label: "Quantidade" }]}
                    width={450}
                    height={300}
                  />
                ) : (
                  <Typography variant="body1" color="text.secondary">
                    Nenhum dado cadastrado
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ boxShadow: "none" }}>
            <CardHeader title="Faixa Etária dos Pacientes" />
            <CardContent>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {home && home.idades.length > 0 ? (
                  <BarChart
                    dataset={groupAges(home?.idades || [])}
                    xAxis={[
                      {
                        scaleType: "band",
                        dataKey: "faixa",
                        tickMinStep: 1,
                        tickSize: 1,
                      },
                    ]}
                    series={[{ dataKey: "qtd", label: "Quantidade" }]}
                    width={450}
                    height={300}
                  />
                ) : (
                  <Typography variant="body1" color="text.secondary">
                    Nenhum dado cadastrado
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;
