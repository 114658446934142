import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import TextInput from "../../shared/components/TextInput";
import InputSelect from "../../shared/components/InputSelect";
import { getTipoAlimentoForSelect } from "../../shared/enums/TipoAlimentoEnum";

interface FilterFoodDialogProps {
  id?: string;
  open: boolean;
  onFilter: (filter: IFoodFilter) => void;
  onClose: () => void;
}

export interface IFoodFilter {
  nome?: string;
  grupo?: string;
}

const FilterFoodDialog: React.FC<FilterFoodDialogProps> = ({
  id,
  open,
  onFilter,
  onClose,
}) => {
  const [filterForm, setFilterForm] = React.useState<{
    nome: string;
    grupo: string;
  }>({
    nome: "",
    grupo: "NULL",
  });

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => onClose()}>
      <DialogTitle variant="h6" color="secondary">
        Filtre por:
      </DialogTitle>
      <DialogContent>
        <Grid container p={1} spacing={1} rowSpacing={2}>
          <Grid item xs={12}>
            <TextInput
              name="nome"
              label="Nome"
              required={false}
              value={filterForm.nome}
              onChange={(e) => setFilterForm({ ...filterForm, nome: e })}
            />
          </Grid>
          <Grid item xs={12}>
            <InputSelect
              name="grupo"
              label="Grupo"
              value={filterForm.grupo}
              onChange={(e) => {
                setFilterForm({
                  ...filterForm,
                  grupo: e.target.value as string,
                });
              }}
              options={[
                { value: "NULL", label: "Selecione" },
                ...getTipoAlimentoForSelect(),
              ]}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onFilter({});
            setFilterForm({
              ...filterForm,
              nome: "",
              grupo: "NULL",
            });
            onClose();
          }}
        >
          Limpar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onFilter({
              nome: filterForm.nome,
              grupo: filterForm.grupo,
            });
            setFilterForm({
              ...filterForm,
              nome: "",
              grupo: "NULL",
            });
            onClose();
          }}
          style={{ color: "white" }}
        >
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterFoodDialog;
