import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  List,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PatientSelectedCard from "../../shared/components/PatientSelectedCard";
import {
  deleteEvaluation,
  getAllEvalutions,
} from "../services/EvalutionService";
import {
  AVALIACAO_ANAMNESE,
  AVALIACAO_ANTROPOMETRIA,
  AVALIACAO_DASHBOARD,
  AVALIACAO_DIETA,
  AVALIACAO_EXAME_FISICO,
  AVALIACAO_EXAME_QUIMICO,
  AVALIACAO_ORIENTACAO,
} from "../../shared/components/AppRoutes";
import { IGetAvaliacaoConsultaDashboardResponse } from "../models/IGetAvaliacaoConsultaDashboardResponse";
import SelecionarRecomendacaoDialog from "../dialogs/SelecionarRecomendacaoDialog";
import EvaluationListItem from "../components/EvaluationListItem";
import SelecionarPerguntaDialog from "../dialogs/SelecionarPerguntaDialog";
import NovaAvaliacaoButton, {
  AvaliacaoType,
} from "../components/NovaAvaliacaoButton";
import useNovaAvaliacao from "../hooks/NovaAvaliacaoHook";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";

const EvaluationDashboardPage: React.FC = () => {
  const { id, consulta_id } = useParams();
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const theme = useTheme();
  const [avaliacoes, setAvaliacoes] = React.useState<
    IGetAvaliacaoConsultaDashboardResponse[]
  >([]);

  const {
    onNovaAvaliacao,
    isSelecionarAnamneseDialogOpen,
    setIsSelecionarAnamneseDialogOpen,
    isSelecionarRecomendacaoDialogOpen,
    setIsSelecionarRecomendacaoDialogOpen,
    onSelectAnamnese,
    onSelectRecomendacao,
    convertStringToAvaliacaoType,
  } = useNovaAvaliacao({ id: id!, consulta_id: consulta_id! });

  const cores: Map<string, string> = new Map([
    ["Composição Corporal", "#ffae5d"],
    ["Exames Físicos", "#ffae5d"],
    ["Exames Laboratoriais", "#ffae5d"],
    ["Plano Alimentar - Livre", "#ffae5d"],
    ["Plano Alimentar - Equivalentes", "#ffae5d"],
    ["Recomendações", "#ffae5d"],
    ["Anamnese", "#ffae5d"],
  ]);

  const navigateToPaginaInicial = () => {
    navigate(AVALIACAO_DASHBOARD(id!, consulta_id!));
  };

  const handleEditAvaliacao = (type: AvaliacaoType, avaliacaoId: string) => {
    if (type === AvaliacaoType.ANAMNESE) {
      navigate(AVALIACAO_ANAMNESE(id!, consulta_id!, avaliacaoId));
    } else if (type === AvaliacaoType.EXAME_FISICO) {
      navigate(AVALIACAO_EXAME_FISICO(id!, consulta_id!, avaliacaoId));
    } else if (type === AvaliacaoType.EXAME_LABORATORIAL) {
      navigate(AVALIACAO_EXAME_QUIMICO(id!, consulta_id!, avaliacaoId));
    } else if (type === AvaliacaoType.COMPOSICAO_CORPORAL) {
      navigate(AVALIACAO_ANTROPOMETRIA(id!, consulta_id!, avaliacaoId));
    } else if (
      type === AvaliacaoType.PLANO_ALIMENTAR_LIVRE ||
      type === AvaliacaoType.PLANO_ALIMENTAR_EQUIVALENTES
    ) {
      navigate(AVALIACAO_DIETA(id!, consulta_id!, avaliacaoId));
    } else if (type === AvaliacaoType.RECOMENDACOES) {
      navigate(AVALIACAO_ORIENTACAO(id!, consulta_id!, avaliacaoId));
    }
  };

  const handleDeleteAvaliacao = (type: AvaliacaoType, avaliacaoId: string) => {
    deleteEvaluation(type, avaliacaoId).then(() => {
      if (consulta_id) {
        getAllEvalutions(consulta_id)
          .then((response) => {
            notifySuccess("Avaliação excluída com sucesso!");
            setAvaliacoes(response);
          })
          .catch((error) => notifyError(error));
      }
    });
  };

  useEffect(() => {
    if (consulta_id) {
      getAllEvalutions(consulta_id)
        .then((response) => {
          setAvaliacoes(response);
        })
        .catch((error) => notifyError(error));
    }
  }, [consulta_id]);

  return (
    <Container>
      <PatientSelectedCard patientId={id} showPaginaInicialButton={false}>
        <NovaAvaliacaoButton onClick={(av) => onNovaAvaliacao(av)} />
      </PatientSelectedCard>
      <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
        <CardHeader
          title="Avaliações do Paciente"
          style={{
            color: theme.palette.secondary.main,
          }}
        />
        <CardContent>
          {avaliacoes.length > 0 ? (
            <List>
              {avaliacoes.map((avaliacao, index) => (
                <EvaluationListItem
                  key={`eval-${index}`}
                  cores={cores}
                  avaliacao={avaliacao}
                  onDelete={() => {
                    handleDeleteAvaliacao(
                      convertStringToAvaliacaoType(avaliacao.tipo),
                      avaliacao.id
                    );
                  }}
                  onEdit={() =>
                    handleEditAvaliacao(
                      convertStringToAvaliacaoType(avaliacao.tipo),
                      avaliacao.id
                    )
                  }
                />
              ))}
            </List>
          ) : (
            <Typography>Nenhuma avaliação cadastrada.</Typography>
          )}
        </CardContent>
      </Card>

      <SelecionarRecomendacaoDialog
        open={isSelecionarRecomendacaoDialogOpen}
        onClose={() => setIsSelecionarRecomendacaoDialogOpen(false)}
        onSelect={onSelectRecomendacao}
      />

      <SelecionarPerguntaDialog
        open={isSelecionarAnamneseDialogOpen}
        onClose={() => setIsSelecionarAnamneseDialogOpen(false)}
        onSelect={onSelectAnamnese}
      />
    </Container>
  );
};

export default EvaluationDashboardPage;
