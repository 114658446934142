import { useState } from "react";

export const usePage = () => {
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  return {
    page,
    count,
    rowsPerPage,
    setPage,
    setCount,
    setRowsPerPage,
  };
};
