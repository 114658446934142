import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { toString } from "../../shared/utils/InputUtil";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
  },
  header: {
    margin: 10,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 5,
  },
  headerLogoContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 5,
  },
  headerLogo: {
    width: 25,
    height: 25,
  },
  headerTitle: {
    position: "relative",
    left: 0,
    right: 0,
    top: 0,
    paddingTop: 7,
    paddingBottom: 7,
    marginTop: 20,
    textAlign: "center",
    backgroundColor: "#bbbbbb",
    fontWeight: "bold",
  },
  sectionContainer: {
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "flex-start",
    margin: 10,
  },
  section: {
    fontSize: 12,
    marginBottom: 7,
    width: "100%",
    fontWeight: "normal",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

interface ExportAntropometriaPdfProps {
  sexo: string;
  nome: string;
  idade: string;
  peso?: string;
  altura?: string;
  imc?: string;
  imcIdeal?: string;
  vet?: string;
  necessidadeHidrica?: string;
  porcentagemGordura?: string;
  porcentagemMuscular?: string;
  pesoGordo?: string;
  pesoMuscular?: string;
  pesoResidual?: string;
  pesoOsseo?: string;
  excessoPeso?: string;
  pesoMaximo?: string;
  pesoMinimo?: string;
  relacaoCinturaQuadril?: number;
}

export const ExportAntropometriaPdf = ({
  sexo,
  nome,
  idade,
  peso,
  altura,
  imc,
  imcIdeal,
  vet,
  necessidadeHidrica,
  porcentagemGordura,
  porcentagemMuscular,
  pesoGordo,
  pesoMuscular,
  pesoResidual,
  pesoOsseo,
  excessoPeso,
  pesoMaximo,
  pesoMinimo,
  relacaoCinturaQuadril,
}: ExportAntropometriaPdfProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerContainer}>
            <View style={styles.headerLogoContainer}>
              <Image src="/diety-logo.png" style={styles.headerLogo} />
              <Text>Diety</Text>
            </View>
            <Text style={{ fontSize: 10 }}>
              Data: {new Date().toLocaleDateString()}
            </Text>
          </View>
          <Text style={styles.headerTitle}>Composição corporal</Text>
        </View>

        <View
          style={{
            margin: 10,
            padding: 10,
            border: "2px solid #bbbbbb",
            flexDirection: "row",
            fontSize: 11,
            gap: 10,
          }}
        >
          <View style={{flexGrow: 1}}>
            <Text>Paciente</Text>
            <Text style={{ color: "#a3a3a3", fontSize: 12, }}>
              {nome}
            </Text>
          </View>
          <View>
            <Text>Idade</Text>
            <Text style={{ color: "#a3a3a3", fontSize: 12, }}>
              {idade} anos
            </Text>
          </View>
          <View>
            <Text>Sexo</Text>
            <Text style={{ color: "#a3a3a3", fontSize: 12, }}>
              {sexo === "M" ? "Masculino" : "Feminino"}
            </Text>
          </View>
        </View>

        <View style={styles.sectionContainer}>
          <View style={styles.section}>
            <Text>Peso</Text>
            <Text style={{ color: "#a3a3a3" }}>{peso ?? "-"} Kg</Text>
          </View>
          <View style={styles.section}>
            <Text>Altura</Text>
            <Text style={{ color: "#a3a3a3" }}>{altura ?? "-"} m</Text>
          </View>
          <View style={styles.section}>
            <Text>IMC</Text>
            <Text style={{ color: "#a3a3a3" }}>{imc ?? "-"} Kg/m²</Text>
          </View>
          <View style={styles.section}>
            <Text>IMC ideal</Text>
            <Text style={{ color: "#a3a3a3" }}>{imcIdeal ?? "-"} Kg/m²</Text>
          </View>
          <View style={styles.section}>
            <Text>Peso mínimo</Text>
            <Text style={{ color: "#a3a3a3" }}>{pesoMinimo ?? "-"} Kg</Text>
          </View>
          <View style={styles.section}>
            <Text>Peso máximo</Text>
            <Text style={{ color: "#a3a3a3" }}>{pesoMaximo ?? "-"} Kg</Text>
          </View>
          <View style={styles.section}>
            <Text>Peso gordo</Text>
            <Text style={{ color: "#a3a3a3" }}>{pesoGordo ?? "-"} Kg</Text>
          </View>
          <View style={styles.section}>
            <Text>Peso muscular</Text>
            <Text style={{ color: "#a3a3a3" }}>{pesoMuscular ?? "-"} Kg</Text>
          </View>
          <View style={styles.section}>
            <Text>Peso ósseo</Text>
            <Text style={{ color: "#a3a3a3" }}>{pesoOsseo ?? "-"} Kg</Text>
          </View>
          <View style={styles.section}>
            <Text>Peso residual</Text>
            <Text style={{ color: "#a3a3a3" }}>{pesoResidual ?? "-"} Kg</Text>
          </View>
          <View style={styles.section}>
            <Text>Excesso de Peso</Text>
            <Text style={{ color: "#a3a3a3" }}>{excessoPeso ?? "-"} Kg</Text>
          </View>
          <View style={styles.section}>
            <Text>Porcentagem muscular</Text>
            <Text style={{ color: "#a3a3a3" }}>
              {porcentagemMuscular ?? "-"} %
            </Text>
          </View>
          <View style={styles.section}>
            <Text>Porcentagem de gordura</Text>
            <Text style={{ color: "#a3a3a3" }}>
              {porcentagemGordura ?? "-"} %
            </Text>
          </View>
          <View style={styles.section}>
            <Text>Necessidade energética</Text>
            <Text style={{ color: "#a3a3a3" }}>{vet ?? "-"} kcal</Text>
          </View>
          <View style={styles.section}>
            <Text>Necessidade hídrica</Text>
            <Text style={{ color: "#a3a3a3" }}>
              {necessidadeHidrica ?? "-"} ml
            </Text>
          </View>
          <View style={styles.section}>
            <Text>Relação cintura-quadril</Text>
            <Text style={{ color: "#a3a3a3" }}>
              {toString(relacaoCinturaQuadril) ?? "-"}
            </Text>
          </View>
        </View>

        <View
          style={{
            border: "2px solid #bbbbbb",
            flexDirection: "column",
            margin: 10,
            padding: 10,
            minHeight: "35px",
          }}
        >
          {relacaoCinturaQuadril &&
            sexo === "M" &&
            relacaoCinturaQuadril >= 0.96 &&
            relacaoCinturaQuadril! <= 1.0 && (
              <Text style={{ fontSize: 11 }}>
                * Risco moderado de doenças cardiovasculares
              </Text>
            )}
          {relacaoCinturaQuadril &&
            sexo === "M" &&
            relacaoCinturaQuadril >= 1.1 &&
            relacaoCinturaQuadril <= 1.2 && (
              <Text style={{ fontSize: 11 }}>
                * Risco alto de doenças cardiovasculares
              </Text>
            )}
          {relacaoCinturaQuadril &&
            sexo === "M" &&
            relacaoCinturaQuadril > 1.2 && (
              <Text style={{ fontSize: 11 }}>
                * Risco muito alto de doenças cardiovasculares
              </Text>
            )}
          {relacaoCinturaQuadril &&
            sexo === "F" &&
            relacaoCinturaQuadril >= 0.81 &&
            relacaoCinturaQuadril! <= 0.85 && (
              <Text style={{ fontSize: 11 }}>
                * Risco moderado de doenças cardiovasculares
              </Text>
            )}
          {relacaoCinturaQuadril &&
            sexo === "F" &&
            relacaoCinturaQuadril > 0.85 &&
            relacaoCinturaQuadril <= 0.88 && (
              <Text style={{ fontSize: 11 }}>
                * Risco alto de doenças cardiovasculares
              </Text>
            )}
          {relacaoCinturaQuadril &&
            sexo === "F" &&
            relacaoCinturaQuadril > 0.88 && (
              <Text style={{ fontSize: 11 }}>
                * Risco muito alto de doenças cardiovasculares
              </Text>
            )}
        </View>
      </Page>
    </Document>
  );
};
