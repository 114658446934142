import { Void } from "../../shared/models/Void";
import { ResponseApi } from "../../shared/models/ResponseApi";
import api from "../../shared/utils/Api";
import { IRegisterUserRequest } from "../models/RegisterUserRequest";
import { ILoggedUserResponse } from "../models/ILoggedUserResponse";

class AuthService {
  public static async login(
    email: string,
    password: string
  ): Promise<ILoggedUserResponse> {
    try {
      const response = await api.post<ILoggedUserResponse>("user/login", {
        email,
        password,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public static async loginGoogle(
    code: string
  ): Promise<ILoggedUserResponse> {
    try {
      const response = await api.post<ILoggedUserResponse>("user/login-google", {
        code,
      });
      return response.data;
    } catch (error) {
      throw new Error("Falha ao fazer login");
    }
  }

  public static async register(data: IRegisterUserRequest): Promise<Void> {
    try {
      const response = await api.post<ResponseApi<Void>>("user", data);
      return response.data.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}

export default AuthService;
