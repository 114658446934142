import { Card, CardHeader, Input, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useEffect, useState } from "react";
import DateInput from "../../shared/components/DateInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextInput from "../../shared/components/TextInput";
import InputSelect from "../../shared/components/InputSelect";
import { SexoEnum } from "../../shared/enums/SexoEnum";
import ContainerCustom from "../../shared/components/ContainerCustom";
import { useAuth } from "../../shared/hooks/AuthHook";
import { PerfilService } from "../services/PerfilService";
import { baseURL } from "../../shared/utils/Api";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";

interface IPerfilForm {
  primeiroNome: string;
  ultimoNome: string;
  sexo: string;
  crn: string;
  dataNascimento: string;
}

const schema = Yup.object().shape({
  primeiroNome: Yup.string().required("Campo obrigatório"),
  ultimoNome: Yup.string().required("Campo obrigatório"),
  sexo: Yup.string().required("Campo obrigatório"),
  crn: Yup.string().required("Campo obrigatório"),
  dataNascimento: Yup.string().required("Campo obrigatório"),
});

const PerfilPage: React.FC = () => {
  const { userLogged } = useAuth();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const theme = useTheme();
  const [selectedPerfilPhoto, setSelectedPerfilPhoto] = useState<
    File | string | null
  >(null);
  const [selectedLogoPhoto, setSelectedLogoPhoto] = useState<
    File | string | null
  >(null);

  const genderOptions = new SexoEnum().getForSelect();

  const initialValues: IPerfilForm = {
    primeiroNome: "",
    ultimoNome: "",
    sexo: "",
    crn: "",
    dataNascimento: "",
  };

  const onSubmit = (values: IPerfilForm) => {
    const form = new FormData();
    form.append("email", userLogged()!.email);
    form.append("primeiroNome", values.primeiroNome);
    form.append("ultimoNome", values.ultimoNome);
    form.append("sexo", values.sexo);
    form.append("crn", values.crn);
    form.append("dataNascimento", values.dataNascimento);

    if (selectedPerfilPhoto) {
      form.append("imagemId", selectedPerfilPhoto);
    }
    if (selectedLogoPhoto) {
      form.append("logoId", selectedLogoPhoto);
    }

    PerfilService.update(form)
      .then((response) => notifySuccess("Perfil atualizado com sucesso!"))
      .catch((error) => {
        notifyError(error);
      });
  };

  const formik = useFormik<IPerfilForm>({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: onSubmit,
  });

  const handlePerfilPhotoFileChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    setSelectedPerfilPhoto(file);
  };

  const handleLogoFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    setSelectedLogoPhoto(file);
  };

  useEffect(() => {
    if (userLogged) {
      PerfilService.getByEmail(userLogged()!.email)
        .then((response) => {
          if (!response) return;

          formik.setFieldValue("primeiroNome", response.primeiroNome);
          formik.setFieldValue("ultimoNome", response.ultimoNome);
          formik.setFieldValue("sexo", response.sexo);
          formik.setFieldValue("crn", response.crn);
          formik.setFieldValue("dataNascimento", response.dataNascimento);

          response.imagemId &&
            setSelectedPerfilPhoto(`${baseURL}arquivo/${response.imagemId}`);
          response.logoId &&
            setSelectedLogoPhoto(`${baseURL}arquivo/${response.logoId}`);
        })
        .catch((error) => {
          notifyError(error);
        });
    }
  }, []);

  return (
    <ContainerCustom>
      <Card style={{ boxShadow: "none" }}>
        <CardHeader title="Informações Básicas" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 5,
            marginRight: 5,
            marginBottom: 5,
          }}
        >
          <Box component="form" sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  name="primeiroNome"
                  label="Primeiro nome"
                  formik={formik}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Ultimo nome"
                  name="ultimoNome"
                  formik={formik}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputSelect
                  label="Sexo"
                  required
                  name="sexo"
                  options={genderOptions}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={6} color="primary">
                <DateInput
                  label="Data de nascimento"
                  name="dataNascimento"
                  required
                  formik={formik}
                ></DateInput>
              </Grid>
              <Grid item xs={4}>
                <TextInput label="CRN" name="crn" formik={formik} required />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      <Card sx={{ boxShadow: "none", mt: 2 }}>
        <CardHeader title="Foto de Perfil" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="caption" color="secondary" marginBottom={2}>
              {selectedPerfilPhoto
                ? "Imagem de perfil selecionada"
                : "Nenhuma imagem de perfil selecionada"}
            </Typography>
            <img
              src={
                selectedPerfilPhoto
                  ? typeof selectedPerfilPhoto === "string"
                    ? selectedPerfilPhoto
                    : URL.createObjectURL(selectedPerfilPhoto)
                  : "/placeholder-photo.png"
              }
              alt="Imagem de perfil"
              style={{
                width: "200px",
                height: "auto",
                marginBottom: 25,
              }}
            />
          </Box>
          <Input
            type="file"
            onChange={handlePerfilPhotoFileChange}
            style={{ display: "none", marginTop: 15 }}
            id="input-file-perfil"
          />
          <label htmlFor="input-file-perfil">
            <Button
              component="span"
              variant="contained"
              style={{ color: "white" }}
            >
              Escolher Imagem de Perfil
            </Button>
          </label>
        </Box>
      </Card>
      <Card sx={{ boxShadow: "none", mt: 2 }}>
        <CardHeader title="Foto da Logo" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="caption" color="secondary" marginTop={2}>
              {selectedLogoPhoto
                ? "Imagem de logo selecionada"
                : "Nenhuma imagem de logo selecionada"}
            </Typography>
            <img
              src={
                selectedLogoPhoto
                  ? typeof selectedLogoPhoto === "string"
                    ? selectedLogoPhoto
                    : URL.createObjectURL(selectedLogoPhoto)
                  : "/placeholder-photo.png"
              }
              alt="Imagem da logo"
              style={{
                width: "200px",
                height: "auto",
                marginBottom: 25,
              }}
            />
          </Box>
          <Input
            type="file"
            onChange={handleLogoFileChange}
            style={{ display: "none" }}
            id="input-file-logo"
          />
          <label htmlFor="input-file-logo">
            <Button
              component="span"
              variant="contained"
              style={{ color: "white" }}
            >
              Escolher Logo
            </Button>
          </label>
        </Box>
      </Card>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!formik.isValid}
          sx={{ mt: 3, mb: 2, mr: 2 }}
          style={{ boxShadow: "none" }}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          <Typography color="white">Salvar</Typography>
        </Button>
      </Box>
    </ContainerCustom>
  );
};

export default PerfilPage;
