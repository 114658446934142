import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { Box, Button, Grid, Typography } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextInput from "../../shared/components/TextInput";
import { createEntry } from "../../shared/utils/CrudService";
import { LOGIN } from "../../shared/components/AppRoutes";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";
import { Login } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface IForgotUserPasswordRequest {
  email: string;
}

const schema = Yup.object().shape({
  email: Yup.string().email().required("Campo obrigatório"),
});

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const onSubmit = (values: IForgotUserPasswordRequest) => {
    createEntry("user/forgot-password", values)
      .then(() => {
        notifySuccess(
          "Enviamos as instruções para recuperação da senha para o seu E-mail."
        );
        navigate(LOGIN);
      })
      .catch((error: any) => {
        notifyError(error.message);
      });
  };

  const formik = useFormik<IForgotUserPasswordRequest>({
    validationSchema: schema,
    initialValues: {
      email: "",
    },
    onSubmit: onSubmit,
  });

  return (
    // <ContainerCustom>
    //   <Box
    //     display="flex"
    //     flexDirection="column"
    //     justifyContent="center"
    //     height="100vh"
    //   >
    //     <Grid container>
    //       <Grid
    //         item
    //         xs={12}
    //         md={6}
    //         sx={{
    //           display: "flex",
    //           flexDirection: "column",
    //           justifyContent: "center",
    //           alignItems: "flex-start",
    //         }}
    //       >
    // <form onSubmit={formik.handleSubmit}>
    //   <Typography variant="h6">Recupere sua senha</Typography>

    //   <Typography color="text.secondary" marginTop={4}>
    //     Informe o e-mail associado à sua conta para recuperar sua senha.
    //   </Typography>
    //   <Box marginTop={1} width="100%">
    //     <TextInput
    //       label="E-mail"
    //       name="email"
    //       required
    //       formik={formik}
    //     />
    //   </Box>
    //   <Button
    //     color="primary"
    //     variant="contained"
    //     type="submit"
    //     disabled={!formik.isValid}
    //     sx={{ width: "120px", mt: 2 }}
    //   >
    //     Enviar
    //   </Button>
    // </form>
    //       </Grid>
    //       <Grid item xs={0} md={6}>
    //         <img
    //           src={process.env.PUBLIC_URL + "/login-logo.png"}
    //           alt="Login logo"
    //         />
    //       </Grid>
    //     </Grid>
    //   </Box>
    // </ContainerCustom>
    <Box sx={{ height: "100vh", backgroundColor: "#fffbfb" }}>
      <Grid container component="main" sx={{ height: "100%" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              pr: 10,
              pl: 10,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                my: "7vh",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/diety-logo.png"}
                alt="login-logo"
                style={{ width: "10rem" }}
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                mb: 3,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <Box
                    sx={{
                      mt: 2,
                      mb: 3,
                    }}
                  >
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      Recupere sua senha.
                    </Typography>
                  </Box>
                  <Typography style={{ color: "#0D0D0D" }}>
                    Informe o e-mail associado à sua conta.
                  </Typography>
                  <Box width="100%">
                    <TextInput
                      label="E-mail"
                      name="email"
                      required
                      formik={formik}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      style={{ color: "white" }}
                      disabled={!formik.isValid}
                      sx={{ mt: 2 }}
                      startIcon={<Login sx={{ color: "white" }} />}
                    >
                      Enviar
                    </Button>
                  </Box>
                  <Grid container justifyContent="center" mt={4}>
                    <Grid item>
                      <Link
                        component={RouterLink}
                        to={LOGIN}
                        variant="body2"
                        // color="secondary"
                        style={{ textDecoration: "none", fontSize: "1rem" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span style={{ color: "#0D0D0D", marginRight: 2 }}>
                            <ArrowBackIcon></ArrowBackIcon>
                          </span>
                          <span style={{ color: "#0D0D0D" }}>
                            Voltar para a tela de login.
                          </span>
                        </div>
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            display: { xs: "none", lg: "flex" },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/login-image.png"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Grid>
      </Grid>
    </Box>
  );
}
