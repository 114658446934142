import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

interface AdBlockProps {
  open: boolean;
}

const AdBlockDialog: React.FC<AdBlockProps> = ({ open }) => {
  return (
    <Dialog open={open}>
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        Ad Block Detectado
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Notamos que você está usando um bloqueador de anúncios. Para que
          possamos continuar oferecendo este serviço de forma gratuita, pedimos
          gentilmente que você desative o ad block. Os anúncios nos ajudam a
          manter o site funcionando e a oferecer conteúdo de qualidade para
          você. Agradecemos pela compreensão e apoio!
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AdBlockDialog;
