import { ODataResult } from "../../shared/models/ODataResult";
import api from "../../shared/utils/Api";
import {
  ICreateMedidaCaseiraLoteRequest,
  ICreateMedidaCaseiraRequest,
} from "../models/ICreateMedidaCaseiraRequest";
import { IGetAllMedidaCaseiraResponse } from "../models/IGetAllMedidaCaseiraResponse";

export const getAllMedidaCaseira = async (filter: {
  alimento?: string;
  page: number;
  numPerPage: number;
}) => {
  try {
    const response = await api.get<ODataResult<IGetAllMedidaCaseiraResponse[]>>(
      "medidaCaseira",
      {
        params: {
          alimentoId: filter.alimento ?? null,
          take: filter.numPerPage,
          skip: filter.page * filter.numPerPage,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Falha ao buscar as medidas caseiras");
  }
};

export const getMedidaCaseiraById = async (id: string) => {
  try {
    const response = await api.get<IGetAllMedidaCaseiraResponse>(
      `medidaCaseira/${id}/odata`,
      {
        params: {
          $select: "id, nome, equivalenteGramas, tamanhoMedida",
          $expand: "alimento($select=id,nome)",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Falha ao buscar aa medida caseira");
  }
};

export const createMedidaCaseira = async (
  request: ICreateMedidaCaseiraRequest
) => {
  try {
    const response = await api.post("medidaCaseira", request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao criar as medidas caseiras");
  }
};

export const createMedidaCaseiraLote = async (
  request: ICreateMedidaCaseiraLoteRequest
) => {
  try {
    const response = await api.post("medidaCaseira/lote", request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao criar as medidas caseiras");
  }
};

export const editMedidaCaseira = async (
  id: string,
  request: ICreateMedidaCaseiraRequest
) => {
  try {
    const response = await api.put("medidaCaseira", { id, ...request });
    return response.data;
  } catch (error) {
    throw new Error("Falha ao editar as medidas caseiras");
  }
};

export const deleteAllMedidaCaseira = async (ids: string[]): Promise<void> => {
  try {
    await api.delete(`medidaCaseira`, {
      params: {
        ids,
      },
    });
    return;
  } catch (error) {
    throw new Error("Falha ao deletar as medidas caseiras");
  }
};
