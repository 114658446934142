import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { BarChart } from "@mui/x-charts";
import {
  deleteExameQuimico,
  getAllExameQuimico,
  getAllExameQuimicoReferencia,
  updateExameQuimico,
} from "../services/EvalutionService";
import { ExameQuimicoReferencia } from "../models/IExameQuimicoReferencia";
import ContainerCustom from "../../shared/components/ContainerCustom";
import SolicitarExameQuimicoDialog from "../dialogs/SolicitarExameQuimicoDialog";
import { useParams } from "react-router-dom";
import {
  ExameQuimicoSolicitadoODataResponse,
  ExameQuimicoSolicitadoRequest,
  UpdateExameQuimicoRequest,
} from "../models/IUpdateExameQuimicoRequest";
import { useHookstate } from "@hookstate/core";
import { globalState } from "../../shared/states/GlobalState";
import useNovaAvaliacao from "../hooks/NovaAvaliacaoHook";
import PatientSelectedCard from "../../shared/components/PatientSelectedCard";
import NovaAvaliacaoButton from "../components/NovaAvaliacaoButton";
import SelecionarRecomendacaoDialog from "../dialogs/SelecionarRecomendacaoDialog";
import SelecionarPerguntaDialog from "../dialogs/SelecionarPerguntaDialog";
import ExameQuimicoResultadoDialog from "../dialogs/ExameQuimicoResultadoDialog";
import DeleteContentDialog from "../../shared/dialogs/DeleteContentDialog";
import useDownloadFile from "../hooks/DownloadFileHook";
import { ExportExameLaboratorialPdf } from "../exports/ExportExameLaboratorialPdf";
import { useAuth } from "../../shared/hooks/AuthHook";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";

const EvaluationChemicalTestPage: React.FC = () => {
  const { id, consulta_id, avaliacaoId } = useParams();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const state = useHookstate(globalState);
  const { userLogged } = useAuth();
  const { downloadFile } = useDownloadFile();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [exams, setExams] = React.useState<
    ExameQuimicoSolicitadoODataResponse[]
  >([]);

  const [dataset, setDataset] = React.useState<any[]>([
    {
      min: 0,
      result: 0,
      max: 0,
      type: "",
    },
  ]);

  const [isSolicitarExameDialogOpened, setIsSolicitarExameDialogOpened] =
    React.useState(false);
  const [examesReferencia, setExamesReferencia] = React.useState<
    ExameQuimicoReferencia[]
  >([]);
  const [isResultadoDialogOpened, setIsResultadoExameDialogOpened] =
    React.useState(false);
  const [isDeleteDialogOpened, setIsDeleteExameDialogOpened] =
    React.useState(false);

  const [exameSelected, setExameSelected] =
    React.useState<ExameQuimicoSolicitadoODataResponse | null>(null);

  const {
    onNovaAvaliacao,
    isSelecionarAnamneseDialogOpen,
    setIsSelecionarAnamneseDialogOpen,
    isSelecionarRecomendacaoDialogOpen,
    setIsSelecionarRecomendacaoDialogOpen,
    onSelectAnamnese,
    onSelectRecomendacao,
  } = useNovaAvaliacao({ id: id!, consulta_id: consulta_id! });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrint = () => {
    downloadFile(
      <ExportExameLaboratorialPdf
        sexo={state.value?.sexo}
        nome={state.value?.nome}
        idade={state.value?.idade.toString()}
        profissional={`${userLogged()?.primeiroNome} ${
          userLogged()?.ultimoNome
        }`}
        exames={exams.map((exam) => exam.exameQuimicoReferencia?.nome ?? "")}
      />,
      "exame-laboratorial.pdf"
    );
  };

  const handleExameSelected = (exame: ExameQuimicoSolicitadoODataResponse) => {
    setExameSelected(exame);

    setDataset([
      {
        min: exame
          ? state.get().sexo === "M"
            ? exame.exameQuimicoReferencia?.valorRefMasInf
            : exame.exameQuimicoReferencia?.valorRefFemInf
          : 0,
        result: exame.valor ?? 0,
        max: exame
          ? state.get().sexo === "M"
            ? exame.exameQuimicoReferencia?.valorRefMasSup
            : exame.exameQuimicoReferencia?.valorRefFemSup
          : 0,
        type: "",
      },
    ]);
  };

  const handleSolicitarExames = (exames: ExameQuimicoReferencia[]) => {
    const fullExames = [
      ...exams,
      ...exames
        .filter(
          (exame) =>
            exams.findIndex((x) => x.exameQuimicoReferenciaId === exame.id) ===
            -1
        )
        .map(
          (exame) =>
            ({
              exameQuimicoId: exame.id,
              exameQuimicoReferenciaId: exame.id,
            } as ExameQuimicoSolicitadoODataResponse)
        ),
    ];

    const request = {
      id: avaliacaoId!,
      solicitados: fullExames.map(
        (exame) =>
          ({
            id: exame.id,
            exameQuimicoId: id!,
            exameQuimicoReferenciaId: exame.exameQuimicoReferenciaId,
            valor: exame.valor,
          } as ExameQuimicoSolicitadoRequest)
      ),
    };

    updateExameQuimico(avaliacaoId!, request)
      .then(() => {
        notifySuccess("Exames solicitados com sucesso");
        getAllExameQuimico(avaliacaoId!)
          .then((response) => {
            setExams(response);
          })
          .catch((error) => {
            notifyError(error);
          });
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  const handleSaveValue = (value: number) => {
    if (!exameSelected) return;

    const updatedExams = exams.map((exam) => {
      if (exam.id === exameSelected.id) {
        return {
          ...exam,
          valor: value,
        };
      }

      return exam;
    });

    setExams(updatedExams);

    setDataset([
      {
        min: exameSelected
          ? state.get().sexo === "M"
            ? exameSelected.exameQuimicoReferencia?.valorRefMasInf
            : exameSelected.exameQuimicoReferencia?.valorRefFemInf
          : 0,
        result: value,
        max: exameSelected
          ? state.get().sexo === "M"
            ? exameSelected.exameQuimicoReferencia?.valorRefMasSup
            : exameSelected.exameQuimicoReferencia?.valorRefFemSup
          : 0,
        type: "",
      },
    ]);
  };

  const isOutOfRange = (value: number, exam: any): boolean => {
    if (!exam) return false;

    const min =
      state.get().sexo === "M"
        ? exam.exameQuimicoReferencia?.valorRefMasInf
        : exam.exameQuimicoReferencia?.valorRefFemInf;

    const max =
      state.get().sexo === "M"
        ? exam.exameQuimicoReferencia?.valorRefMasSup
        : exam.exameQuimicoReferencia?.valorRefFemSup;

    return value < min || value > max;
  };

  const handleRemoveExame = (id: string) => {
    setExameSelected(null);

    deleteExameQuimico(id)
      .then(() => {
        notifySuccess("Exame removido com sucesso");
        getAllExameQuimico(avaliacaoId!)
          .then((response) => {
            setExams(response);
          })
          .catch((error) => {
            notifyError(error);
          });
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  const handleSave = () => {
    const request = {
      id: avaliacaoId!,
      solicitados: exams.map((exam) => ({
        id: exam.id,
        exameQuimicoId: exam.id,
        exameQuimicoReferenciaId: exam.exameQuimicoReferencia?.id,
        valor: exam.valor,
      })),
    } as UpdateExameQuimicoRequest;

    updateExameQuimico(avaliacaoId!, request)
      .then(() => {
        notifySuccess("Exames salvos com sucesso");
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  useEffect(() => {
    getAllExameQuimicoReferencia()
      .then((response) => {
        setExamesReferencia(response);
      })
      .catch((error) => {
        notifyError(error);
      });

    getAllExameQuimico(avaliacaoId!)
      .then((response) => {
        setExams(response);
      })
      .catch((error) => {
        notifyError(error);
      });
  }, []);

  return (
    <ContainerCustom>
      <PatientSelectedCard patientId={id} showPaginaInicialButton={false}>
        <NovaAvaliacaoButton onClick={(av) => onNovaAvaliacao(av)} />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSave}
          sx={{ ml: 1 }}
        >
          Salvar
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setIsSolicitarExameDialogOpened(true)}
          sx={{ ml: 1 }}
        >
          Solicitar exames
        </Button>
        {/* <PDFDownloadLink
          document={<ExportExameLaboratorialPdf />}
          fileName="exame-laboratorial.pdf"
        > */}
        <Button
          variant="text"
          onClick={handlePrint}
          sx={{ ml: 1 }}
          disabled={exams.length === 0}
        >
          Imprimir
        </Button>
        {/* </PDFDownloadLink> */}
      </PatientSelectedCard>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
            <CardHeader title="Exames Laboratoriais" />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Exame</TableCell>
                      <TableCell>Resultado</TableCell>
                      <TableCell>Referência</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {exams.length > 0 ? (
                      exams.map((exam, index) => (
                        <TableRow
                          selected={exameSelected?.id === exam.id}
                          key={index}
                          onClick={() => handleExameSelected(exam)}
                        >
                          <TableCell>{index}</TableCell>
                          <TableCell>
                            {exam.exameQuimicoReferencia?.nome}
                          </TableCell>
                          <TableCell>
                            {exam.valor ? (
                              <Chip
                                label={`${exam.valor} (${exam.exameQuimicoReferencia?.dimensao})`}
                                color={
                                  isOutOfRange(exam.valor, exam)
                                    ? "error"
                                    : "success"
                                }
                              />
                            ) : (
                              `-`
                            )}
                          </TableCell>
                          <TableCell>
                            {state.get().sexo === "M" &&
                              `${exam.exameQuimicoReferencia?.valorRefMasInf} - ${exam.exameQuimicoReferencia?.valorRefMasSup}`}
                            {state.get().sexo === "F" &&
                              `${exam.exameQuimicoReferencia?.valorRefFemInf} - ${exam.exameQuimicoReferencia?.valorRefFemSup}`}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setExameSelected(exam);
                                setIsResultadoExameDialogOpened(true);
                              }}
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setExameSelected(exam);
                                setIsDeleteExameDialogOpened(true);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Typography textAlign={"center"}>
                            Nenhum exame solicitado
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
            <CardHeader title="Valores de Referência" />
            <CardContent
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BarChart
                dataset={dataset}
                xAxis={[{ scaleType: "band", dataKey: "type" }]}
                series={[
                  { dataKey: "min", label: "Mínimo" },
                  { dataKey: "result", label: "Resultado" },
                  { dataKey: "max", label: "Máximo" },
                ]}
                width={300}
                height={250}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <SolicitarExameQuimicoDialog
        open={isSolicitarExameDialogOpened}
        onClose={() => setIsSolicitarExameDialogOpened(false)}
        examesReferencia={examesReferencia}
        onSelect={handleSolicitarExames}
      />

      <SelecionarRecomendacaoDialog
        open={isSelecionarRecomendacaoDialogOpen}
        onClose={() => setIsSelecionarRecomendacaoDialogOpen(false)}
        onSelect={onSelectRecomendacao}
      />

      <SelecionarPerguntaDialog
        open={isSelecionarAnamneseDialogOpen}
        onClose={() => setIsSelecionarAnamneseDialogOpen(false)}
        onSelect={onSelectAnamnese}
      />

      <ExameQuimicoResultadoDialog
        open={isResultadoDialogOpened}
        onClose={() => setIsResultadoExameDialogOpened(false)}
        exame={exameSelected?.exameQuimicoReferencia?.nome}
        value={exameSelected?.valor}
        dimension={exameSelected?.exameQuimicoReferencia?.dimensao}
        onSave={handleSaveValue}
      />

      <DeleteContentDialog
        open={isDeleteDialogOpened}
        message="Essa operação não pode ser desfeita"
        onClose={() => setIsDeleteExameDialogOpened(false)}
        onAccept={() => {
          if (exameSelected) {
            handleRemoveExame(exameSelected.id!);
            setExameSelected(null);
          }
        }}
      />
    </ContainerCustom>
  );
};

export default EvaluationChemicalTestPage;
