import { createContext } from "react";

export interface IUserLogged {
  token: string;
  email: string;
  primeiroNome: string;
  ultimoNome: string;
  expiresIn: Date;
  isRemembered: boolean;
  roles: string[];
}

export type AuthContextType = {
  isAuthenticated: () => boolean;
  userLogged: () => IUserLogged | null;
  singin: (userLogged: IUserLogged) => void;
  singout: () => void;
  isRemembered: () => boolean;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const AuthProvider = ({ children }: any) => {
  const isAuthenticated = (): boolean => {
    const email = localStorage.getItem("diety.email");
    const expiresIn = localStorage.getItem("diety.expiresIn");

    if (!email || !expiresIn) {
      return false;
    }

    const expiresInDate = new Date(expiresIn);

    if (expiresInDate <= new Date()) {
      return false;
    }

    return true;
  };

  const isLembrarDeMim = (): boolean => {
    const lembrarDeMim = localStorage.getItem("diety.lembrarDeMim");

    if (!lembrarDeMim) {
      return false;
    }

    return Boolean(lembrarDeMim);
  };

  const userLogged = (): IUserLogged | null => {
    if (!isAuthenticated()) {
      return null;
    }

    return {
      email: localStorage.getItem("diety.email")!,
      primeiroNome: localStorage.getItem("diety.primeiroNome")!,
      ultimoNome: localStorage.getItem("diety.ultimoNome")!,
      expiresIn: new Date(localStorage.getItem("diety.expiresIn")!),
      token: localStorage.getItem("diety.token")!,
      isRemembered: isLembrarDeMim(),
      roles: localStorage.getItem("diety.roles")?.split(",") ?? [],
    };
  };

  const singin = (userLogged: IUserLogged) => {
    localStorage.setItem("diety.token", userLogged.token);
    localStorage.setItem("diety.email", userLogged.email);
    localStorage.setItem("diety.primeiroNome", userLogged.primeiroNome);
    localStorage.setItem("diety.ultimoNome", userLogged.ultimoNome);
    localStorage.setItem("diety.expiresIn", userLogged.expiresIn.toISOString());
    localStorage.setItem("diety.lembrarDeMim", userLogged.isRemembered.toString());
    localStorage.setItem("diety.roles", userLogged.roles.join(","));
  };

  const singout = () => {
    localStorage.removeItem("diety.token");
    localStorage.removeItem("diety.email");
    localStorage.removeItem("diety.primeiroNome");
    localStorage.removeItem("diety.ultimoNome");
    localStorage.removeItem("diety.expiresIn");
    localStorage.removeItem("diety.lembrarDeMim");
    localStorage.removeItem("diety.roles");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        isRemembered: isLembrarDeMim,
        userLogged: userLogged,
        singin: singin,
        singout: singout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
