import { useNavigate } from "react-router-dom";
import {
  AVALIACAO_ORIENTACAO,
  AVALIACAO_ANAMNESE,
  AVALIACAO_ANTROPOMETRIA,
  AVALIACAO_EXAME_QUIMICO,
  AVALIACAO_EXAME_FISICO,
  AVALIACAO_DIETA,
} from "../../shared/components/AppRoutes";
import {
  createRecomendacao,
  createAnamnese,
  createAntropometria,
  createExameQuimico,
  createExameFisico,
  createDieta,
} from "../services/EvalutionService";
import React from "react";
import { AvaliacaoType } from "../components/NovaAvaliacaoButton";

interface NovaAvaliacaoProps {
  id: string;
  consulta_id: string;
}

export interface NovaAvaliacaoData {
  onNovaAvaliacao: (action: AvaliacaoType) => void;
  onSelectRecomendacao: (recomendacaoId: string) => void;
  onSelectAnamnese: (anamneseId: string) => void;
  isSelecionarRecomendacaoDialogOpen: boolean;
  isSelecionarAnamneseDialogOpen: boolean;
  setIsSelecionarAnamneseDialogOpen: (value: boolean) => void;
  setIsSelecionarRecomendacaoDialogOpen: (value: boolean) => void;
  convertStringToAvaliacaoType: (value: string) => AvaliacaoType;
}

const useNovaAvaliacao = ({
  id,
  consulta_id,
}: NovaAvaliacaoProps): NovaAvaliacaoData => {
  const navigate = useNavigate();
  const [
    isSelecionarRecomendacaoDialogOpen,
    setIsSelecionarRecomendacaoDialogOpen,
  ] = React.useState<boolean>(false);
  const [isSelecionarAnamneseDialogOpen, setIsSelecionarAnamneseDialogOpen] =
    React.useState<boolean>(false);

  const changeSelecionarAnamneseDialogOpen = (value: boolean) => {
    setIsSelecionarAnamneseDialogOpen(value);
  };

  const changeSelecionarRecomendacaoDialogOpen = (value: boolean) => {
    setIsSelecionarRecomendacaoDialogOpen(value);
  };

  const novaAvaliacaoHandle = (action: AvaliacaoType) => {
    console.log(action);
    if (action === AvaliacaoType.ANAMNESE) {
      setIsSelecionarAnamneseDialogOpen(true);
    } else if (action === AvaliacaoType.EXAME_FISICO) {
      handleCriarExameFisico();
    } else if (action === AvaliacaoType.EXAME_LABORATORIAL) {
      handleCriarExameQuimico();
    } else if (action === AvaliacaoType.COMPOSICAO_CORPORAL) {
      handleCriarAntropometria();
    } else if (action === AvaliacaoType.PLANO_ALIMENTAR_LIVRE) {
      handleCriarDieta("LIVRE");
    } else if (action === AvaliacaoType.PLANO_ALIMENTAR_EQUIVALENTES) {
      handleCriarDieta("EQUIVALENTE");
    } else if (action === AvaliacaoType.RECOMENDACOES) {
      setIsSelecionarRecomendacaoDialogOpen(true);
    }
  };

  const handleConvertStringToAvaliacaoType = (value: string): AvaliacaoType => {
    switch (value) {
      case "Anamnese":
        return AvaliacaoType.ANAMNESE;
      case "Exames Físicos":
        return AvaliacaoType.EXAME_FISICO;
      case "Exames Laboratoriais":
        return AvaliacaoType.EXAME_LABORATORIAL;
      case "Composição Corporal":
        return AvaliacaoType.COMPOSICAO_CORPORAL;
      case "Plano Alimentar - Livre":
        return AvaliacaoType.PLANO_ALIMENTAR_LIVRE;
      case "Plano Alimentar - Equivalentes":
        return AvaliacaoType.PLANO_ALIMENTAR_EQUIVALENTES;
      case "Recomendações":
        return AvaliacaoType.RECOMENDACOES;
      default:
        return AvaliacaoType.ANAMNESE;
    }
  }

  const handleSelectRecomendacao = (recomendacaoId: string) => {
    createRecomendacao({
      consultaId: consulta_id,
      agregadorRecomendacaoId: recomendacaoId,
    })
      .then((recomendacaoId) => {
        navigate(AVALIACAO_ORIENTACAO(id, consulta_id, recomendacaoId));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSelectAnamnese = (anamneseId: string) => {
    createAnamnese({
      consultaId: consulta_id!,
      agregadorPerguntaId: anamneseId,
    })
      .then((avaliacaoId) => {
        navigate(AVALIACAO_ANAMNESE(id, consulta_id, avaliacaoId));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCriarAntropometria = () => {
    createAntropometria(consulta_id!)
      .then((antropometriaId) => {
        navigate(AVALIACAO_ANTROPOMETRIA(id, consulta_id, antropometriaId));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCriarExameQuimico = () => {
    createExameQuimico({ consultaId: consulta_id! })
      .then((exameId) => {
        navigate(AVALIACAO_EXAME_QUIMICO(id, consulta_id, exameId));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCriarExameFisico = () => {
    createExameFisico({ consultaId: consulta_id })
      .then((exameId) => {
        navigate(AVALIACAO_EXAME_FISICO(id, consulta_id, exameId));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCriarDieta = (tipo: string) => {
    createDieta(consulta_id, tipo)
      .then((dietaId) => {
        navigate(AVALIACAO_DIETA(id, consulta_id, dietaId));
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return {
    onNovaAvaliacao: novaAvaliacaoHandle,
    isSelecionarRecomendacaoDialogOpen,
    isSelecionarAnamneseDialogOpen,
    setIsSelecionarAnamneseDialogOpen: changeSelecionarAnamneseDialogOpen,
    setIsSelecionarRecomendacaoDialogOpen:
      changeSelecionarRecomendacaoDialogOpen,
    onSelectAnamnese: handleSelectAnamnese,
    onSelectRecomendacao: handleSelectRecomendacao,
    convertStringToAvaliacaoType: handleConvertStringToAvaliacaoType,
  };
};

export default useNovaAvaliacao;
