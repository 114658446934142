import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

export interface DownloadFileData {
  downloadFile: (file: any, filename: string) => Promise<void>;
}

const useDownloadFile = (): DownloadFileData => {
  const handleDownloadFile = async (file: any, filename: string) => {
    const currentPdf = pdf(file);
    const blob = await currentPdf.toBlob();
    saveAs(blob, filename);
  };

  return {
    downloadFile: handleDownloadFile,
  };
};

export default useDownloadFile;
