import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { errorText } from "../utils/InputUtil";

export interface InputSelectItem {
  label: string;
  value: string;
}

interface InputSelectProps {
  options: InputSelectItem[];
  label?: string;
  name?: string;
  required?: boolean;
  formik?: FormikProps<any>;
  value?: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  width?: number;
}

const InputSelect: React.FC<InputSelectProps> = ({
  label,
  options,
  name,
  required,
  formik,
  value,
  onChange,
  disabled,
  fullWidth = true,
  width,
}) => {
  return (
    <FormControl
      sx={width ? { width: width } : undefined}
      fullWidth={fullWidth}
      required={required}
      error={
        formik ? formik.touched[name!] && Boolean(formik.errors[name!]) : false
      }
    >
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        label={label}
        value={value ? value : formik ? formik.values[name!] : undefined}
        onChange={
          onChange ? onChange : formik ? formik.handleChange : undefined
        }
        onBlur={formik ? formik.handleBlur : undefined}
        disabled={disabled ?? false}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {formik && formik.touched[name!] && Boolean(formik.errors[name!]) && (
        <FormHelperText>{errorText(formik!, name!)}</FormHelperText>
      )}
    </FormControl>
  );
};

export default InputSelect;
