import api from "../../shared/utils/Api";
import { ICreateConsultaRequest } from "../models/ICreateConsultaRequest";
import { IGetConsultaPacienteDashboardResponse } from "../models/IGetConsultaPacienteDashboardResponse";

export const getConsultaDashboard = async (
  id: string,
  ano?: number,
): Promise<IGetConsultaPacienteDashboardResponse> => {
  const response = await api.get(`consulta/dashboard/${id}`, {
    params: { ano },
  });
  return response.data;
};

export const createConsulta = async (
  body: ICreateConsultaRequest
): Promise<void> => {
  const response = await api.post(`consulta`, body);
  return response.data;
};

export const createConsultaAvulsa = async (
  pacienteId: string,
): Promise<void> => {
  const response = await api.post(`consulta/avulsa`, { pacienteId });
  return response.data;
};

export const iniciarConsulta = async (
  id: string
): Promise<void> => {
  const response = await api.put(`consulta/${id}/iniciar`);
  return response.data;
};

export const finalizarConsulta = async (
  id: string
): Promise<void> => {
  const response = await api.put(`consulta/${id}/finalizar`);
  return response.data;
};

export const deleteConsulta = async (consultaId: string): Promise<void> => {
  await api.delete(`consulta/${consultaId}`);
};
