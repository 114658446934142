import { Download, Delete } from "@mui/icons-material";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import { RoleGuard } from "../../shared/components/RoleGuard";
import { baseURL } from "../../shared/utils/Api";

interface DownloadListItemProps {
  title: string;
  description: string;
  id: string;
  arquivoId: string;
  onRemove: (id: string) => void;
}

export function DownloadListItem({
  title,
  description,
  id,
  arquivoId,
  onRemove,
}: DownloadListItemProps): JSX.Element {
  return (
    <ListItem>
      <ListItemText primary={title} secondary={description} />
      <ListItemSecondaryAction>
        <IconButton
          href={`${baseURL}arquivo/${arquivoId}`}
          download
          target="_blank"
        >
          <Download color="action" />
        </IconButton>
        <RoleGuard permit={["Admin"]}>
          <IconButton onClick={() => onRemove(id)}>
            <Delete color="action" />
          </IconButton>
        </RoleGuard>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
