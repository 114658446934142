import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { isAllInputNotTouched } from "../../shared/utils/InputUtil";
import TextInput from "../../shared/components/TextInput";
import NumberInput from "../../shared/components/NumberInput";
import { Recomendacao } from "../models/AgrupadorRecomendacao";
import { v4 as uuidv4 } from "uuid";

interface CreateRecomendacaoDialogProps {
  id?: string;
  open: boolean;
  onAdd: (recomendacao: Recomendacao) => void;
  onClose: () => void;
}

interface IFormValues {
  id?: string;
  nome: string;
}

const schema = yup.object().shape({
  nome: yup.string().required("Campo obrigatório"),
});

const initialValues: IFormValues = {
  nome: "",
};

const CreateRecomendacaoDialog: React.FC<CreateRecomendacaoDialogProps> = ({
  id,
  open,
  onAdd,
  onClose,
}) => {
  const onSubmit = (values: IFormValues) => {
    onAdd({
      id: id ?? uuidv4(),
      nome: values.nome,
      ordem: 0,
    });
    onClose();
    formik.resetForm();
  };

  const formik = useFormik<IFormValues>({
    initialValues,
    validationSchema: schema,
    onSubmit,
  });

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => onClose()}>
      <DialogTitle>Criar recomendação</DialogTitle>
      <DialogContent>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12}>
            <TextInput label="Nome" name="nome" required formik={formik} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isAllInputNotTouched(formik) || !formik.isValid}
          onClick={() => formik.submitForm()}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRecomendacaoDialog;
