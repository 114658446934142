import { Add, Edit } from "@mui/icons-material";
import {
  Checkbox,
  Container,
  Grid,
  Paper,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IGetAllMedidaCaseiraResponse } from "../models/IGetAllMedidaCaseiraResponse";
import {
  deleteAllMedidaCaseira,
  getAllMedidaCaseira,
} from "../services/MedidaCaseiraService";
import {
  CADASTRAR_MEDIDA_CASEIRA_LOTE,
  EDITAR_MEDIDA_CASEIRA,
} from "../../shared/components/AppRoutes";
import ListAppBar from "../components/ListAppBar";
import FilterDialogCustom from "../../shared/dialogs/FilterDialogCustom";
import AutocompleteCustomInput from "../../shared/components/AutocompleteCustomInput";
import { getAllAutocomplete } from "../../shared/services/AutocompleteService";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";
import TableCustom from "../../shared/components/TableCustom";
import { usePage } from "../../shared/hooks/PageHook";

export interface IMedidaCaseiraFilter {
  alimento: any;
}

const MedidaCaseiraListPage: React.FC = () => {
  const [medidas, setMedidas] = React.useState<IGetAllMedidaCaseiraResponse[]>(
    []
  );
  const [isSelectingMode, setIsSelectingMode] = React.useState<boolean>(false);
  const [selecteds, setSelecteds] = React.useState<string[]>([]);
  const { page, count, rowsPerPage, setRowsPerPage, setCount, setPage } =
    usePage();
  const [isFilterDialogOpened, setIsFilterDialogOpened] =
    React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<IMedidaCaseiraFilter | undefined>(
    undefined
  );
  const [filterForm, setFilterForm] = React.useState<{
    alimento?: any;
  }>({
    alimento: undefined,
  });

  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const handleSelectOrDesselectAll = (checked: boolean) => {
    if (checked) {
      setSelecteds(medidas.map((medida) => medida.id!));
      !isSelectingMode && setIsSelectingMode(true);
    } else {
      setSelecteds([]);
      isSelectingMode && setIsSelectingMode(false);
    }
  };

  const handleDelete = () => {
    if (selecteds.length === 0) return;

    deleteAllMedidaCaseira(selecteds)
      .then(() => {
        notifySuccess("Medidas caseiras excluídas com sucesso!");
        setPage(0);
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  const handleSelect = (id: string) => {
    let newSelecteds: string[] = [];

    if (selecteds.includes(id)) {
      newSelecteds = selecteds.filter((item) => item !== id);
      setSelecteds(newSelecteds);
    } else {
      newSelecteds = [...selecteds, id];
      setSelecteds(newSelecteds);
    }

    if (newSelecteds.length === 0) {
      isSelectingMode && setIsSelectingMode(false);
    } else {
      !isSelectingMode && setIsSelectingMode(true);
    }
  };

  const handleFilter = (filter: any) => {
    setFilter(filter);
    setPage(0);
  };

  const loadPage = async () => {
    try {
      const response = await getAllMedidaCaseira({
        page,
        numPerPage: rowsPerPage,
        alimento: filter?.alimento.id,
      });

      if (response.count !== count) {
        setCount(response.count);
      }

      setMedidas(response.data);
    } catch (error: any) {
      notifyError(error);
    }
  };

  useEffect(() => {
    loadPage();
  }, [page, rowsPerPage, filter]);

  return (
    <Container>
      <Paper>
        <ListAppBar
          title="Minhas medidas caseiras"
          numSelecteds={selecteds.length}
          isEditEnabled={isSelectingMode}
          removeTitle="Deseja realmente excluir as medidas selecionados?"
          removeMessage="Essa operação não poderá ser desfeita."
          onDelete={handleDelete}
          onFilter={() => setIsFilterDialogOpened(true)}
          menuActions={
            selecteds.length === 1
              ? [
                  // {
                  //   icon: <Add />,
                  //   title: "Adicionar",
                  //   onClick: () => navigate(CADASTRAR_MEDIDA_CASEIRA),
                  // },
                  {
                    icon: <Add />,
                    title: "Adicionar em lote",
                    onClick: () => navigate(CADASTRAR_MEDIDA_CASEIRA_LOTE),
                  },
                  {
                    icon: <Edit />,
                    title: "Editar",
                    onClick: () =>
                      navigate(EDITAR_MEDIDA_CASEIRA(selecteds[0]!)),
                  },
                ]
              : [
                  // {
                  //   icon: <Add />,
                  //   title: "Adicionar",
                  //   onClick: () => navigate(CADASTRAR_MEDIDA_CASEIRA),
                  // },
                  {
                    icon: <Add />,
                    title: "Adicionar em lote",
                    onClick: () => navigate(CADASTRAR_MEDIDA_CASEIRA_LOTE),
                  },
                ]
          }
        />
        <TableCustom
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(page) => setPage(page)}
          onRowsPerPageChange={(page) => {
            setRowsPerPage(page);
          }}
          rowCount={medidas.length}
          selectedCouunt={selecteds.length}
          emptyText={"Nenhuma medida caseira encontrada."}
          headers={[
            { id: "alimento", label: "Alimento" },
            { id: "nome", label: "Nome" },
            { id: "tamanhoMedida", label: "Quantidade" },
            { id: "equivalenteGramas", label: "Equivalente (g)" },
          ]}
          onDesselectAll={() => handleSelectOrDesselectAll(false)}
          onSelectAll={() => handleSelectOrDesselectAll(true)}
        >
          {medidas.map((medida) => {
            const isItemSelected = selecteds.includes(medida.id!);

            return (
              <TableRow
                key={medida.id}
                hover
                onClick={(event) => handleSelect(medida.id!)}
                selected={isItemSelected}
                role="checkbox"
                sx={{ cursor: "pointer" }}
              >
                <TableCell>
                  <Checkbox color="primary" checked={isItemSelected} />
                </TableCell>
                <TableCell>{medida.alimento.nome}</TableCell>
                <TableCell>{medida.nome}</TableCell>
                <TableCell>{medida.tamanhoMedida}</TableCell>
                <TableCell>{medida.equivalenteGramas}</TableCell>
              </TableRow>
            );
          })}
        </TableCustom>
      </Paper>

      <FilterDialogCustom
        open={isFilterDialogOpened}
        onClose={() => setIsFilterDialogOpened(false)}
        onFilter={() => {
          handleFilter({
            alimento: filterForm.alimento,
          });
          setIsFilterDialogOpened(false);
        }}
        onClear={() => {
          setFilterForm({ alimento: undefined });
          setFilter(undefined);
        }}
      >
        <Grid item xs={12}>
          <AutocompleteCustomInput
            label="Alimento"
            name="alimento"
            value={filterForm.alimento}
            onChange={(oldValue, newValue) =>
              setFilterForm({ ...filterForm, alimento: newValue })
            }
            resource={() => getAllAutocomplete("alimento")}
          />
        </Grid>
      </FilterDialogCustom>
    </Container>
  );
};

export default MedidaCaseiraListPage;
