import api from "../../shared/utils/Api";
import { GetAdminHomeResponse } from "../models/GetAdminHomeResponse";
import { GetHomeResponse } from "../models/GetHomeResponse";

export async function getHome(): Promise<GetHomeResponse> {
  try {
    const response = await api.get<GetHomeResponse>("home");
    return response.data;
  } catch (error) {
    throw new Error("Falha ao consultar a home");
  }
}

export async function getAdminHome(): Promise<GetAdminHomeResponse> {
  try {
    const response = await api.get<GetAdminHomeResponse>("home/admin");
    return response.data;
  } catch (error) {
    throw new Error("Falha ao consultar a home");
  }
}
