import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { errorText } from "../utils/InputUtil";
import { FormikProps } from "formik";
import { IAutocompleteResponse } from "../models/AutocompleteResponse";
import { getAllAutocomplete } from "../services/AutocompleteService";

interface AutocompleteInputProps {
  name: string;
  label: string;
  formik: FormikProps<any>;
  url: string;
  placeholder?: string;
  isOData?: boolean;
  required?: boolean;
  disabled?: boolean;
  onError?: (error: string) => void;
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  name,
  label,
  formik,
  url,
  required,
  disabled,
  isOData = false,
  onError,
  placeholder,
}) => {
  const [options, setOptions] = React.useState<IAutocompleteResponse[]>([]);

  useEffect(() => {
    getAllAutocomplete(url, isOData)
      .then((response) => {
        setOptions(response);
      })
      .catch((error) => {
        onError && onError(error);
      });
  }, []);

  return (
    <Autocomplete
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => option.nome || ""}
      isOptionEqualToValue={(option, value) => {
        return value === undefined || option.id === value.id;
      }}
      value={formik.values[name!]}
      onChange={(event: any, newValue: any | null) => {
        formik.setFieldValue(name!, newValue);
      }}
      onBlur={formik.handleBlur}
      renderInput={(params) => (
        <TextField
          name={name}
          required={required}
          {...params}
          label={label}
          placeholder={placeholder}
          fullWidth
          error={formik.touched[name!] && Boolean(formik.errors[name!])}
          helperText={errorText(formik, name)}
        />
      )}
    />
  );
};

export default AutocompleteInput;
