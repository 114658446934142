export const routes: Map<string, string> = new Map([
  ["app", "Início"],
  ["perfil", "Perfil"],
  ["alimento", "Tabela de Referência"],
  ["criar-alimento", "Adicionar Alimento"],
  ["editar-alimento", "Editar Alimento"],
  ["medida-caseira", "Medidas Caseiras"],
  ["criar-medida", "Adicionar Medida Caseira"],
  ["editar-medida", "Editar Medida Caseira"],
  ["paciente", "Pacientes"],
  ["criar-paciente", "Adicionar Paciente"],
  ["editar-paciente", "Editar Paciente"],
  ["consulta", "Consultas"],
  ["avaliacao", "Avaliações"],
  ["anamnese", "Anamnese"],
  ["anamneses", "Anamneses"],
  ["exame-fisico", "Exame Físico"],
  ["exame-laboratorial", "Exame Laboratorial"],
  ["antropometria", "Antropometria"],
  ["dieta", "Dieta"],
  ["orientacao", "Orientação"],
  ["orientacoes", "Orientações"],
  ["configuracao", "Configurações"],
  ["criar-orientacao", "Adicionar Orientação"],
  ["criar-anamnese", "Adicionar Anamnese"],
  ["editar-anamnese", "Editar Anamnese"],
  ["agendamento", "Agendamentos"],
  ["agendar", "Agendar"],
  ["downloads", "Downloads"],
  ["referencia", "Alimentos"],
]);
