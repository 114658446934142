import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Add,
  CalendarToday,
  Check,
  Delete,
  Email,
  LocalHospital,
  NextPlan,
  Phone,
} from "@mui/icons-material";
import { EventCalendar } from "react-mui-event-calendar";
import ScheduleCreateModal from "../modals/ScheduleCreateModal";
import {
  deleteAgendamento,
  getAllAgendamentoByMes,
  updateAgendamento,
} from "../services/ScheduleService";
import moment from "moment";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";

const ScheduleListPage: React.FC<{ agendar: boolean }> = ({ agendar }) => {
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const [open, setOpen] = React.useState(agendar);
  const [reload, setReload] = React.useState(true);

  const [datasource, setDatasource] = React.useState<any[]>([]);

  const loadMoreAgendamentos = (mes: number, ano: number) => {
    getAllAgendamentoByMes(mes, ano)
      .then((data) => {
        const datasourceMapped = data.map((item) => {
          return {
            id: item.id,
            date: moment(item.data),
            title: item.paciente,
            popupContent: (
              <>
                <List sx={{ pt: 0 }}>
                  <ListItem>
                    <ListItemIcon>
                      <CalendarToday color="secondary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={moment(item.data).format("DD/MM/yyyy HH:mm")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Email color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={item.email} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Phone color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={item.telefone ?? "-"} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <LocalHospital color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={item.status} />
                  </ListItem>
                  {item.status === "Agendada" && (
                    <ListItem>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Check sx={{ color: "white" }} />}
                        sx={{ width: "100%" }}
                        onClick={() =>
                          updateAgendamento(item.id, {
                            id: item.id,
                            dataHora: item.data,
                            status: "CONFIRMADA",
                            obs: item.obs,
                          })
                            .then(() => setReload(true))
                            .catch((error) => notifyError(error))
                        }
                      >
                        Confirmar
                      </Button>
                    </ListItem>
                  )}
                  {item.status === "Confirmada" && (
                    <ListItem>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<NextPlan color="primary" />}
                        sx={{ width: "100%" }}
                        onClick={() =>
                          updateAgendamento(item.id, {
                            id: item.id,
                            dataHora: item.data,
                            status: "NAO_INICIADA",
                            obs: item.obs,
                          })
                            .then(() => setReload(true))
                            .catch((error) => notifyError(error))
                        }
                      >
                        Próximo
                      </Button>
                    </ListItem>
                  )}
                  <ListItem>
                    <Button
                      color="error"
                      startIcon={<Delete sx={{ color: "error" }} />}
                      sx={{ width: "100%" }}
                      onClick={() => {
                        deleteAgendamento(item.id)
                          .then(() => {
                            notifySuccess("Agendamento excluído com sucesso!");
                            setReload(true);
                          })
                          .catch((error) => notifyError(error));
                      }}
                    >
                      Exluir
                    </Button>
                  </ListItem>
                </List>
              </>
            ),
          };
        });

        setDatasource(datasourceMapped);
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  useEffect(() => {
    if (reload) {
      loadMoreAgendamentos(new Date().getMonth() + 1, new Date().getFullYear());
      setReload(false);
    }
  }, [reload]);

  return (
    <Container maxWidth="lg" style={{ marginTop: 25 }}>
      <Card style={{ boxShadow: "none" }}>
        <CardHeader
          title="Meus Agendamentos"
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
              style={{ color: "white" }}
            >
              <Add className="plus-button" />
              Agendamento
            </Button>
          }
        />
        <ScheduleCreateModal
          open={open}
          onClose={(reload) => {
            setOpen(false);
            setReload(reload);
          }}
        />
        <CardContent>
          <EventCalendar
            onDataChange={(data) => console.log(data)}
            dataSource={datasource}
            width="100%"
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default ScheduleListPage;
