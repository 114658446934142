import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getRecomendacaoById } from "../services/EvalutionService";
import PatientSelectedCard from "../../shared/components/PatientSelectedCard";
import useNovaAvaliacao from "../hooks/NovaAvaliacaoHook";
import NovaAvaliacaoButton from "../components/NovaAvaliacaoButton";
import SelecionarRecomendacaoDialog from "../dialogs/SelecionarRecomendacaoDialog";
import SelecionarPerguntaDialog from "../dialogs/SelecionarPerguntaDialog";
import useDownloadFile from "../hooks/DownloadFileHook";
import { globalState } from "../../shared/states/GlobalState";
import { useHookstate } from "@hookstate/core";
import { ExportRecomendacaoPdf } from "../exports/ExportRecomendacaoPdf";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";

const EvaluationOrientationPage: React.FC = () => {
  const { notifySuccess, notifyError } = useSnackbarCustom();
  const { id, consulta_id, recomendacaoId } = useParams();
  const { downloadFile } = useDownloadFile();

  const state = useHookstate(globalState);

  const [orientations, setOrientations] = React.useState<any[]>([]);

  const {
    onNovaAvaliacao,
    isSelecionarAnamneseDialogOpen,
    setIsSelecionarAnamneseDialogOpen,
    isSelecionarRecomendacaoDialogOpen,
    setIsSelecionarRecomendacaoDialogOpen,
    onSelectAnamnese,
    onSelectRecomendacao,
  } = useNovaAvaliacao({ id: id!, consulta_id: consulta_id! });

  const handlePrint = () => {
    downloadFile(
      <ExportRecomendacaoPdf
        sexo={state.value?.sexo}
        nome={state.value?.nome}
        idade={state.value?.idade.toString()}
        recomendacoes={orientations.map((o) => o.nome)}
      />,
      "recomendações.pdf"
    );
  };

  useEffect(() => {
    if (!recomendacaoId) return;

    getRecomendacaoById(recomendacaoId!)
      .then((data) => {
        setOrientations(data.agrupadorRecomendacao.recomendacoes);
      })
      .catch((error) => {
        notifyError(error);
      });
  }, [recomendacaoId]);

  return (
    <Container maxWidth="lg">
      <PatientSelectedCard patientId={id} showPaginaInicialButton={false}>
        <NovaAvaliacaoButton onClick={(av) => onNovaAvaliacao(av)} />
        <Button variant="text" sx={{ ml: 1 }} onClick={handlePrint}>
          Imprimir
        </Button>
      </PatientSelectedCard>
      {orientations.length > 0 ? (
        <Card style={{ boxShadow: "none" }} sx={{ mt: 2 }}>
          <CardHeader
            title="Recomendações"
          />
          <CardContent>
            <List>
              {orientations.map((orientation, index) => (
                <ListItem key={index}>
                  <span style={{ marginRight: 5 }}>-</span>
                  <ListItemText primary={orientation.nome} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardContent>
            <Box>Nenhuma orientação cadastrada.</Box>
          </CardContent>
        </Card>
      )}

      <SelecionarRecomendacaoDialog
        open={isSelecionarRecomendacaoDialogOpen}
        onClose={() => setIsSelecionarRecomendacaoDialogOpen(false)}
        onSelect={onSelectRecomendacao}
      />

      <SelecionarPerguntaDialog
        open={isSelecionarAnamneseDialogOpen}
        onClose={() => setIsSelecionarAnamneseDialogOpen(false)}
        onSelect={onSelectAnamnese}
      />
    </Container>
  );
};

export default EvaluationOrientationPage;
