import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import AntropometriaResultItem from "../components/AntropometriaResultItem";
import { useHookstate } from "@hookstate/core";
import { globalState } from "../../shared/states/GlobalState";
import { ExpandMore, InfoSharp } from "@mui/icons-material";
import InputSelect, {
  InputSelectItem,
} from "../../shared/components/InputSelect";
import Calculator from "../utils/Calculator";
import NumberInput from "../../shared/components/NumberInput";
import { ICreateAntropometriaRequest } from "../models/ICreateAntropometriaRequest";
import { useParams } from "react-router-dom";
import {
  getAntropometriaById,
  saveAntropometria,
} from "../services/EvalutionService";
import ParametroIngestaoHidricaDialog from "../dialogs/ParametroIngestaoHidricaDialog";
import useNovaAvaliacao from "../hooks/NovaAvaliacaoHook";
import SelecionarRecomendacaoDialog from "../dialogs/SelecionarRecomendacaoDialog";
import SelecionarPerguntaDialog from "../dialogs/SelecionarPerguntaDialog";
import PatientSelectedCard from "../../shared/components/PatientSelectedCard";
import NovaAvaliacaoButton from "../components/NovaAvaliacaoButton";
import { ExportAntropometriaPdf } from "../exports/ExportAntropometriaPdf";
import useDownloadFile from "../hooks/DownloadFileHook";
import TabPanelCustom from "../../shared/components/TabPanelCustom";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";

interface EvaluationAnthropometryInputState {
  peso: string;
  altura: string;
  imcIdeal: string;
  diametroPunho: string;
  diametroFemur: string;
  protocolo: string;
  protocoloPercentualGordura: string;
  formulaVet: string;
  objetivo: string;
  nivelAtividade: string;
  fatorAtividade: string;
  dct: string;
  subescapular: string;
  suprailiaca: string;
  panturrilha: string;
  abdominal: string;
  peitoral: string;
  coxa: string;
  biceps: string;
  cb: string;
  cp: string;
  cc: string;
  cq: string;
  ca: string;
}

const EvaluationAnthropometryPage: React.FC = () => {
  const state = useHookstate(globalState);
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const { id, consulta_id, avaliacaoId } = useParams();
  const { downloadFile } = useDownloadFile();

  const [currentTab, setCurrentTab] = React.useState<number>(0);
  const [input, setInput] = React.useState<EvaluationAnthropometryInputState>({
    peso: "",
    altura: "",
    imcIdeal: "",
    diametroPunho: "",
    diametroFemur: "",
    protocolo: "NULL",
    protocoloPercentualGordura: "NULL",
    formulaVet: "NULL",
    objetivo: "NULL",
    nivelAtividade: "NULL",
    fatorAtividade: "",
    dct: "",
    subescapular: "",
    suprailiaca: "",
    panturrilha: "",
    abdominal: "",
    peitoral: "",
    coxa: "",
    biceps: "",
    cb: "",
    cp: "",
    cc: "",
    cq: "",
    ca: "",
  });

  const [imc, setImc] = useState<number | undefined>();
  const [pesoIdeal, setPesoIdeal] = useState<number | undefined>();
  const [pesoAjustado, setPesoAjustado] = useState<number | undefined>();
  const [densidadeCorporal, setDensidadeCorporal] = useState<
    number | undefined
  >();
  const [percentualGordura, setPercentualGordura] = useState<
    number | undefined
  >();
  const [vet, setVet] = useState<number | undefined>();
  const [pesoGordo, setPesoGordo] = useState<number | undefined>();
  const [pesoMuscular, setPesoMuscular] = useState<number | undefined>();
  const [pesoOsseo, setPesoOsseo] = useState<number | undefined>();
  const [pesoResidual, setPesoResidual] = useState<number | undefined>();
  const [pesoMaximo, setPesoMaximo] = useState<number | undefined>();
  const [pesoMinimo, setPesoMinimo] = useState<number | undefined>();
  const [percentagemMuscular, setPercentagemMuscular] = useState<
    number | undefined
  >();
  const [relacaoCinturaQuadril, setRelacaoCinturaQuadril] = useState<
    number | undefined
  >();

  const [ingestaoHidrica, setIngestaoHidrica] = useState<number | undefined>();

  const [parametroIngestaoHidrica, setParametroIngestaoHidrica] =
    useState<string>("");

  const [isIngestaoHidricaDialogOpened, setIsIngestaoHidricaDialogOpened] =
    useState<boolean>(false);

  const {
    onNovaAvaliacao,
    isSelecionarAnamneseDialogOpen,
    setIsSelecionarAnamneseDialogOpen,
    isSelecionarRecomendacaoDialogOpen,
    setIsSelecionarRecomendacaoDialogOpen,
    onSelectAnamnese,
    onSelectRecomendacao,
    convertStringToAvaliacaoType,
  } = useNovaAvaliacao({ id: id!, consulta_id: consulta_id! });

  const formulasVet: InputSelectItem[] = [
    {
      value: "NULL",
      label: "Selecione",
    },
    {
      value: "FORMULA_BOLSO",
      label: "Fórmula de bolso",
    },
    {
      value: "HARRIS_BENEDICT",
      label: "Harris - Benedict (1919)",
    },
    {
      value: "FAO_WHO",
      label: "FAO / WHO (1985)",
    },
    {
      value: "EER_IOM",
      label: "EER / IOM (2005)",
    },
    {
      value: "KATCH_MCARDLE",
      label: "Katch - Mcardle (1996)",
    },
    {
      value: "CUNNINGAN",
      label: "Cunningan (1980)",
    },
    {
      value: "MIFFLIN_SOBREPESO",
      label: "Mifflin - Sobrepeso (1990)",
    },
    {
      value: "MIFFLIN_OBESIDADE",
      label: "Mifflin - Obesidade (1990)",
    },
    {
      value: "HENRY_REES",
      label: "Henry - Rees (1991)",
    },
    {
      value: "TINSLEY_PESO",
      label: "Tinsley - Por peso (2018)",
    },
    {
      value: "TINSLEY_GORDURA",
      label: "Tinsley - Por massa livre de gordura (2018)",
    },
    {
      value: "SCHOFIELD",
      label: "Schofield (1985)",
    },
    {
      value: "MIFFLIN_ST_JEOR",
      label: "Mifflin - ST Jeor (1990)",
    },
    {
      value: "FAO_OMS",
      label: "FAO / OMS (1989)",
    },
  ];

  const objetivos: InputSelectItem[] = [
    {
      value: "NULL",
      label: "Selecione",
    },
    {
      value: "PERDA_PESO",
      label: "Perda de peso",
    },
    {
      value: "MANTER_PESO",
      label: "Manter peso",
    },
    {
      value: "GANHO_PESO",
      label: "Ganho peso",
    },
  ];

  const protocolosPercentualGordura: InputSelectItem[] = [
    {
      value: "NULL",
      label: "Selecione",
    },
    {
      value: "SIRI",
      label: "Siri",
    },
    {
      value: "DURNIN",
      label: "Durnin / Womersley",
    },
    {
      value: "LOHMAN",
      label: "Lohman",
    },
  ];

  const protocolos: InputSelectItem[] = [
    {
      value: "NULL",
      label: "Selecione",
    },
    {
      value: "PETROSKI_4",
      label: "Petroski 1995 - 4 dobras",
    },
    {
      value: "JACKSON_POLLOCK_3",
      label: "Jakcson / Pollock - 3 dobras",
    },
    {
      value: "GUEDES_3",
      label: "Guedes 1985 - 3 dobras",
    },
  ];

  const niveisAtividade: InputSelectItem[] = [
    {
      value: "NULL",
      label: "Selecione",
    },
    {
      value: "SED",
      label: "Sedentário",
    },
    {
      value: "LEV",
      label: "Leve",
    },
    {
      value: "MOD",
      label: "Moderado",
    },
    {
      value: "INT",
      label: "Intenso",
    },
  ];

  const toNumber = (value: string): number => {
    return Number(value.replace(".", "").replace(",", "."));
  };

  const toString = (value: number | undefined): string => {
    if (!value) {
      return "";
    }

    return value.toFixed(2).replace(".", ",");
  };

  const saveHandle = () => {
    const request: ICreateAntropometriaRequest = {
      consultaId: consulta_id!!,
      peso: input.peso ? toNumber(input.peso) : undefined,
      altura: input.altura ? toNumber(input.altura) : undefined,
      imcIdeal: input.imcIdeal ? toNumber(input.imcIdeal) : undefined,
      diametroPulso: input.diametroPunho
        ? toNumber(input.diametroPunho)
        : undefined,
      diametroFemour: input.diametroFemur
        ? toNumber(input.diametroFemur)
        : undefined,
      protocoloDensidadeCorporal: input.protocolo,
      protocoloPercentualGordura: input.protocoloPercentualGordura,
      dct: input.dct ? toNumber(input.dct) : undefined,
      subescapular: input.subescapular
        ? toNumber(input.subescapular)
        : undefined,
      supraIliaca: input.suprailiaca ? toNumber(input.suprailiaca) : undefined,
      panturrilhaMedial: input.panturrilha
        ? toNumber(input.panturrilha)
        : undefined,
      peitoral: input.peitoral ? toNumber(input.peitoral) : undefined,
      coxa: input.coxa ? toNumber(input.coxa) : undefined,
      cb: input.cb ? toNumber(input.cb) : undefined,
      cc: input.cc ? toNumber(input.cc) : undefined,
      cq: input.cq ? toNumber(input.cq) : undefined,
      ca: input.ca ? toNumber(input.ca) : undefined,
      cp: input.cp ? toNumber(input.cp) : undefined,
      vet: vet,
      formulaVet: input.formulaVet,
      objetivo: input.objetivo,
      fatorAtividade: input.fatorAtividade
        ? toNumber(input.fatorAtividade)
        : undefined,
      nivelAtividadeFisica: input.nivelAtividade,
    };

    saveAntropometria(avaliacaoId!, request)
      .then(() => {
        notifySuccess("Composição corporal salva com sucesso!");
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  const openIngestaoHidricaDialog = () => {
    setIsIngestaoHidricaDialogOpened(true);
  };

  const closeIngestaoHidricaDialog = (valor?: string) => {
    if (valor) {
      setParametroIngestaoHidrica(valor);
    }

    setIsIngestaoHidricaDialogOpened(false);
  };

  const handlePrint = () => {
    downloadFile(
      <ExportAntropometriaPdf
        sexo={state.value?.sexo}
        nome={state.value?.nome}
        idade={state.value?.idade.toString()}
        peso={input.peso}
        altura={input.altura}
        imc={toString(imc)}
        imcIdeal={input.imcIdeal}
        vet={toString(vet)}
        necessidadeHidrica={toString(ingestaoHidrica)}
        relacaoCinturaQuadril={relacaoCinturaQuadril}
        pesoMaximo={toString(pesoMaximo)}
        pesoMinimo={toString(pesoMinimo)}
        pesoGordo={toString(pesoGordo)}
        pesoMuscular={toString(pesoMuscular)}
        pesoResidual={toString(pesoResidual)}
        pesoOsseo={toString(pesoOsseo)}
        porcentagemGordura={toString(percentualGordura)}
        porcentagemMuscular={toString(percentagemMuscular)}
      />,
      "antropometria.pdf"
    );
  };

  useEffect(() => {
    if (avaliacaoId) {
      getAntropometriaById(avaliacaoId)
        .then((data) => {
          const newInput: EvaluationAnthropometryInputState = {
            peso: toString(data.peso),
            altura: toString(data.altura),
            imcIdeal: toString(data.imcIdeal),
            diametroPunho: toString(data.diametroPulso),
            diametroFemur: toString(data.diametroFemour),
            protocolo: data.protocoloDensidadeCorporal ?? "NULL",
            protocoloPercentualGordura:
              data.protocoloPercentualGordura ?? "NULL",
            formulaVet: data.formulaVet ?? "NULL",
            objetivo: data.objetivo ?? "NULL",
            nivelAtividade: data.nivelAtividadeFisica ?? "NULL",
            fatorAtividade: toString(data.fatorAtividade),
            dct: toString(data.dct),
            subescapular: toString(data.subescapular),
            suprailiaca: toString(data.supraIliaca),
            panturrilha: toString(data.panturrilhaMedial),
            abdominal: toString(data.abdominal),
            peitoral: "",
            coxa: toString(data.coxa),
            biceps: "",
            cb: toString(data.cb),
            cp: toString(data.cp),
            cc: toString(data.cc),
            cq: toString(data.cq),
            ca: toString(data.ca),
          };

          setInput(newInput);
        })
        .catch((error) => {
          notifyError(error);
        });
    }
  }, [avaliacaoId]);

  useEffect(() => {
    if (input.peso && input.altura) {
      setImc(
        Calculator.calculateImc(toNumber(input.peso), toNumber(input.altura))
      );
    }

    if (input.imcIdeal && input.altura) {
      setPesoIdeal(
        Calculator.calculatePesoIdeal(
          toNumber(input.imcIdeal),
          toNumber(input.altura)
        )
      );
    }

    if (input.altura) {
      setPesoMaximo(Calculator.calculatePesoMaximo(toNumber(input.altura)));
      setPesoMinimo(Calculator.calculatePesoMinimo(toNumber(input.altura)));
    }

    switch (input.formulaVet) {
      case "FORMULA_BOLSO":
        if (input.peso && input.objetivo) {
          setVet(
            Calculator.calculateFormulaBolso(
              toNumber(input.peso),
              input.objetivo
            )
          );
        }
        break;
      case "HRIS_BENEDICT":
        if (input.fatorAtividade && input.peso && input.altura) {
          setVet(
            Calculator.calculateFormulaHarrisBenedict(
              state.value!.sexo,
              state.value!.idade,
              toNumber(input.altura),
              toNumber(input.peso),
              toNumber(input.fatorAtividade)
            )
          );
        }
        break;
      case "FAO_WHO":
        if (input.nivelAtividade && input.peso) {
          setVet(
            Calculator.calculateFormulaFAOWHO(
              state.value!.sexo,
              state.value!.idade,
              input.nivelAtividade,
              toNumber(input.peso)
            )
          );
        }
        break;
      case "EER_IOM":
        if (input.nivelAtividade && input.peso && input.altura) {
          setVet(
            Calculator.calculateFormulaEER(
              state.value!.sexo,
              state.value!.idade,
              input.nivelAtividade,
              toNumber(input.peso),
              toNumber(input.altura)
            )
          );
        }
        break;
      case "FAO_OMS":
        if (input.nivelAtividade && input.peso) {
          setVet(
            Calculator.calculateFormulaFAOOMS(
              state.value!.sexo,
              state.value!.idade,
              input.nivelAtividade,
              toNumber(input.peso)
            )
          );
        }
        break;
    }

    if (
      input.protocolo === "PETROSKI_4" &&
      input.peso &&
      input.altura &&
      input.dct &&
      input.subescapular &&
      input.suprailiaca &&
      input.panturrilha
    ) {
      if (state.value?.sexo === "F" && (!input.peso || !input.altura)) {
        return;
      }

      setDensidadeCorporal(
        Calculator.calculatePetroski4Dobras(
          state.value!.idade,
          state.value!.sexo,
          toNumber(input.dct),
          toNumber(input.subescapular),
          toNumber(input.suprailiaca),
          toNumber(input.panturrilha),
          toNumber(input.peso),
          toNumber(input.altura)
        )
      );
    } else if (
      input.protocolo === "JACKSON_POLLOCK_3" &&
      input.peitoral &&
      input.abdominal &&
      input.coxa
    ) {
      // setResult({
      //   ...result,
      //   densidadeCorporal: Calculator.calculateJacsonPollock3Dobras(
      //     state.value!.idade,
      //     state.value!.sexo,
      //     toNumber(input.peitoral),
      //     toNumber(input.abdominal),
      //     toNumber(input.coxa)
      //   ),
      // });
    } else if (
      input.protocolo === "GUEDES_3" &&
      input.dct &&
      input.subescapular &&
      input.suprailiaca &&
      input.panturrilha &&
      input.biceps &&
      input.coxa
    ) {
      if (
        state.value?.sexo === "F" &&
        input.dct &&
        input.biceps &&
        input.coxa &&
        input.suprailiaca &&
        input.subescapular
      ) {
        // Calculator.calculateGuedes3Dobras();
      } else if (
        state.value?.sexo === "M" &&
        input.dct &&
        input.abdominal &&
        input.suprailiaca
      ) {
        // Calculator.calculateGuedes3Dobras();
      }
    }

    if (input.altura && input.diametroFemur && input.diametroPunho) {
      setPesoOsseo(
        Calculator.calculatePesoOsseo(
          toNumber(input.altura),
          toNumber(input.diametroPunho),
          toNumber(input.diametroFemur)
        )
      );
    }

    if (input.peso) {
      setPesoResidual(
        Calculator.calculatePesoResidual(
          state.value?.sexo!,
          toNumber(input.peso)
        )
      );
    }

    if (input.cc && input.cq) {
      setRelacaoCinturaQuadril(
        Calculator.calculateRelacaoCinturaQuadril(
          toNumber(input.cc),
          toNumber(input.cq)
        )
      );
    }
  }, [input]);

  useEffect(() => {
    if (imc && input.peso && pesoIdeal) {
      setPesoAjustado(
        Calculator.calculatePesoAjustado(imc, toNumber(input.peso), pesoIdeal)
      );
    }
  }, [imc, pesoIdeal, input]);

  useEffect(() => {
    if (densidadeCorporal) {
      switch (input.protocoloPercentualGordura) {
        case "SIRI":
          {
            const value = 4.95 / densidadeCorporal! - 4.5;
            setPercentualGordura(value);
          }
          break;
        case "DURNIN":
          break;
        case "LOHMAN":
          {
            const value = 4.95 / densidadeCorporal! - 4.5;
            setPercentualGordura(value);
          }
          break;
      }
    }
  }, [densidadeCorporal]);

  useEffect(() => {
    if (input.peso && percentualGordura) {
      setPesoGordo(
        Calculator.calculatePesoGordo(toNumber(input.peso), percentualGordura)
      );
    }
  }, [percentualGordura, input]);

  useEffect(() => {
    if (input.peso && pesoGordo && pesoOsseo && pesoResidual) {
      setPesoMuscular(
        Calculator.calculatePesoMuscular(
          toNumber(input.peso),
          pesoGordo,
          pesoOsseo,
          pesoResidual
        )
      );
    }
  }, [pesoGordo, pesoOsseo, pesoResidual, input]);

  useEffect(() => {
    if (input.peso && pesoMuscular) {
      setPercentagemMuscular(
        Calculator.calculatePercentagemPesoMuscular(
          toNumber(input.peso),
          pesoMuscular
        )
      );
    }
  }, [pesoMuscular, input]);

  useEffect(() => {
    if (input.peso && parametroIngestaoHidrica) {
      setIngestaoHidrica(
        toNumber(input.peso) * toNumber(parametroIngestaoHidrica)
      );
    }
  }, [parametroIngestaoHidrica, input]);

  return (
    <Container maxWidth="lg">
      <PatientSelectedCard patientId={id} showPaginaInicialButton={false}>
        <NovaAvaliacaoButton onClick={(av) => onNovaAvaliacao(av)} />
        <Button
          variant="contained"
          color="secondary"
          onClick={saveHandle}
          sx={{ ml: 1 }}
        >
          Salvar
        </Button>
        <Button variant="text" sx={{ ml: 1 }} onClick={handlePrint}>
          Imprimir
        </Button>
      </PatientSelectedCard>
      <Tabs
        value={currentTab}
        onChange={(e, page) => setCurrentTab(page)}
        sx={{ mt: 2 }}
      >
        <Tab label="Composição corporal" />
        <Tab label="Resultados" />
        <Tab label="Tutoriais" />
      </Tabs>
      <TabPanelCustom value={currentTab} index={0}>
        <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
          <CardHeader title="Composição do Corpo" />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item sm={12} md={4}>
                <NumberInput
                  name="peso"
                  label="Peso"
                  value={input.peso}
                  onChange={(e) => setInput({ ...input, peso: e })}
                  endAdornment="Kg"
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <NumberInput
                  name="altura"
                  label="Altura"
                  value={input.altura}
                  onChange={(e) => setInput({ ...input, altura: e })}
                  endAdornment="m"
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <NumberInput
                  name="imcIdeal"
                  label="IMC ideal"
                  endAdornment="Kg/m²"
                  value={input.imcIdeal}
                  onChange={(e) => setInput({ ...input, imcIdeal: e })}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mt: 1 }}>
              <Grid item sm={12} md={4}>
                <NumberInput
                  name="diametroPunho"
                  label="Diâmetro do punho"
                  value={input.diametroPunho}
                  onChange={(e) => setInput({ ...input, diametroPunho: e })}
                  endAdornment="cm"
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <NumberInput
                  name="diametroFemur"
                  label="Diâmetro do fêmur"
                  value={input.diametroFemur}
                  onChange={(e) => setInput({ ...input, diametroFemur: e })}
                  endAdornment="cm"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
          <CardHeader title="Dobras Corporais" />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item sm={12} md={4}>
                <InputSelect
                  label="Protocolo de densidade corporal"
                  value={input.protocolo}
                  onChange={(e) =>
                    setInput({ ...input, protocolo: e.target.value })
                  }
                  options={protocolos}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <InputSelect
                  label="Protocolo de percentual de gordura"
                  value={input.protocoloPercentualGordura}
                  onChange={(e) =>
                    setInput({
                      ...input,
                      protocoloPercentualGordura: e.target.value,
                    })
                  }
                  options={protocolosPercentualGordura}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mt: 3 }}>
              {input.protocolo !== "NULL" &&
                (input.protocolo === "PETROSKI_4" ||
                  input.protocolo === "GUEDES_3") && (
                  <Grid item sm={12} md={4}>
                    <NumberInput
                      name="dct"
                      label="DCT"
                      value={input.dct}
                      onChange={(e) => setInput({ ...input, dct: e })}
                      endAdornment="mm"
                    />
                  </Grid>
                )}
              {input.protocolo !== "NULL" &&
                (input.protocolo === "PETROSKI_4" ||
                  (input.protocolo === "GUEDES_3" &&
                    state.value?.sexo === "F")) && (
                  <Grid item sm={12} md={4}>
                    <NumberInput
                      name="subescapular"
                      label="Subescapular"
                      value={input.subescapular}
                      onChange={(e) => setInput({ ...input, subescapular: e })}
                      endAdornment="mm"
                    />
                  </Grid>
                )}
              {input.protocolo !== "NULL" &&
                (input.protocolo === "PETROSKI_4" ||
                  input.protocolo === "GUEDES_3") && (
                  <Grid item sm={12} md={4}>
                    <NumberInput
                      name="suprailiaca"
                      label="Supra-ilíaca"
                      value={input.suprailiaca}
                      onChange={(e) => setInput({ ...input, suprailiaca: e })}
                      endAdornment="mm"
                    />
                  </Grid>
                )}
              {input.protocolo !== "NULL" &&
                input.protocolo === "JACKSON_POLLOCK_3" && (
                  <Grid item sm={12} md={4}>
                    <NumberInput
                      name="peitoral"
                      label="Peitoral"
                      value={input.peitoral}
                      onChange={(e) => setInput({ ...input, peitoral: e })}
                      endAdornment="mm"
                    />
                  </Grid>
                )}
              {input.protocolo !== "NULL" &&
                (input.protocolo === "JACKSON_POLLOCK_3" ||
                  (input.protocolo === "GUEDES_3" &&
                    state.value?.sexo === "M")) && (
                  <Grid item sm={12} md={4}>
                    <NumberInput
                      name="abdominal"
                      label="Abdominal"
                      value={input.abdominal}
                      onChange={(e) => setInput({ ...input, abdominal: e })}
                      endAdornment="mm"
                    />
                  </Grid>
                )}
              {input.protocolo !== "NULL" &&
                (input.protocolo === "JACKSON_POLLOCK_3" ||
                  (input.protocolo === "GUEDES_3" &&
                    state.value?.sexo === "F")) && (
                  <Grid item sm={12} md={4}>
                    <NumberInput
                      name="coxa"
                      label="Coxa"
                      value={input.coxa}
                      onChange={(e) => setInput({ ...input, coxa: e })}
                      endAdornment="mm"
                    />
                  </Grid>
                )}
              {input.protocolo !== "NULL" &&
                input.protocolo === "PETROSKI_4" && (
                  <Grid item sm={12} md={4}>
                    <NumberInput
                      name="panturrilha"
                      label="Panturrilha medial"
                      value={input.panturrilha}
                      onChange={(e) => setInput({ ...input, panturrilha: e })}
                      endAdornment="mm"
                    />
                  </Grid>
                )}
              {state.value?.sexo === "F" &&
                input.protocolo !== "NULL" &&
                input.protocolo === "GUEDES_3" && (
                  <Grid item sm={12} md={4}>
                    <NumberInput
                      name="biceps"
                      label="Biceps"
                      value={input.biceps}
                      onChange={(e) => setInput({ ...input, biceps: e })}
                      endAdornment="mm"
                    />
                  </Grid>
                )}
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
          <CardHeader title="Circunferências Corporais" />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item sm={12} md={4}>
                <NumberInput
                  name="cb"
                  label="CB"
                  endAdornment="mm"
                  value={input.cb}
                  onChange={(e) => setInput({ ...input, cb: e })}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <NumberInput
                  name="cp"
                  label="CP"
                  endAdornment="mm"
                  value={input.cp}
                  onChange={(e) => setInput({ ...input, cp: e })}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <NumberInput
                  name="cc"
                  label="CC"
                  endAdornment="mm"
                  value={input.cc}
                  onChange={(e) => setInput({ ...input, cc: e })}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <NumberInput
                  name="cq"
                  label="CQ"
                  endAdornment="mm"
                  value={input.cq}
                  onChange={(e) => setInput({ ...input, cq: e })}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <NumberInput
                  name="ca"
                  label="CA"
                  endAdornment="mm"
                  value={input.ca}
                  onChange={(e) => setInput({ ...input, ca: e })}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
          <CardHeader title="Parâmetros do GET" />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item sm={12} md={4}>
                <InputSelect
                  label="Fórmula"
                  value={input.formulaVet}
                  onChange={(e) =>
                    setInput({ ...input, formulaVet: e.target.value })
                  }
                  options={formulasVet}
                />
              </Grid>
              {input.formulaVet !== "NULL" &&
                input.formulaVet !== "HARRIS_BENEDICT" &&
                input.formulaVet !== "FAO_WHO" &&
                input.formulaVet !== "EER_IOM" &&
                input.formulaVet !== "FAO_OMS" && (
                  <Grid item sm={12} md={4}>
                    <InputSelect
                      label="Objetivo"
                      value={input.objetivo}
                      onChange={(e) =>
                        setInput({ ...input, objetivo: e.target.value })
                      }
                      options={objetivos}
                    />
                  </Grid>
                )}
              {input.formulaVet !== "NULL" &&
                input.formulaVet !== "FORMULA_BOLSO" &&
                input.formulaVet !== "HARRIS_BENEDICT" && (
                  <Grid item sm={12} md={4}>
                    <InputSelect
                      label="Nível de atividade física"
                      value={input.nivelAtividade}
                      onChange={(e) =>
                        setInput({ ...input, nivelAtividade: e.target.value })
                      }
                      options={niveisAtividade}
                    />
                  </Grid>
                )}
              {input.formulaVet !== "NULL" &&
                input.formulaVet !== "FAO_WHO" &&
                input.formulaVet !== "EER_IOM" &&
                input.formulaVet === "HARRIS_BENEDICT" && (
                  <Grid item sm={12} md={4}>
                    <NumberInput
                      name="fatorAtividade"
                      label="Fator atividade"
                      value={input.fatorAtividade}
                      onChange={(e) =>
                        setInput({ ...input, fatorAtividade: e })
                      }
                    />
                  </Grid>
                )}
            </Grid>
          </CardContent>
        </Card>
      </TabPanelCustom>
      <TabPanelCustom value={currentTab} index={1}>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item sm={6}>
            <Card sx={{ boxShadow: "none", backgroundColor: "primary.dark" }}>
              <CardContent>
                <Typography variant="h6" color="primary.light">
                  GET
                </Typography>
                <Typography variant="h4" sx={{ color: "white" }}>
                  {vet ? Math.floor(Number(vet)) : "-"} kcal
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={6}>
            <Card sx={{ boxShadow: "none", backgroundColor: "secondary.dark" }}>
              <CardContent>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography
                    variant="h6"
                    display="inline"
                    color="secondary.light"
                  >
                    Necessidades Hídricas
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={openIngestaoHidricaDialog}
                    sx={{ ml: 1, color: "secondary.light" }}
                  >
                    <InfoSharp sx={{ width: 18, height: 18 }} />
                  </IconButton>
                </Box>
                <Typography variant="h4" color="white">
                  {ingestaoHidrica ? Math.floor(ingestaoHidrica) : "-"} ml
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} md={6}>
            <Card style={{ boxShadow: "none" }}>
              <CardHeader title="Pesos Calculados" />
              <CardContent>
                <Box display="flex" flexDirection="column">
                  <AntropometriaResultItem
                    title="IMC"
                    result={imc ? imc.toFixed(2) : "-"}
                    dimension="kg/m²"
                  />
                  <AntropometriaResultItem
                    title="Peso ideal"
                    result={pesoIdeal ? pesoIdeal.toFixed(2) : "-"}
                    dimension="kg"
                  />
                  <AntropometriaResultItem
                    title="IMC ideal"
                    result={
                      input.imcIdeal ? toNumber(input.imcIdeal).toFixed(2) : "-"
                    }
                    dimension="kg/m²"
                  />
                  <AntropometriaResultItem
                    title="Peso ajustado"
                    result={pesoAjustado ? pesoAjustado.toFixed(2) : "-"}
                    dimension="kg"
                  />
                  <AntropometriaResultItem
                    title="Densidade corporal"
                    result={
                      densidadeCorporal ? densidadeCorporal.toFixed(2) : "-"
                    }
                    dimension="g/cm³"
                  />
                  <AntropometriaResultItem
                    title="Peso gordo"
                    result={pesoGordo ? pesoGordo.toFixed(2) : "-"}
                    dimension="kg"
                  />
                  <AntropometriaResultItem
                    title="Peso muscular"
                    result={pesoMuscular ? pesoMuscular.toFixed(2) : "-"}
                    dimension="kg"
                  />
                  <AntropometriaResultItem
                    title="Peso residual"
                    result={pesoResidual ? pesoResidual.toFixed(2) : "-"}
                    dimension="kg"
                  />
                  <AntropometriaResultItem
                    title="Peso ósseo"
                    result={pesoOsseo ? pesoOsseo.toFixed(2) : "-"}
                    dimension="kg"
                  />
                  <AntropometriaResultItem
                    title="Peso mínimo"
                    result={pesoMinimo ? pesoMinimo.toFixed(2) : "-"}
                    dimension="kg"
                  />
                  <AntropometriaResultItem
                    title="Peso máximo"
                    result={pesoMaximo ? pesoMaximo.toFixed(2) : "-"}
                    dimension="kg"
                  />
                  <AntropometriaResultItem
                    title="Excesso de peso"
                    result={pesoAjustado ? pesoAjustado.toFixed(2) : "-"}
                    dimension="kg"
                  />
                  <AntropometriaResultItem
                    title="Porcentagem muscular"
                    result={
                      percentagemMuscular ? percentagemMuscular.toFixed(2) : "-"
                    }
                    dimension="%"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ boxShadow: "none", backgroundColor: "primary" }}>
              <CardHeader title="Composições Corporais Calculadas" />
              <CardContent>
                <Box display="flex" flexDirection="column">
                  <AntropometriaResultItem
                    title="Porcentagem de gordura corporal"
                    result={
                      percentualGordura
                        ? (percentualGordura * 100).toFixed(2)
                        : "-"
                    }
                    dimension="%"
                  />
                  <AntropometriaResultItem
                    title="Relação cintura-quadril"
                    result={
                      relacaoCinturaQuadril
                        ? relacaoCinturaQuadril.toFixed(2)
                        : "-"
                    }
                    dimension=""
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanelCustom>

      <TabPanelCustom value={currentTab} index={2}>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              Como aferir a dobra cutânea tricipital - DCT?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              <li>
                Peça à pessoa que fique relaxada, de pé, com o braço pendurado
                ao lado do corpo. O músculo tríceps deve estar relaxado, sem
                contração muscular;
              </li>
              <li>
                Encontre e marque o ponto médio entre o acrômio (o osso do
                ombro) e o olécrano (a ponta do cotovelo);
              </li>
              <li>
                Pegue a dobra cutânea verticalmente, levantando a pele e o
                tecido adiposo com o adipômetro;
              </li>
              <li>
                Segure a dobra com firmeza sem beliscar o músculo subjacente;
              </li>
              <li>
                Utilize o adipômetro para medir a dobra cutânea. Normalmente, a
                leitura é feita em milímetros (mm);
              </li>
              <li>
                Após posicionar corretamente o adipômetro na dobra cutânea
                tricipital, aguarde alguns segundos para permitir que a mola do
                adipômetro se estabilize na leitura;
              </li>
              <li>Anote a medida obtida; </li>
              <li>
                Para aumentar a precisão, repita o processo de medição duas ou
                três vezes;
              </li>
              <li>
                Registre todas as leituras e calcule a média das medidas para
                obter um valor mais confiável.
              </li>
            </ol>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img
                src={
                  "https://images.tcdn.com.br/img/editor/up/706165/dobra_cutanea_triceps.jpg"
                }
                alt="DCT"
                width={350}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              Como aferir a dobra cutânea bicipital - DCB?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              <li>
                Peça à pessoa para ficar relaxada, com o braço estendido ao lado
                do corpo;
              </li>
              <li>
                Localize o ponto médio entre o acrômio (osso do ombro) e o
                olécrano (ponta do cotovelo) na parte anterior do braço;
              </li>
              <li>Marque este ponto central na pele;</li>
              <li>
                Com o indivíduo ainda relaxado, pegue a dobra cutânea
                verticalmente, levantando a pele e o tecido adiposo com o
                adipômetro;
              </li>
              <li>
                Segure a dobra com firmeza sem beliscar o músculo subjacente;
              </li>
              <li>
                Posicione o adipômetro na dobra cutânea bicipital, alinhando-o
                perpendicularmente à dobra;
              </li>
              <li>
                Aperte o gatilho do adipômetro para realizar a medição. O
                ponteiro irá indicar a medida em milímetros (mm);
              </li>
              <li>
                Aguarde alguns segundos para que o adipômetro estabilize na
                leitura. Anote o valor da dobra cutânea bicipital obtido na
                escala do adipômetro;
              </li>
              <li>
                Para aumentar a precisão, repita o procedimento de medição duas
                ou três vezes;
              </li>
              <li>
                Registre todas as leituras e calcule a média para obter um valor
                mais confiável.
              </li>
            </ol>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img
                src={
                  "https://images.tcdn.com.br/img/editor/up/706165/dobra_cutanea_biceps.jpg"
                }
                alt="DCT"
                width={350}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              Como aferir a dobra cutânea subescapular - DCSE?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              <li>
                Peça à pessoa que fique em pé ou sentada, com os ombros
                relaxados e os braços ao lado do corpo;
              </li>
              <li>
                Identifique a escápula (omoplata), que é o osso grande na parte
                superior das costas;
              </li>
              <li>
                Localize o ponto diagonalmente abaixo da escápula, onde a pele
                forma uma dobra natural quando a pessoa gira o braço
                ligeiramente para trás;{" "}
              </li>
              <li>
                Peça à pessoa que gire levemente o braço para trás para expor a
                área da dobra cutânea subescapular;
              </li>
              <li>
                Use os dedos para identificar a localização exata da dobra
                cutânea subescapular;
              </li>
              <li>
                Com a pessoa na posição adequada, pegue a dobra cutânea
                verticalmente entre o polegar e o indicador;
              </li>
              <li>
                Utilize o adipômetro para medir a dobra cutânea. Posicione o
                adipômetro paralelamente à dobra, a cerca de 1 cm abaixo do
                ponto identificado;
              </li>
              <li>
                A medida da dobra cutânea subescapular é executada obliquamente
                em relação ao eixo longitudinal, seguindo a orientação dos arcos
                costais, sendo localizada a dois centímetros abaixo do ângulo
                inferior da escápula. Dessa forma, a dobra cutânea forma uma
                linha de 45 graus em relação ao alinhamento da pele natural e se
                prolonga na diagonal, em direção ao cotovelo direito;
              </li>
              <li>
                Aguarde alguns segundos para que o adipômetro estabilize na
                leitura. Anote o valor da dobra cutânea subescapular obtido na
                escala do adipômetro;
              </li>
              <li>
                Repita o processo de medição duas ou três vezes para aumentar a
                precisão;
              </li>
              <li>
                Registre todas as leituras e calcule a média para obter um valor
                mais confiável.
              </li>
            </ol>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img
                src={
                  "https://images.tcdn.com.br/img/editor/up/706165/dobra_cutanea_subescapular.jpg"
                }
                alt="DCT"
                width={350}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              Como aferir a dobra cutânea abdominal - DCABD?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              <li>
                Peça à pessoa que fique em pé, com os músculos abdominais
                relaxados e os braços ao lado do corpo;{" "}
              </li>
              <li>
                Encontre o ponto na linha média do corpo, aproximadamente 2 cm
                ao lado da cicatriz umbilical;{" "}
              </li>
              <li>
                Este ponto geralmente está na altura da crista ilíaca (parte
                superior do quadril) e da costela;{" "}
              </li>
              <li>
                Utilize os dedos para identificar a localização exata da dobra
                cutânea abdominal. Esta dobra deve estar paralela ao músculo
                reto abdominal;{" "}
              </li>
              <li>
                Com a pessoa na posição adequada, pegue a dobra cutânea
                verticalmente entre o polegar e o indicador;{" "}
              </li>
              <li>
                Utilize o adipômetro para medir a dobra cutânea. Posicione o
                adipômetro paralelamente à dobra, a cerca de 2 cm ao lado da
                cicatriz umbilical;{" "}
              </li>
              <li>
                Aperte o gatilho do adipômetro para realizar a medição. O
                ponteiro ou a tela digital do adipômetro mostrará a medida em
                milímetros (mm);{" "}
              </li>
              <li>
                Aguarde alguns segundos para que o adipômetro estabilize na
                leitura. Anote o valor da dobra cutânea abdominal obtida na
                escala do adipômetro;{" "}
              </li>
              <li>
                Repita o processo de medição duas ou três vezes para aumentar a
                precisão;{" "}
              </li>
              <li>
                Registre todas as leituras e calcule a média para obter um valor
                mais confiável.{" "}
              </li>
            </ol>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img
                src={
                  "https://images.tcdn.com.br/img/editor/up/706165/dobra_cutanea_abdominal.jpg"
                }
                alt="DCT"
                width={350}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              Como aferir a dobra cutânea axilar média - DCAXM?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              <li>
                Peça para a pessoa ficar em pé, com os braços relaxados ao lado
                do corpo;
              </li>
              <li>
                Identifique o ponto médio da axila na linha média axilar, entre
                a borda inferior da omoplata e a parede lateral do tórax;
              </li>
              <li>
                Com a mão não dominante, segure a dobra cutânea com os dedos
                polegar e indicador, tomando cuidado para incluir toda a
                espessura da dobra cutânea axilar média;
              </li>
              <li>
                Utilize um adipômetro na mão dominante para medir a espessura da
                dobra cutânea;
              </li>
              <li>
                Posicione o adipômetro paralelamente à dobra cutânea e a cerca
                de 1 cm abaixo do ponto de preensão;
              </li>
              <li>
                Aperte o gatilho do adipômetro e aguarde alguns segundos para
                que a leitura se estabilize;
              </li>
              <li>Registre a medida em milímetros (mm);</li>
              <li>
                Repita o procedimento duas ou três vezes para garantir a
                precisão da medida;
              </li>
              <li>
                Se houver diferenças significativas entre as medidas repetidas,
                faça uma média dos valores.
              </li>
            </ol>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img
                src={
                  "https://images.tcdn.com.br/img/editor/up/706165/dobra_cutanea_axilar_media.jpg"
                }
                alt="DCT"
                width={350}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              Como aferir a dobra cutânea peitoral ou torácica - DCPE?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              <li>
                Peça para a pessoa ficar em pé, com os braços relaxados ao lado
                do corpo;
              </li>
              <li>
                Encontre o ponto correto para a medição da dobra cutânea
                peitoral. Ele geralmente está localizado na metade da distância
                entre o mamilo e a axila, na linha média axilar;
              </li>
              <li>
                Com a mão não dominante, levante e segure a dobra cutânea
                peitoral com os dedos polegar e indicador, tomando cuidado para
                incluir toda a espessura da dobra cutânea;
              </li>
              <li>
                Utilize um adipômetro na mão dominante para medir a espessura da
                dobra cutânea;
              </li>
              <li>
                Posicione o adipômetro paralelamente à dobra cutânea, a
                aproximadamente 1 cm abaixo do ponto de preensão;
              </li>
              <li>
                Aperte o gatilho do adipômetro e aguarde alguns segundos para
                que a leitura se estabilize; Registre a medida em milímetros
                (mm);
              </li>
              <li>
                Repita o procedimento duas ou três vezes para garantir a
                precisão da medida;
              </li>
              <li>
                Se houver diferenças significativas entre as medidas repetidas,
                faça uma média dos valores.
              </li>
            </ol>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={
                  "https://images.tcdn.com.br/img/editor/up/706165/dobra_cutanea_toracica_peitoral_feminina.jpg"
                }
                alt="DCT"
                width={350}
              />
              <img
                src={
                  "https://images.tcdn.com.br/img/editor/up/706165/dobra_cutanea_toracica_peitoral.jpg"
                }
                alt="DCT"
                width={350}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              Como aferir a dobra cutânea da coxa - DCCOX?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              <li>
                Peça para a pessoa ficar em pé, com o peso corporal igualmente
                distribuído entre as pernas;
              </li>
              <li>
                Encontre o ponto correto para a medição da dobra cutânea da
                coxa. Geralmente, este ponto está localizado na parte anterior
                da coxa, na metade da distância entre o joelho e a dobra da
                virilha;
              </li>
              <li>
                Com a mão não dominante, segure a dobra cutânea da coxa com os
                dedos polegar e indicador, garantindo que toda a espessura da
                dobra cutânea esteja incluída;
              </li>
              <li>
                Utilize um adipômetro na mão dominante para medir a espessura da
                dobra cutânea;
              </li>
              <li>
                Posicione o adipômetro perpendicularmente à dobra cutânea, a
                aproximadamente 1 cm abaixo do ponto de preensão;
              </li>
              <li>
                Aperte o gatilho do adipômetro e aguarde alguns segundos para
                que a leitura se estabilize. Registre a medida em milímetros
                (mm);
              </li>
              <li>
                Repita o procedimento duas ou três vezes para garantir a
                precisão da medida;
              </li>
              <li>
                Se houver diferenças significativas entre as medidas repetidas,
                faça uma média dos valores.
              </li>
            </ol>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img
                src={
                  "https://images.tcdn.com.br/img/editor/up/706165/dobra_cutanea_coxa.jpg"
                }
                alt="DCT"
                width={350}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              Como aferir a dobra cutânea da panturrilha medial - DCPANTM?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              <li>
                Peça ao indivíduo que fique de pé com o peso distribuído
                igualmente entre os dois pés;
              </li>
              <li>
                Garanta que a panturrilha esteja relaxada. A medição deve ser
                feita em posição ereta, mas sem contrair os músculos;
              </li>
              <li>
                Localize a parte mais larga da panturrilha (gastrocnêmico);
              </li>
              <li>
                Use a fita métrica para encontrar o ponto médio entre o joelho e
                o tornozelo, que é onde a dobra cutânea será aferida;
              </li>
              <li>Marque esse ponto com a caneta dermográfica;</li>
              <li>
                Limpe o adipômetro com álcool para garantir a higienização;
              </li>
              <li>
                Ajuste o adipômetro de acordo com as instruções do fabricante;
              </li>
              <li>
                Com a mão esquerda (se você for destro), faça uma pinça com o
                polegar e o indicador cerca de 1 cm acima do ponto marcado;
              </li>
              <li>
                Puxe suavemente a pele e a gordura subcutânea, afastando-a do
                músculo;
              </li>
              <li>
                Com a mão direita (se você for destro), posicione o adipômetro
                perpendicular à dobra cutânea, cerca de 1 cm abaixo dos dedos
                que estão segurando a pele;
              </li>
              <li>
                Solte o adipômetro lentamente, permitindo que ele se ajuste à
                espessura da dobra cutânea;
              </li>
              <li>
                Espere cerca de 1 a 2 segundos após soltar o adipômetro para
                fazer a leitura;
              </li>
              <li>Registre a medida em milímetros (mm);</li>
              <li>
                Repita o procedimento pelo menos duas vezes no mesmo ponto para
                garantir a precisão da medição;
              </li>
              <li>
                Se as leituras variarem mais de 1 mm, realize uma terceira
                medição e utilize a média das três medidas.
              </li>
            </ol>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img
                src={
                  "https://images.tcdn.com.br/img/editor/up/706165/dobra_cutanea_panturrilha_medial.jpg"
                }
                alt="DCT"
                width={350}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Como aferir a dobra cutânea suprailíaca?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              <li>
                Certifique-se de que o ambiente esteja privado e confortável;
              </li>
              <li>
                Peça à pessoa que fique em pé, relaxada, com os braços ao lado
                do corpo;
              </li>
              <li>
                Encontre a crista ilíaca, que é a parte superior do osso do
                quadril;
              </li>
              <li>
                A dobra cutânea suprailíaca é localizada logo acima desta área,
                geralmente ligeiramente à frente da linha média lateral do
                corpo;
              </li>
              <li>
                Para identificar o ponto exato, você pode usar a fita métrica
                para medir aproximadamente 2-3 cm acima da crista ilíaca;
              </li>
              <li>
                Com a mão não dominante, faça uma pinça com o polegar e o
                indicador, levantando a pele e a gordura subcutânea no local
                identificado;
              </li>
              <li>
                Certifique-se de que está pegando apenas a gordura e não o
                músculo;
              </li>
              <li>
                A pinça deve ser feita no ângulo correto, que geralmente é
                aproximadamente diagonal ou levemente inclinada, seguindo a
                orientação natural da gordura subcutânea;
              </li>
              <li>
                Posicione o adipômetro aproximadamente 1 cm abaixo da pinça, no
                centro da dobra cutânea;
              </li>
              <li>
                Libere a pressão do adipômetro lentamente para garantir uma
                leitura precisa;
              </li>
              <li>
                Mantenha a pinça firme enquanto faz a medição para evitar que a
                pele e a gordura se soltem;
              </li>
              <li>
                Após liberar a pressão do adipômetro, leia a medida indicada no
                instrumento;
              </li>
              <li>Anote o valor obtido;</li>
              <li>
                Repita o processo pelo menos duas vezes para garantir a
                consistência das medições;
              </li>
              <li>
                Se houver uma diferença significativa entre as medições, realize
                uma terceira medição e use a média dos valores obtidos;
              </li>
              <li>Registre todas as medições realizadas;</li>
              <li>
                Compare os resultados com tabelas de referência ou utilize
                fórmulas específicas para calcular a percentagem de gordura
                corporal, se necessário.
              </li>
            </ol>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img
                src={
                  "https://images.tcdn.com.br/img/editor/up/706165/dobra_cutanea_supra_iliaca.jpg"
                }
                alt="DCT"
                width={350}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              Como aferir a dobra cutânea supraespinal - DCSESP?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              <li>Peça à pessoa que fique em pé, relaxada, com os braços ao lado do corpo;</li>
              <li>A dobra cutânea supraespinal está localizada acima da espinha ilíaca ântero-superior, que é a parte mais proeminente do osso do quadril na frente do corpo;</li>
              <li>Para localizar o ponto exato, encontre a espinha ilíaca ântero-superior e suba cerca de 5 cm, indo levemente para frente;</li>
              <li>O ponto de medição é normalmente em um ângulo de 45 graus, alinhado com a crista ilíaca;</li>
              <li>Com a mão não dominante, faça uma pinça com o polegar e o indicador, levantando a pele e a gordura subcutânea no local identificado. Certifique-se de que está pegando apenas a gordura e não o músculo;</li>
              <li>A pinça deve ser feita no ângulo correto, que é diagonal em relação à espinha ilíaca ântero-superior.</li>
              <li>Posicione o adipômetro aproximadamente 1 cm abaixo da pinça, no centro da dobra cutânea;</li>
              <li>Libere a pressão do adipômetro lentamente para garantir uma leitura precisa;</li>
              <li>Mantenha a pinça firme enquanto faz a medição para evitar que a pele e a gordura se soltem.</li>
              <li>Após liberar a pressão do adipômetro, leia a medida indicada no instrumento;</li>
              <li>Anote o valor obtido;</li>
              <li>Repita o processo pelo menos duas vezes para garantir a consistência das medições;</li>
              <li>Se houver uma diferença significativa entre as medições, realize uma terceira medição e use a média dos valores obtidos;</li>
              <li>Registre todas as medições realizadas;</li>
              <li>Compare os resultados com tabelas de referência ou utilize fórmulas específicas para calcular a percentagem de gordura corporal, se necessário.</li>
            </ol>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img
                src={
                  "https://images.tcdn.com.br/img/editor/up/706165/dobra_cutanea_supra_espinhal.jpg"
                }
                alt="DCT"
                width={350}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </TabPanelCustom>

      <ParametroIngestaoHidricaDialog
        open={isIngestaoHidricaDialogOpened}
        initialValue={parametroIngestaoHidrica}
        onClose={closeIngestaoHidricaDialog}
      />
      <SelecionarRecomendacaoDialog
        open={isSelecionarRecomendacaoDialogOpen}
        onClose={() => setIsSelecionarRecomendacaoDialogOpen(false)}
        onSelect={onSelectRecomendacao}
      />

      <SelecionarPerguntaDialog
        open={isSelecionarAnamneseDialogOpen}
        onClose={() => setIsSelecionarAnamneseDialogOpen(false)}
        onSelect={onSelectAnamnese}
      />
    </Container>
  );
};

export default EvaluationAnthropometryPage;
