import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { SnackbarProvider } from "notistack";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId="727723266997-0u9miki7uo75de7e3fm8odq6hsnpc0oj.apps.googleusercontent.com">
    <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
      <App />
    </SnackbarProvider>
  </GoogleOAuthProvider>

  // </React.StrictMode>
);
