import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { isAllInputNotTouched } from "../../shared/utils/InputUtil";
import TextInput from "../../shared/components/TextInput";
import InputSelect from "../../shared/components/InputSelect";
import { PerguntaRequest } from "../models/AgrupadorPerguntaRequest";
import NumberInput from "../../shared/components/NumberInput";
import { TipoRespostaEnum } from "../../shared/enums/TipoRespostaEnum";
import { Remove } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";

interface CreatePerguntaDialogProps {
  id?: string;
  open: boolean;
  onAdd: (pergunta: PerguntaRequest) => void;
  onClose: () => void;
}

interface IFormValues {
  titulo: string;
  subTitulo?: string;
  tipoResposta: string;
  selectOpcoes?: string[];
}

const schema = yup.object().shape({
  titulo: yup.string().required("Nome é obrigatório"),
  subTitulo: yup.string(),
  tipoResposta: yup.string().required("Tipo de Resposta é obrigatório"),
  selectOpcoes: yup.array().of(yup.string().required("Opção é obrigatória")),
});

const initialValues: IFormValues = {
  titulo: "",
  subTitulo: "",
  tipoResposta: "",
  selectOpcoes: [],
};

const CreatePerguntaDialog: React.FC<CreatePerguntaDialogProps> = ({
  id,
  open,
  onAdd,
  onClose,
}) => {
  const [isShowOpcoes, setIsShowOpcoes] = React.useState(false);
  const tipoRespostaEnum = new TipoRespostaEnum();

  const onSubmit = (values: IFormValues) => {
    onAdd({
      id: id ?? uuidv4(),
      titulo: values.titulo,
      subTitulo: values.subTitulo,
      tipoAvaliacao: "ANAMNESE",
      tipoResposta: values.tipoResposta,
      selectOpcoes: values.selectOpcoes || [],
      ordem: 0,
    });
    onClose();
    formik.resetForm();
  };

  const formik = useFormik<IFormValues>({
    initialValues,
    validationSchema: schema,
    onSubmit,
  });

  const handleAddOption = () => {
    formik.setFieldValue("selectOpcoes", [
      ...(formik.values.selectOpcoes || []),
      "",
    ]);
  };

  const handleRemoveOption = (selectedOpcao: string) => {
    formik.setFieldValue(
      "selectOpcoes",
      formik.values.selectOpcoes?.filter((opcao) => opcao !== selectedOpcao)
    );
  };

  useEffect(() => {
    if (formik.values.tipoResposta && formik.values.tipoResposta != "TEXTO") {
      setIsShowOpcoes(true);
    } else {
      isShowOpcoes && setIsShowOpcoes(false);
    }
  }, [formik.values]);

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Detalhes da Pergunta</DialogTitle>
      <DialogContent>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12}>
            <TextInput label="Título" name="titulo" required formik={formik} />
          </Grid>
          <Grid item xs={12}>
            <TextInput label="Subtítulo" name="subTitulo" formik={formik} />
          </Grid>
          <Grid item xs={12}>
            <InputSelect
              label="Tipo de Resposta"
              name="tipoResposta"
              formik={formik}
              required
              options={tipoRespostaEnum.getForSelect()}
            />
          </Grid>
          {isShowOpcoes && (
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="secondary" marginLeft={1}>
                  Opções:
                </Typography>
                <Button
                  variant="outlined"
                  onClick={handleAddOption}
                  color="secondary"
                >
                  Adicionar
                </Button>
              </div>
              <List>
                {formik.values.selectOpcoes?.map((opcao, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <TextInput
                          key={index}
                          label={`Opção`}
                          name={`selectOpcoes.${index}`}
                          formik={formik}
                        />
                      }
                      style={{ marginRight: 15 }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => handleRemoveOption(opcao)}
                        style={{ backgroundColor: "#9d7df0" }}
                      >
                        <Remove className="plus-button" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isAllInputNotTouched(formik) || !formik.isValid}
          onClick={() => formik.submitForm()}
          style={{ color: "white" }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePerguntaDialog;
