import { Paper } from "@mui/material";
import React from "react";

interface TabPanelCustomProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanelCustom({
  children,
  value,
  index,
  ...other
}: TabPanelCustomProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-custom-${index}`}
      {...other}
    >
      {value === index && (
        <Paper elevation={0} sx={{ p: 2 }}>
          {children}
        </Paper>
      )}
    </div>
  );
}

export default TabPanelCustom;
