import { useSnackbar } from "notistack";

export const useSnackbarCustom = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSuccessMessage = (message: string) => {
    enqueueSnackbar(message, { variant: "success" });
  };

  const handleErrorMessage = (message: string) => {
    enqueueSnackbar(message, { variant: "error" });
  };

  return {
    notifySuccess: handleSuccessMessage,
    notifyError: handleErrorMessage,
  };
};
