import { InputSelectItem } from "../components/InputSelect";

export enum TipoAlimento {
  CEREAIS_DERIVADOS = "Cereais e Derivados",
  VERDURAS_HORTALICAS_DERIVADOS = "Verduras, Hortaliças e Derivados",
  FRUTAS_DERIVADOS = "Frutas e Derivados",
  GORDURAS_OLEOS = "Gorduras e Óleos",
  PESCADOS_E_FRUTOS_DO_MAR = "Pescados e Frutos do Mar",
  CARNES_DERIVADOS = "Carnes e Derivados",
  LEITE_DERIVADOS = "Leite e Derivados",
  BEBIDAS = "Bebidas (Alcoólicas e não Alcoólicas)",
  OVOS_DERIVADOS = "Ovos e Derivados",
  PRODUTOS_ACUCARADOS = "Produtos Açucarados",
  MISCELANEAS = "Miscelâneas",
  OUTROS_ALIMENTOS_INDUSTRIALIZADOS = "Outros Alimentos Industrializados",
  ALIMENTOS_PREPARADOS = "Alimentos Preparados",
  LEGUMINOSAS_E_DERIVADOS = "Leguminosas e Derivados",
  NOZES_E_SEMENTES = "Nozes e Sementes",
}

export const getTipoAlimentoForSelect = (): InputSelectItem[] =>
  Object.keys(TipoAlimento).map((key) => {
    return {
      label: TipoAlimento[key as keyof typeof TipoAlimento],
      value: key,
    };
  });
