import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React from "react";
import TextInput from "../../shared/components/TextInput";

interface FilterRecomendacaoDialogProps {
  id?: string;
  open: boolean;
  onFilter: (filter: IRecomendacaoFilter) => void;
  onClose: () => void;
}

export interface IRecomendacaoFilter {
  nome?: string;
}

const FilterRecomendacaoDialog: React.FC<FilterRecomendacaoDialogProps> = ({
  id,
  open,
  onFilter,
  onClose,
}) => {
  const [filterForm, setFilterForm] = React.useState<{ nome: string }>({
    nome: "",
  });

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => onClose()}>
      <DialogTitle variant="h6" color="secondary">
        Filtre por:
      </DialogTitle>
      <DialogContent>
        <Grid container p={1} spacing={1}>
          <Grid item xs={12}>
            <TextInput
              name="nome"
              label="Nome"
              required={false}
              value={filterForm.nome}
              onChange={(e) => setFilterForm({ ...filterForm, nome: e })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onFilter({});
            setFilterForm({ ...filterForm, nome: "" });
            onClose();
          }}
        >
          Limpar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onFilter({ nome: filterForm.nome });
            setFilterForm({ ...filterForm, nome: "" });
            onClose();
          }}
          style={{ color: "white" }}
        >
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterRecomendacaoDialog;
