import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./shared/providers/AuthProvider";
import { AppRoutes } from "./shared/components/AppRoutes";
import { Audio as Loading } from "react-loader-spinner";
import { useHookstate } from "@hookstate/core";
import { globalState } from "./shared/states/GlobalState";
import LoadingOverlay from "react-loading-overlay-ts";
import { appStyleState } from "./shared/states/AppStyleState";
import { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

Chart.register(CategoryScale);

function App() {
  const state = useHookstate(globalState);
  const styleState = useHookstate(appStyleState);

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#ffae5d",
        light: "#ffc476",
        dark: "#ff7337",
      },
      secondary: {
        main: "#3f3f3f",
        light: "#3f3f3f",
        dark: "#3f3f3f",
      },
      background: {
        default: "rgba(255, 255, 255, 0.54)",
      },
    },
    typography: {
      fontFamily: ["Roboto"].join(","),
    },
  });

  useEffect(() => {
    const styles = appStyleState.get();
    document.documentElement.style.setProperty(
      "--primary-color",
      styles.primaryColor
    );
  }, [styleState]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AuthProvider>
          <LoadingOverlay
            active={state.value?.isLoading ?? false}
            spinner={
              <Loading
                height="100"
                width="100"
                color="#ffae5d"
                ariaLabel="loading"
              />
            }
            styles={{
              overlay: (base) => ({
                ...base,
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 9999,
              }),
              content: (base) => ({
                ...base,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }),
            }}
          >
            <Router>
              <AppRoutes />
            </Router>
          </LoadingOverlay>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
