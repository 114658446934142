import { AxiosError } from "axios";
import api from "./Api";

export const getEntry = async <T>(
  resource: string,
  urlSearchParams?: URLSearchParams
): Promise<T> => {
  try {
    const response = await api.get<T>(`${resource}${urlSearchParams ? `?${urlSearchParams.toString()}` : ""}`);
    return response.data;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      throw new Error(error.message);
    } else {
      throw new Error("Falha ao buscar informações");
    }
  }
};

export const createEntry = async <T, R>(
  resource: string,
  request: T
): Promise<R> => {
  try {
    const response = await api.post<R>(resource, request);
    return response.data;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      throw new Error(error.message);
    } else {
      throw new Error("Falha ao criar registro");
    }
  }
};

export const editEntry = async <T, R>(
  resource: string,
  request: T
): Promise<R> => {
  try {
    const response = await api.put<R>(resource, request);
    return response.data;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      throw new Error(error.message);
    } else {
      throw new Error("Falha ao atualizar registro");
    }
  }
};

export const removeEntry = async (resource: string): Promise<void> => {
  try {
    const response = await api.delete(resource);
    return response.data;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      throw new Error(error.message);
    } else {
      throw new Error("Falha ao remover registro");
    }
  }
};
