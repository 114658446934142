import { IconButton, InputAdornment, TextField } from "@mui/material";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { errorText, getNestedProperty } from "../utils/InputUtil";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface TextInputProps {
  label?: string;
  name?: string;
  endAdornment?: string;
  placeholder?: string;
  required?: boolean;
  formik?: FormikProps<any>;
  disabled?: boolean;
  multiline?: boolean;
  password?: boolean;
  rows?: number;
  value?: string;
  autoComplete?: string;
  margin?: "none" | "dense" | "normal";
  onChange?: (event: string) => void;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  name,
  required,
  endAdornment,
  placeholder,
  formik,
  disabled,
  multiline,
  password,
  rows,
  value,
  autoComplete,
  margin,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEndAdornmentIcon = (): JSX.Element | undefined => {
    if (endAdornment && password) {
      return (
        <InputAdornment position="end">
          {endAdornment}
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      );
    }

    if (endAdornment) {
      return <InputAdornment position="end">{endAdornment}</InputAdornment>;
    }

    if (password) {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      );
    }

    return undefined;
  };

  return (
    <TextField
      variant="outlined"
      label={label}
      required={required}
      fullWidth
      multiline={multiline}
      margin={margin}
      rows={rows}
      placeholder={placeholder}
      name={name}
      autoComplete={autoComplete}
      type={password && !showPassword ? "password" : "text"}
      value={
        value ? value : formik ? getNestedProperty(formik.values, name!) : ""
      }
      onChange={
        formik
          ? formik.handleChange
          : onChange
          ? (e) => onChange(e.target.value)
          : undefined
      }
      onBlur={formik ? formik.handleBlur : undefined}
      error={
        formik
          ? getNestedProperty(formik.touched, name!) &&
            Boolean(getNestedProperty(formik.errors, name!))
          : false
      }
      helperText={formik ? errorText(formik, name!) : undefined}
      disabled={disabled ?? false}
      InputProps={{ endAdornment: handleEndAdornmentIcon() }}
    />
  );
};

export default TextInput;
