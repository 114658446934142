import { Card, CardContent, Grid } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect } from "react";
import { getAdminHome } from "../services/HomeService";
import { GetAdminHomeResponse } from "../models/GetAdminHomeResponse";

const AdminHomePage: React.FC = () => {
  const [home, setHome] = React.useState<GetAdminHomeResponse | null>(null);

  useEffect(() => {
    getAdminHome().then((response) => {
      setHome(response);
    });
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={3}>
          <Container sx={{ bgcolor: "white", p: 2, borderRadius: 1 }}>
            <h4>Quantidade de clientes</h4>
            <h3>{home?.totalClientes ? home.totalClientes : "-"}</h3>
          </Container>
        </Grid>
        <Grid item xs={12} md={3}>
          <Container sx={{ bgcolor: "white", p: 2, borderRadius: 1 }}>
            <h4>Quantidade de pacientes</h4>
            <h3>{home?.totalPacientes ? home.totalPacientes : "-"}</h3>
          </Container>
        </Grid>
        <Grid item xs={12} md={3}>
        <Container sx={{ bgcolor: "white", p: 2, borderRadius: 1 }}>
            <h4>Quantidade de alimentos</h4>
            <h3>{home?.totalAlimentos ? home.totalAlimentos : "-"}</h3>
          </Container>
        </Grid>
        <Grid item xs={12} md={3}>
        <Container sx={{ bgcolor: "white", p: 2, borderRadius: 1 }}>
            <h4>Quantidade de medidas</h4>
            <h3>{home?.totalMedidasCaseiras ? home.totalMedidasCaseiras : "-"}</h3>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminHomePage;
