import { FormikProps } from "formik";
import React from "react";
import { errorText } from "../utils/InputUtil";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

interface DateInputProps {
  label: string;
  name: string;
  required?: boolean;
  formik: FormikProps<any>;
}

const DateInput: React.FC<DateInputProps> = ({
  label,
  name,
  required,
  formik,
}) => {
  const onChange = (e: any) => {
    formik.setFieldValue(name, e ? e.toISOString() : "");
  };

  return (
    <DatePicker
      label={label}
      name={name}
      format="DD/MM/YYYY"
      value={formik.values[name] ? moment(formik.values[name]) : null}
      onChange={(e) => onChange(e)}
      slotProps={{
        textField: {
          required: required,
          fullWidth: true,
          onBlur: formik.handleBlur,
          helperText: errorText(formik, name),
          error:
            formik.touched.dataNascimento &&
            Boolean(formik.errors.dataNascimento),
        },
      }}
    />
  );
};

export default DateInput;
