import { useState } from "react";

export interface IFilter {
  field: string;
  value: any;
}

export const useTableFilter = () => {
  const [filtersApplied, setFiltersApplied] = useState<IFilter[]>([]);

  const handleAddFilter = (filter: IFilter) => {
    setFiltersApplied([...filtersApplied, filter]);
  };

  const handleRemoveFilter = (filter: IFilter) => {
    setFiltersApplied(filtersApplied.filter((item) => item !== filter));
  };

  const generateFilterString = (): string | undefined => {
    if (filtersApplied.length === 0) {
      return undefined;
    }

    return filtersApplied
      .map((filter) => {
        if (typeof filter.value === "number" || typeof filter.value === "boolean") { 
          return `${filter.field} eq ${filter.value}`;
        }
        return `contains(${filter.field}, '${filter.value}')`;
      })
      .join(" and ");
  };

  return {
    filters: filtersApplied,
    onAddFilter: handleAddFilter,
    onRemoveFilter: handleRemoveFilter,
    generateFilterString,
  };
};
