import { Route, Routes } from "react-router-dom";
import { NotFoundPage } from "../pages/NotFoundPage";
import { ProtectedRoute } from "./ProtectedRoute";
import SinginPage from "../../auth/pages/SinginPage";
import Dashboard from "../pages/Dashboard";
import HomePage from "../../home/pages/HomePage";
import FoodListPage from "../../food/pages/FoodListPage";
import SignUpPage from "../../auth/pages/SingupPage";
import FoodFormPage from "../../food/pages/FoodFormPage";
import PatientFormPage from "../../patient/pages/PatientFormPage";
import PatientListPage from "../../patient/pages/PatientListPage";
import EvaluationAnthropometryPage from "../../evaluation/pages/EvaluationAnthropometryPage";
import EvaluationDietPage from "../../evaluation/pages/EvaluationDietPage";
import ScheduleListPage from "../../schedule/pages/ScheduleListPage";
import EvaluationOrientationPage from "../../evaluation/pages/EvaluationOrientationPage";
import EvaluationChemicalTestPage from "../../evaluation/pages/EvaluationChemicalTestPage";
import PerfilPage from "../../perfil/pages/PerfilPage";
import ConsultaDashboardPage from "../../consulta/pages/ConsultaDashboardPage";
import EvaluationDashboardPage from "../../evaluation/pages/EvaluationDashboardPage";
import MedidaCaseiraListPage from "../../medidaCaseira/pages/MedidaCaseiraListPage";
import MedidaCaseiraFormPage from "../../medidaCaseira/pages/MedidaCaseiraFormPage";
import PerguntaListPage from "../../pergunta/pages/PerguntaListPage";
import PerguntaFormPage from "../../pergunta/pages/PerguntaFormPage";
import OrientationListPage from "../../orientation/pages/OrientationListPage";
import OrientationFormPage from "../../orientation/pages/OrientationFormPage";
import EvaluationAnamnesePage from "../../evaluation/pages/EvaluationAnamnesePage";
import EvaluationBodyTestPage from "../../evaluation/pages/EvaluationBodyTestPage";
import { DownloadListPage } from "../../download/pages/DownloadListPage";
import ForgotPasswordPage from "../../auth/pages/ForgotPasswordPage";
import ResetPasswordPage from "../../auth/pages/ResetPasswordPage";
import ValidateEmailPage from "../../auth/pages/ValidateEmailPage";
import { DownloadFormPage } from "../../download/pages/DownloadFormPage";
import { ForbiddenPage } from "../pages/ForbiddenPage";
import { RoleGuard } from "./RoleGuard";
import { ChoosePageFromRole } from "./ChoosePageFromRole";
import AdminHomePage from "../../home/pages/AdminHomePage";
import MedidaCaseiraLoteFormPage from "../../medidaCaseira/pages/MedidaCaseiraLoteFormPage";

export const FORBIDDEN = "/acesso-negado";

export const APP = "/";

export const LOGIN = `${APP}acessar`;
export const REGISTER = `${APP}registrar`;
export const PERFIL = `${APP}perfil`;
export const FORGOT_PASSWORD = `${APP}recuperar-senha`;
export const RESET_PASSWORD = `${APP}resetar-senha`;
export const CONFIRM_EMAIL = `${APP}confirmar-email`;

export const AGENDAMENTO = `${APP}agendamento`;
export const CADASTRAR_AGENDAMENTO = `${APP}agendamento/agendar`;

export const LISTAR_DOWNLOAD = `${APP}downloads`;

const CONFIGURACAO = `${APP}`;
export const CONFIGURACAO_ORIENTACAO = `${CONFIGURACAO}orientacoes`;
export const CADASTRAR_CONFIGURACAO_ORIENTACAO = `${CONFIGURACAO}orientacoes/criar`;
export const EDITAR_CONFIGURACAO_ORIENTACAO = (id: string) =>
  `${CONFIGURACAO}orientacoes/${id}/editar`;
export const CONFIGURACAO_ANAMNESE = `${CONFIGURACAO}anamneses`;
export const CADASTRAR_CONFIGURACAO_ANAMNESE = `${CONFIGURACAO}anamneses/criar-anamnese`;
export const EDITAR_CONFIGURACAO_ANAMNESE = (id: string) =>
  `${CONFIGURACAO}anamneses/${id}/editar-anamnese`;

const ALIMENTO = `${APP}`;
export const LISTAR_ALIMENTOS = `${ALIMENTO}referencia`;
export const CADASTRAR_ALIMENTO = `${ALIMENTO}referencia/criar-alimento`;
export const EDITAR_ALIMENTO = (id: string) =>
  `${ALIMENTO}referencia/${id}/editar-alimento`;
export const LISTAR_MEDIDA_CASEIRA = `${ALIMENTO}medida-caseira`;
export const CADASTRAR_MEDIDA_CASEIRA = `${ALIMENTO}medida-caseira/criar-medida`;
export const CADASTRAR_MEDIDA_CASEIRA_LOTE = `${ALIMENTO}medida-caseira/criar-medida-lote`;
export const EDITAR_MEDIDA_CASEIRA = (id: string) =>
  `${ALIMENTO}/medida-caseira/${id}/editar-medida`;

export const PACIENTE = `${APP}paciente`;

export const LISTAR_PACIENTE = `${PACIENTE}`;
export const CADASTRAR_PACIENTE = `${PACIENTE}/criar-paciente`;
export const EDITAR_PACIENTE = (id: string) =>
  `${PACIENTE}/${id}/editar-paciente`;

export const CONSULTA_DASHBOARD = (id: string) => `${PACIENTE}/${id}/consulta`;

export const AVALIACAO_DASHBOARD = (id: string, consulta_id: string) =>
  `${CONSULTA_DASHBOARD(id)}/${consulta_id}/avaliacao`;
export const AVALIACAO_ANTROPOMETRIA = (
  id: string,
  consulta_id: string,
  avaliacaoId: string
) => `${AVALIACAO_DASHBOARD(id, consulta_id)}/${avaliacaoId}/antropometria`;
export const AVALIACAO_EXAME_FISICO = (
  id: string,
  consulta_id: string,
  avaliacaoId: string
) => `${AVALIACAO_DASHBOARD(id, consulta_id)}/${avaliacaoId}/exame-fisico`;
export const AVALIACAO_EXAME_QUIMICO = (
  id: string,
  consulta_id: string,
  avaliacaoId: string
) =>
  `${AVALIACAO_DASHBOARD(id, consulta_id)}/${avaliacaoId}/exame-laboratorial`;
export const AVALIACAO_DIETA = (
  id: string,
  consulta_id: string,
  avaliacaoId: string
) => `${AVALIACAO_DASHBOARD(id, consulta_id)}/${avaliacaoId}/dieta`;
export const AVALIACAO_ORIENTACAO = (
  id: string,
  consulta_id: string,
  recomendacaoId: string
) => `${AVALIACAO_DASHBOARD(id, consulta_id)}/${recomendacaoId}/orientacao`;
export const AVALIACAO_ANAMNESE = (
  id: string,
  consulta_id: string,
  avaliacaoId: string
) => `${AVALIACAO_DASHBOARD(id, consulta_id)}/${avaliacaoId}/anamnese`;

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={LOGIN} element={<SinginPage />} />
      <Route path={REGISTER} element={<SignUpPage />} />
      <Route path={FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route path={RESET_PASSWORD} element={<ResetPasswordPage />} />
      <Route path={CONFIRM_EMAIL} element={<ValidateEmailPage />} />
      <Route
        path={APP}
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      >
        <Route path="perfil" element={<PerfilPage />} />

        <Route
          path=""
          element={
            <ChoosePageFromRole
              redirectIfNotPermit={FORBIDDEN}
              pageRoles={[
                { permit: ["Client"], page: <HomePage /> },
                { permit: ["Admin"], page: <AdminHomePage /> },
              ]}
            />
          }
        />

        <Route path={`referencia`} element={<FoodListPage />} />
        <Route path={`referencia/criar-alimento`} element={<FoodFormPage />} />
        <Route
          path="referencia/:id/editar-alimento"
          element={<FoodFormPage />}
        />
        <Route path="medida-caseira" element={<MedidaCaseiraListPage />} />
        <Route
          path="medida-caseira/criar-medida"
          element={<MedidaCaseiraFormPage />}
        />
        <Route
          path="medida-caseira/criar-medida-lote"
          element={<MedidaCaseiraLoteFormPage />}
        />
        <Route
          path="medida-caseira/:id/editar-medida"
          element={<MedidaCaseiraFormPage />}
        />

        <Route path="orientacoes">
          <Route path="" element={<OrientationListPage />} />
          <Route path="criar" element={<OrientationFormPage />} />
          <Route path=":id/editar" element={<OrientationFormPage />} />
        </Route>

        <Route path="anamneses">
          <Route path="" element={<PerguntaListPage />} />
          <Route path="criar-anamnese" element={<PerguntaFormPage />} />
          <Route path=":id/editar-anamnese" element={<PerguntaFormPage />} />
        </Route>

        <Route
          path="agendamento"
          element={
            <RoleGuard permit={["Client"]} redirectIfNotPermit={FORBIDDEN}>
              <ScheduleListPage agendar={false} />
            </RoleGuard>
          }
        />
        <Route
          path="agendamento/agendar"
          element={
            <RoleGuard permit={["Client"]} redirectIfNotPermit={FORBIDDEN}>
              <ScheduleListPage agendar={true} />
            </RoleGuard>
          }
        />

        <Route path="downloads">
          <Route path="" element={<DownloadListPage />} />
          <Route path="criar" element={<DownloadFormPage />} />
        </Route>

        <Route
          path="paciente"
          element={
            <RoleGuard permit={["Client"]} redirectIfNotPermit={FORBIDDEN}>
              <PatientListPage />
            </RoleGuard>
          }
        />
        <Route
          path="paciente/criar-paciente"
          element={
            <RoleGuard permit={["Client"]} redirectIfNotPermit={FORBIDDEN}>
              <PatientFormPage />
            </RoleGuard>
          }
        />
        <Route
          path="paciente/:id/editar-paciente"
          element={
            <RoleGuard permit={["Client"]} redirectIfNotPermit={FORBIDDEN}>
              <PatientFormPage />
            </RoleGuard>
          }
        />

        <Route path="paciente/:id">
          <Route path="consulta">
            <Route
              path=""
              element={
                <RoleGuard permit={["Client"]} redirectIfNotPermit={FORBIDDEN}>
                  <ConsultaDashboardPage />
                </RoleGuard>
              }
            />
            <Route path=":consulta_id/avaliacao">
              <Route
                path=""
                element={
                  <RoleGuard
                    permit={["Client"]}
                    redirectIfNotPermit={FORBIDDEN}
                  >
                    <EvaluationDashboardPage />
                  </RoleGuard>
                }
              />
              <Route
                path=":avaliacaoId/anamnese"
                element={<EvaluationAnamnesePage />}
              />
              <Route
                path=":avaliacaoId/antropometria"
                element={<EvaluationAnthropometryPage />}
              />
              <Route
                path=":avaliacaoId/dieta"
                element={<EvaluationDietPage />}
              />
              <Route
                path=":recomendacaoId/orientacao"
                element={<EvaluationOrientationPage />}
              />
              <Route
                path=":avaliacaoId/exame-laboratorial"
                element={<EvaluationChemicalTestPage />}
              />

              <Route
                path=":avaliacaoId/exame-fisico"
                element={<EvaluationBodyTestPage />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
      <Route path={FORBIDDEN} element={<ForbiddenPage />} />
    </Routes>
  );
};
