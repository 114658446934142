import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import NumberInput from "../../shared/components/NumberInput";
import TextInput from "../../shared/components/TextInput";
import { isAllInputNotTouched } from "../../shared/utils/InputUtil";

interface AddMedidaCaseiraDialogProps {
  open: boolean;
  alimento: string;
  onClose: () => void;
  onAdd: (medida: IMedidaCaseiraInput) => void;
}

export interface IMedidaCaseiraInput {
  nome: string;
  tamanhoMedida: string;
  equivalenteGramas: string;
}

const schema = Yup.object().shape({
  nome: Yup.string().required("Medida caseira é obrigatória"),
  tamanhoMedida: Yup.string().required("Tamanho da medida é obrigatório"),
  equivalenteGramas: Yup.string().required(
    "Equivalente em gramas é obrigatório"
  ),
});

const defaultValues: IMedidaCaseiraInput = {
  nome: "",
  tamanhoMedida: "",
  equivalenteGramas: "",
};

export function AddMedidaCaseiraDialog({
  open,
  onClose,
  onAdd,
  alimento,
}: AddMedidaCaseiraDialogProps): JSX.Element {
  const onSubmit = (data: IMedidaCaseiraInput) => {
    onAdd(data);
    formik.resetForm();
  };

  const formik = useFormik<IMedidaCaseiraInput>({
    initialValues: defaultValues,
    validationSchema: schema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth>
        <DialogTitle>Adicionar medida caseira - {alimento}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={4}>
              <NumberInput
                name="tamanhoMedida"
                label="Tamanho da medida"
                formik={formik}
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                name="nome"
                label="Medida caseira"
                formik={formik}
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <NumberInput
                name="equivalenteGramas"
                label="Equivalente em gramas"
                formik={formik}
                required
                endAdornment="g"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            onClick={onClose}
            sx={{ width: "120px" }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isAllInputNotTouched(formik) || !formik.isValid}
            onClick={() => {
              formik.submitForm();
            }}
            sx={{ width: "120px" }}
            style={{ color: "white" }}
          >
            Adicionar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={isAllInputNotTouched(formik) || !formik.isValid}
            onClick={() => {
              formik.submitForm();
              onClose();
            }}
          >
            Adicionar e fechar
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
