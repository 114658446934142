import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import React, { useEffect } from "react";
import DietaRefeicaoDialog from "../dialogs/DietaRefeicaoDialog";
import { Add, Delete } from "@mui/icons-material";
import DietaRefeicaoAlimentoDialog, {
  IDietaCardapioDialogDto,
} from "../dialogs/DietaRefeicaoAlimentoDialog";
import { useParams } from "react-router-dom";
import { getDietaById, saveDieta } from "../services/EvalutionService";
import {
  DietaCardapioRequest,
  DietaRefeicaoRequest,
  DietaRequest,
} from "../models/IDietaRequest";
import { toString } from "../../shared/utils/InputUtil";
import SelecionarRecomendacaoDialog from "../dialogs/SelecionarRecomendacaoDialog";
import SelecionarPerguntaDialog from "../dialogs/SelecionarPerguntaDialog";
import useNovaAvaliacao from "../hooks/NovaAvaliacaoHook";
import PatientSelectedCard from "../../shared/components/PatientSelectedCard";
import NovaAvaliacaoButton from "../components/NovaAvaliacaoButton";
import DietaAdequacaoGeralDialog from "../dialogs/DietaAdequacaoGeralDialog";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";

const LinearProgressCho = styled(LinearProgress)(({ theme }) => ({
  [`& .${linearProgressClasses.barColorPrimary}`]: {
    backgroundColor: "#ebd834",
  },
}));

const LinearProgressLip = styled(LinearProgress)(({ theme }) => ({
  [`& .${linearProgressClasses.barColorPrimary}`]: {
    backgroundColor: "#b1eb34",
  },
}));

const LinearProgressPtn = styled(LinearProgress)(({ theme }) => ({
  [`& .${linearProgressClasses.barColorPrimary}`]: {
    backgroundColor: "#34ebe1",
  },
}));

interface IVet {
  vet: number;
  sinalAcum: number;
  vetAjuste: number;
  vetTotal: number;
}

export interface IParametroAdequacao {
  lip: string;
  ptn: string;
  cho: string;
}

export interface IAdequacaoGeral {
  lip: number;
  ptn: number;
  cho: number;
  perKcal: number;
  perLip: number;
  perPtn: number;
  perCho: number;
}

const EvaluationDietPage: React.FC = () => {
  const { id, consulta_id, avaliacaoId } = useParams();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const {
    onNovaAvaliacao,
    isSelecionarAnamneseDialogOpen,
    setIsSelecionarAnamneseDialogOpen,
    isSelecionarRecomendacaoDialogOpen,
    setIsSelecionarRecomendacaoDialogOpen,
    onSelectAnamnese,
    onSelectRecomendacao,
  } = useNovaAvaliacao({ id: id!, consulta_id: consulta_id! });

  const [tipo, setTipo] = React.useState<string>("LIVRE");
  const [diaSemana, setDiaSemana] = React.useState(() => [
    "SEG",
    "TER",
    "QUA",
    "QUI",
    "SEX",
    "SAB",
    "DOM",
  ]);
  const [vet, setVet] = React.useState<IVet>({
    vet: 0,
    vetTotal: 0,
    vetAjuste: 0,
    sinalAcum: 0,
  });
  const [parametroAdequacao, setParametroAdequacao] =
    React.useState<IParametroAdequacao>({
      lip: "30",
      ptn: "15",
      cho: "55",
    });
  const [adequacaoGeral, setAdequacaoGeral] = React.useState<IAdequacaoGeral>({
    lip: 0,
    ptn: 0,
    cho: 0,
    perKcal: 0,
    perLip: 0,
    perPtn: 0,
    perCho: 0,
  });
  const [isAdequacaoGeralDialogOpened, setIsAdequacaoGeralDialogOpened] =
    React.useState(false);
  const [isRefeicaoDialogOpened, seIsRefeicaoDialogOpened] =
    React.useState(false);
  const [isAlimentooDialogOpened, setIsAlimentooDialogOpened] =
    React.useState(false);

  const [adequcaoGrafico, setAdequcaoGrafico] = React.useState<any[]>([
    {
      innerRadius: 70,
      data: [
        { id: 0, value: 33, label: "Lip", color: "red" },
        { id: 1, value: 33, label: "Ptn", color: "red" },
        { id: 2, value: 34, label: "Cho", color: "red" },
      ],
    },
  ]);

  const [refeicao, setRefeicao] = React.useState(0);
  const [refeicoes, setRefeicoes] = React.useState<DietaRefeicaoRequest[]>([]);

  const [alimentos, setAlimentos] = React.useState<
    Map<string, IDietaCardapioDialogDto[]>
  >(new Map()); // Mapeia o id da refeição com os alimentos

  const handleDiaSemana = (
    event: React.MouseEvent<HTMLElement>,
    dias: string[]
  ) => {
    setDiaSemana(dias);
  };

  const openRefeicaoDialog = () => {
    seIsRefeicaoDialogOpened(true);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setRefeicao(newValue);
  };

  const addRefeicao = (refeicao: DietaRefeicaoRequest) => {
    setRefeicoes([...refeicoes, refeicao]);
  };

  const addAlimento = (alimento: IDietaCardapioDialogDto) => {
    const currentRefeicao = refeicoes[refeicao].id!;

    const refeicaoAlimentos = alimentos.get(currentRefeicao) || [];
    refeicaoAlimentos.push(alimento);
    setAlimentos(new Map(alimentos.set(currentRefeicao, refeicaoAlimentos)));
  };

  const removeAlimento = (alimento: IDietaCardapioDialogDto) => {
    const currentRefeicao = refeicoes[refeicao].id!;

    const refeicaoAlimentos = alimentos.get(currentRefeicao) || [];
    const newAlimentos = refeicaoAlimentos.filter(
      (current) => current.id !== alimento.id
    );
    setAlimentos(new Map(alimentos.set(currentRefeicao, newAlimentos)));
  };

  const handleSave = () => {
    const refeicoesRequest = refeicoes;

    for (let refeicao of refeicoes) {
      refeicao.cardapios =
        alimentos.get(refeicao.id!)?.map(
          (alimento) =>
            ({
              id: alimento.id,
              alimentoId: alimento.alimento.id,
              medidaCaseiraId: alimento.medidaCaseira?.id,
              qtdGramas: alimento.qtdGramas,
              qtdMedCas: alimento.qtdMedCas,
            } as DietaCardapioRequest)
        ) ?? [];
    }

    const request: DietaRequest = {
      id: avaliacaoId,
      consultaId: consulta_id!,
      vet: vet.vet,
      vetTotal: vet.vetTotal,
      vetAcum: vet.vetAjuste,
      sinalAcum: vet.sinalAcum,
      tipo: tipo || undefined,
      refeicoes: refeicoesRequest,
      diaSemanas: diaSemana,
    };

    saveDieta(avaliacaoId!, request)
      .then(() => {
        notifySuccess("Plano alimentar salvo com sucesso!");
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  useEffect(() => {
    getDietaById(avaliacaoId!)
      .then((dieta) => {
        const vetData = {
          vet: dieta.vet ?? 0,
          vetTotal: dieta.vetTotal ?? 0,
          vetAjuste: dieta.vetAcum ?? 0,
          sinalAcum: dieta.sinalAcum ?? 0,
        };

        setTipo(dieta.tipo ?? "LIVRE");

        setVet(vetData);

        dieta.percentualCarboidrato &&
          setParametroAdequacao({
            ...parametroAdequacao,
            cho: toString(dieta.percentualCarboidrato ?? 0),
            lip: toString(dieta.percentualLipidio ?? 0),
            ptn: toString(dieta.percentualProteina ?? 0),
          });

        setDiaSemana(() => dieta.diaSemanas!);

        setRefeicoes(
          dieta.refeicoes
            ? dieta.refeicoes.map((refeicao) => ({
                id: refeicao.id!,
                dietaId: refeicao.dietaId,
                nome: refeicao.nome,
                horario: refeicao.horario,
                cardapios: [],
              }))
            : []
        );

        if (dieta.refeicoes) {
          for (let refeicao of dieta.refeicoes) {
            const refeicaoAlimentos = refeicao.cardapios.map(
              (alimento) =>
                ({
                  id: alimento.id,
                  dietaRefeicaoId: refeicao.id!,
                  alimento: alimento.alimentoId,
                  medidaCaseira: alimento.medidaCaseiraId,
                  qtdMedCas: alimento.qtdMedCas,
                  qtdGramas: alimento.qtdGramas,
                } as IDietaCardapioDialogDto)
            );

            setAlimentos(
              new Map(alimentos.set(refeicao.id!, refeicaoAlimentos))
            );
          }
        } else {
          setAlimentos(new Map());
        }
      })
      .catch((error) => {
        notifyError(error);
      });
  }, [avaliacaoId]);

  useEffect(() => {
    const alimentosList: IDietaCardapioDialogDto[] = [];

    alimentos.forEach((value: IDietaCardapioDialogDto[], key: string) => {
      alimentosList.push(...value);
    });

    let kcalTotal = 0;
    let lipTotal = 0;
    let ptnTotal = 0;
    let choTotal = 0;

    alimentosList.forEach((alim) => {
      // De acordo com as gramas do alimento vamos calcular quanto de kcal, lip, ptn e cho ele tem
      const qtdGrama = alim.medidaCaseira
        ? alim.medidaCaseira.equivalenteGramas * alim.qtdMedCas!
        : alim.qtdGramas;
      kcalTotal += (alim.alimento.kcal * qtdGrama) / 100;
      lipTotal += (alim.alimento.lipidio * qtdGrama) / 100;
      ptnTotal += (alim.alimento.proteina * qtdGrama) / 100;
      choTotal += (alim.alimento.carboidrato * qtdGrama) / 100;
    });

    const lip = (vet.vetTotal * parseFloat(parametroAdequacao.lip)) / 100;
    const ptn = (vet.vetTotal * parseFloat(parametroAdequacao.ptn)) / 100;
    const cho = (vet.vetTotal * parseFloat(parametroAdequacao.cho)) / 100;

    const perKcalCalculated =
      vet.vetTotal > 0 ? (kcalTotal / vet.vetTotal) * 100 : 0;
    const perLipCalculated = lip > 0 ? (lipTotal / lip) * 100 : 0;
    const perPtnCalculated = ptn > 0 ? (ptnTotal / ptn) * 100 : 0;
    const perChoCalculated = cho > 0 ? (choTotal / cho) * 100 : 0;

    const newAdequacaoGeral = {
      ...adequacaoGeral,
      lip: lip,
      ptn: ptn,
      cho: cho,
      perKcal: perKcalCalculated,
      perLip: perLipCalculated,
      perPtn: perPtnCalculated,
      perCho: perChoCalculated,
    };

    setAdequacaoGeral(newAdequacaoGeral);

    const newAdequacaoGrafico = [
      {
        innerRadius: 70,
        data: [
          {
            id: 0,
            value: perLipCalculated,
            label: "Lip",
            color: "#b1eb34",
          },
          {
            id: 1,
            value: perPtnCalculated,
            label: "Ptn",
            color: "#34ebe1",
          },
          {
            id: 2,
            value: perChoCalculated,
            label: "Cho",
            color: "#ebd834",
          },
        ],
      },
    ];

    setAdequcaoGrafico(newAdequacaoGrafico);
  }, [vet.vetTotal, parametroAdequacao, alimentos]);

  return (
    <Container maxWidth="lg">
      <PatientSelectedCard patientId={id} showPaginaInicialButton={false}>
        <NovaAvaliacaoButton onClick={(av) => onNovaAvaliacao(av)} />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSave}
          sx={{ ml: 1 }}
        >
          Salvar
        </Button>
        <Button variant="text" sx={{ ml: 1 }}>
          Imprimir
        </Button>
      </PatientSelectedCard>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={7}>
          <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
            <CardHeader title="Dias da Semana" />
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ToggleButtonGroup
                  disabled={!tipo}
                  value={diaSemana}
                  onChange={handleDiaSemana}
                >
                  <ToggleButton value="SEG" color="primary">
                    Segunda
                  </ToggleButton>
                  <ToggleButton value="TER" color="primary">
                    Terça
                  </ToggleButton>
                  <ToggleButton value="QUA" color="primary">
                    Quarta
                  </ToggleButton>
                  <ToggleButton value="QUI" color="primary">
                    Quinta
                  </ToggleButton>
                  <ToggleButton value="SEX" color="primary">
                    Sexta
                  </ToggleButton>
                  <ToggleButton value="SAB" color="primary">
                    Sábado
                  </ToggleButton>
                  <ToggleButton value="DOM" color="primary">
                    Domingo
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </CardContent>
          </Card>

          <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
            <CardHeader
              title="Refeições"
              action={
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!tipo}
                  onClick={openRefeicaoDialog}
                  style={{ color: "white" }}
                >
                  Adicionar
                </Button>
              }
            />

            <CardContent>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={refeicao}
                onChange={handleChange}
              >
                {refeicoes.map((ref, index) => (
                  <Tab
                    key={`tab-${index}`}
                    label={`${ref.nome} - ${ref.horario}`}
                  />
                ))}
              </Tabs>
              {refeicoes.length > 0 ? (
                <div role="tabpanel">
                  <List>
                    {refeicoes[refeicao] &&
                      alimentos
                        .get(refeicoes[refeicao].id!)
                        ?.map((alimento, index) => (
                          <ListItem key={`index-${index}`}>
                            <ListItemText
                              primary={alimento.alimento.nome}
                              secondary={
                                alimento.medidaCaseira
                                  ? `Quantidade de ${alimento.qtdMedCas} ${alimento.medidaCaseira?.nome}`
                                  : `Quantidade de ${alimento.qtdGramas}g`
                              }
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() => removeAlimento(alimento)}
                              >
                                <Delete color="action" />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                    <ListItem key={`index-botao`}>
                      <ButtonBase
                        onClick={() => setIsAlimentooDialogOpened(true)}
                        disabled={!tipo}
                        sx={{ width: "100%" }}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="row"
                        >
                          <Add sx={{ color: "text.disabled" }} />
                          <Typography variant="h6" color="text.disabled">
                            Adicionar alimento
                          </Typography>
                        </Box>
                      </ButtonBase>
                    </ListItem>
                  </List>
                </div>
              ) : (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  textAlign="center"
                >
                  Nenhuma refeição cadastrada
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={0} md={5}>
          <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
            <CardHeader
              title="Adequação"
              action={
                <Button
                  variant="outlined"
                  onClick={() => setIsAdequacaoGeralDialogOpened(true)}
                >
                  Ver mais
                </Button>
              }
            />
            <CardContent>
              {vet.vetTotal ? (
                <Box>
                  <PieChart series={adequcaoGrafico} width={400} height={200} />
                  <Grid
                    container
                    spacing={1}
                    mt={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item xs={1}>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        fontSize="0.8em"
                        fontWeight="bold"
                      >
                        Kcal
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <LinearProgress
                        variant="determinate"
                        value={(adequacaoGeral.perKcal / vet.vetTotal) * 100}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        fontSize="0.8em"
                        textAlign="right"
                      >
                        {adequacaoGeral.perKcal.toFixed(0)}/
                        {vet.vetTotal.toFixed(0)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item xs={1}>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        fontSize="0.8em"
                        fontWeight="bold"
                      >
                        Cho
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <LinearProgressCho
                        variant="determinate"
                        value={
                          (adequacaoGeral.perCho / adequacaoGeral.cho) * 100
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        fontSize="0.8em"
                        textAlign="right"
                      >
                        {adequacaoGeral.perCho.toFixed(0)}/
                        {adequacaoGeral.cho.toFixed(0)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item xs={1}>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        fontSize="0.8em"
                        fontWeight="bold"
                      >
                        Lip
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <LinearProgressLip
                        variant="determinate"
                        value={
                          (adequacaoGeral.perLip / adequacaoGeral.lip) * 100
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        fontSize="0.8em"
                        textAlign="right"
                      >
                        {adequacaoGeral.perLip.toFixed(0)}/
                        {adequacaoGeral.lip.toFixed(0)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item xs={1}>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        fontSize="0.8em"
                        fontWeight="bold"
                      >
                        Ptn
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <LinearProgressPtn
                        variant="determinate"
                        value={
                          (adequacaoGeral.perPtn / adequacaoGeral.ptn) * 100
                        }
                        classes={{ colorPrimary: "#ebd834" }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        fontSize="0.8em"
                        textAlign="right"
                      >
                        {adequacaoGeral.perPtn.toFixed(0)}/
                        {adequacaoGeral.ptn.toFixed(0)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    textAlign="center"
                  >
                    O plano ainda não possui configuração. Acesse o botão ver
                    mais para configurar.
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DietaAdequacaoGeralDialog
        open={isAdequacaoGeralDialogOpened}
        onClose={(result) => {
          setIsAdequacaoGeralDialogOpened(false);
          setVet({
            vet: result.vet,
            vetTotal: result.vetTotal,
            vetAjuste: result.vetAjuste,
            sinalAcum: result.sinalAcum,
          });
          setParametroAdequacao(result.parametroAdequacao);
          setAdequacaoGeral(result.adequacaoGeral);
        }}
        currentVet={vet.vet}
        currentVetTotal={vet.vetTotal}
        currentVetAjuste={vet.vetAjuste}
        currentSinalAcum={vet.sinalAcum}
        currentParametroAdequacao={parametroAdequacao}
        currentAdequacaoGeral={adequacaoGeral}
        currentAlimentos={alimentos}
      />

      <DietaRefeicaoDialog
        open={isRefeicaoDialogOpened}
        dietaId={avaliacaoId!}
        onAdd={addRefeicao}
        onClose={() => seIsRefeicaoDialogOpened(false)}
      />
      <DietaRefeicaoAlimentoDialog
        open={isAlimentooDialogOpened}
        tipo={tipo!}
        refeicao={refeicoes[refeicao]}
        onClose={() => setIsAlimentooDialogOpened(false)}
        onAdd={addAlimento}
      />

      <SelecionarRecomendacaoDialog
        open={isSelecionarRecomendacaoDialogOpen}
        onClose={() => setIsSelecionarRecomendacaoDialogOpen(false)}
        onSelect={onSelectRecomendacao}
      />

      <SelecionarPerguntaDialog
        open={isSelecionarAnamneseDialogOpen}
        onClose={() => setIsSelecionarAnamneseDialogOpen(false)}
        onSelect={onSelectAnamnese}
      />
    </Container>
  );
};

export default EvaluationDietPage;
