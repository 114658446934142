import { Avatar, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { LOGIN, PERFIL } from "./AppRoutes";
import { useAuth } from "../hooks/AuthHook";
import LogoutIcon from "@mui/icons-material/Logout";

const UserMenu: React.FC = () => {
  const theme = useTheme();
  const { isAuthenticated, userLogged, singout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loggedUserName, setLoggedUserName] = React.useState<string>();

  useEffect(() => {
    if (!isAuthenticated()) return;
    setLoggedUserName(userLogged()?.primeiroNome);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    singout();
    navigate(LOGIN);
  };

  // const handlePerfil = () => {
  //   setAnchorEl(null);
  //   navigate(PERFIL);
  // };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span style={{ color: "white" }}>Olá, {loggedUserName}</span>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginRight: 10,
        }}
        onClick={handleClick}
      >
        <Avatar
          alt={loggedUserName}
          sx={{ width: 40, height: 40, ml: "10px", backgroundColor: "white" }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: "primary.main" }}
          >
            {loggedUserName?.charAt(0) ?? "E"}
          </Typography>
        </Avatar>
        <ArrowDropDownIcon sx={{ color: "white" }} />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ width: "170px" }}
      >
        {/* <MenuItem
          onClick={() => {
            handlePerfil();
          }}
          style={{ color: theme.palette.secondary.main }}
        >
          Perfil
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            handleLogout();
          }}
          style={{ color: theme.palette.secondary.main }}
        >
          Sair
          <LogoutIcon color="primary" style={{ marginLeft: 3 }} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
