import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React from "react";

interface FilterDialogCustomProps {
  open: boolean;
  children?: React.ReactNode;
  onClear: () => void;
  onFilter: () => void;
  onClose: () => void;
}

const FilterDialogCustom: React.FC<FilterDialogCustomProps> = ({
  open,
  children,
  onClear,
  onFilter,
  onClose,
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => onClose()}>
      <DialogTitle variant="h6" color="secondary">
        Filtre por:
      </DialogTitle>
      <DialogContent>
        <Grid container p={1} spacing={1} rowSpacing={2}>
          {children}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClear();
            onClose();
          }}
        >
          Limpar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onFilter();
            onClose();
          }}
          style={{ color: "white" }}
        >
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialogCustom;
