import { Container } from "@mui/material";
import React from "react";

interface ContainerCustomProps {
  children: JSX.Element[] | JSX.Element;
}

const ContainerCustom: React.FC<ContainerCustomProps> = ({ children }) => {
  return <Container sx={{mb: 2}}>{children}</Container>;
};

export default ContainerCustom;
