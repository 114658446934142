import api from "../../shared/utils/Api";
import { ODataResult } from "../../shared/models/ODataResult";
import { AgrupadorPerguntaRequest } from "../models/AgrupadorPerguntaRequest";

export const getAllPerguntas = async (
  page?: number,
  numPerPage?: number,
  nome?: string
): Promise<ODataResult<AgrupadorPerguntaRequest[]>> => {
  try {
    const response = await api.get<ODataResult<AgrupadorPerguntaRequest[]>>(
      "agrupadorPergunta",
      {
        params: {
          take: numPerPage ?? undefined,
          skip: page ? page * numPerPage! : undefined,
          nome: nome,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Falha ao consultar perguntas");
  }
};

export const getPerguntaById = async (
  id: string
): Promise<AgrupadorPerguntaRequest> => {
  try {
    const response = await api.get<AgrupadorPerguntaRequest>(
      `agrupadorPergunta/${id}/odata`,
      {
        params: {
          $expand: `perguntas`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Falha ao consultar pergunta");
  }
};

export const createPerguntas = async (request: AgrupadorPerguntaRequest) => {
  try {
    const response = await api.post("agrupadorPergunta", request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao criar pergunta");
  }
};

export const editPerguntas = async (
  id: string,
  request: AgrupadorPerguntaRequest
) => {
  try {
    const response = await api.put(`agrupadorPergunta/${id}`, request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao atualizar pergunta");
  }
};

export const deletePerguntas = async (id: string) => {
  try {
    const response = await api.delete(`agrupadorPergunta/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteAllPerguntas = async (ids: string[]) => {
  try {
    const response = await api.delete(`agrupadorPergunta`, {
      data: ids,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
