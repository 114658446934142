import api from "../../shared/utils/Api";
import { ODataResult } from "../../shared/models/ODataResult";
import { AgrupadorRecomendacao } from "../models/AgrupadorRecomendacao";

export const getAllAgrupadorRecomendacao = async (
  page?: number,
  numPerPage?: number,
  filter?: string
): Promise<ODataResult<AgrupadorRecomendacao[]>> => {
  try {
    const response = await api.get<ODataResult<AgrupadorRecomendacao[]>>(
      "agrupadorRecomendacao/odata",
      {
        params: {
          $count: true,
          $top: numPerPage ?? undefined,
          $skip: page ? page * numPerPage! : undefined,
          $expand: `recomendacoes`,
          $orderby: "titulo",
          $filter: filter ? filter : undefined,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Falha ao consultar recomendações");
  }
};

export const getAgrupadorRecomendacaoById = async (
  id: string
): Promise<AgrupadorRecomendacao> => {
  try {
    const response = await api.get<AgrupadorRecomendacao>(
      `agrupadorRecomendacao/${id}/odata`,
      {
        params: {
          $expand: `recomendacoes($orderby=ordem asc)`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Falha ao consultar recomendação");
  }
};

export const createAgrupadorRecomendacao = async (
  request: AgrupadorRecomendacao
) => {
  try {
    const response = await api.post("agrupadorRecomendacao", request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao criar recomendação");
  }
};

export const editAgrupadorRecomendacao = async (
  id: string,
  request: AgrupadorRecomendacao
) => {
  try {
    const response = await api.put(`agrupadorRecomendacao/${id}`, request);
    return response.data;
  } catch (error) {
    throw new Error("Falha ao atualizar recomendação");
  }
};

export const deleteAgrupadorRecomendacao = async (id: string) => {
  try {
    const response = await api.delete(`agrupadorRecomendacao/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteAllAgrupadorRecomendacao = async (ids: string[]) => {
  try {
    const response = await api.delete(`agrupadorRecomendacao`, {
      data: ids,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
