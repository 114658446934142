import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { getAllAgrupadorRecomendacao } from "../../orientation/services/OrientationService";
import { AgrupadorRecomendacao } from "../../orientation/models/AgrupadorRecomendacao";
import { Check } from "@mui/icons-material";

interface SelecionarRecomendacaoDialogProps {
  open: boolean;
  onSelect: (recomendacaoId: string) => void;
  onClose: () => void;
}

const SelecionarRecomendacaoDialog: React.FC<
  SelecionarRecomendacaoDialogProps
> = ({ open, onSelect, onClose }) => {
  const [recomendacoes, setRecomendacoes] = React.useState<
    AgrupadorRecomendacao[]
  >([]);
  const [selected, setSelected] = React.useState<string>("");

  useEffect(() => {
    getAllAgrupadorRecomendacao()
      .then((data) => {
        setRecomendacoes(data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>
        <Typography variant="h6" color="secondary">
          Selecione uma Recomendação
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          {recomendacoes.map((recomendacao, index) => (
            <ListItemButton
              key={index}
              selected={selected === recomendacao.id}
              onClick={() => setSelected(recomendacao.id!)}
            >
              {selected === recomendacao.id ? (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              ) : undefined}
              <ListItemText
                primary={recomendacao.titulo}
                secondary={recomendacao.subTitulo}
              />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!selected}
          onClick={() => onSelect(selected)}
          style={{ color: "white" }}
        >
          Selecionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelecionarRecomendacaoDialog;
