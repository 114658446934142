import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { globalState } from "../states/GlobalState";
import { LOGIN } from "../components/AppRoutes";

export const baseURL = process.env.REACT_APP_BASE_URL ?? `https://app.dietyme.com.br/api/v1/`;

const api = axios.create({
  baseURL: baseURL,
  timeout: 1000 * 60 * 60,
});

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const token = localStorage.getItem("diety.token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    globalState.set({ ...globalState.value!, isLoading: true });
    return config;
  },
  (error: any) => {
    globalState.set({ ...globalState.value!, isLoading: false });
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (config: AxiosResponse<any, any>) => {
    globalState.set({ ...globalState.value!, isLoading: false });
    return config;
  },
  async (error: any) => {
    globalState.set({ ...globalState.value!, isLoading: false });
    if (error.response?.status === 401) {
      localStorage.clear();

      window.location.href = LOGIN;
    } else {
      return Promise.reject(error.response?.data.message);
    }
  }
);

export default api;
