import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/AuthHook";
import { LOGIN } from "./AppRoutes";

export function ProtectedRoute({ children }: any) {
    const {isAuthenticated, singout} = useAuth();

    if (!isAuthenticated()) {
        singout();
        return <Navigate to={LOGIN} replace />;
    }

    return children;
}