import { FormikProps } from "formik";
import CardCustom from "../components/CardCustom";
import ContainerCustom from "../components/ContainerCustom";

interface IFormPageProps {
  title: string;
  formik: FormikProps<any>;
  actions: JSX.Element;
  children: React.ReactNode;
}

export function FormPage({
  title,
  formik,
  actions,
  children,
}: IFormPageProps): JSX.Element {
  return (
    <ContainerCustom>
      <CardCustom title={title}>
        <form onSubmit={formik.handleSubmit}>
          {children}
          {actions}
        </form>
      </CardCustom>
    </ContainerCustom>
  );
}
