import { FormikProps } from "formik";
import React from "react";
import { errorText } from "../utils/InputUtil";
import { DatePicker, MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";

interface DateTimeInputProps {
  label: string;
  name: string;
  required?: boolean;
  formik: FormikProps<any>;
}

const DateTimeInput: React.FC<DateTimeInputProps> = ({
  label,
  name,
  required,
  formik,
}) => {
  const onChange = (e: any) => {
    formik.setFieldValue(name, e ? e.toISOString() : "");
  };

  return (
    <MobileDateTimePicker
      label={label}
      name={name}
      format="DD/MM/YYYY HH:mm"
      value={formik.values[name] ? moment(formik.values[name]) : null}
      onChange={(e) => onChange(e)}
      slotProps={{
        textField: {
          required: required,
          fullWidth: true,
          onBlur: formik.handleBlur,
          helperText: errorText(formik, name),
          error:
            formik.touched.dataNascimento &&
            Boolean(formik.errors.dataNascimento),
        },
      }}
    />
  );
};

export default DateTimeInput;
