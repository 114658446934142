import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Check } from "@mui/icons-material";
import { AgrupadorPerguntaRequest } from "../../pergunta/models/AgrupadorPerguntaRequest";
import { getAllPerguntas } from "../../pergunta/services/PerguntaService";

interface SelecionarPerguntaDialogProps {
  open: boolean;
  onSelect: (perguntaId: string) => void;
  onClose: () => void;
}

const SelecionarPerguntaDialog: React.FC<SelecionarPerguntaDialogProps> = ({
  open,
  onSelect,
  onClose,
}) => {
  const [perguntas, setPerguntas] = React.useState<AgrupadorPerguntaRequest[]>(
    []
  );
  const [selected, setSelected] = React.useState<string>("");

  useEffect(() => {
    getAllPerguntas()
      .then((data) => {
        setPerguntas(data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>
        <Typography variant="h6" color="secondary">
          Selecione uma Anamnese
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          {perguntas.map((anamnese, index) => (
            <ListItemButton
              key={index}
              selected={selected === anamnese.id}
              onClick={() => setSelected(anamnese.id!)}
            >
              <ListItemIcon>
                {selected === anamnese.id ? <Check /> : undefined}
              </ListItemIcon>
              <ListItemText
                primary={anamnese.titulo}
                secondary={anamnese.subTitulo}
              />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!selected}
          onClick={() => onSelect(selected)}
          style={{ color: "white" }}
        >
          Selecionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelecionarPerguntaDialog;
