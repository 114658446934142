import { Enum } from "../models/Enum";

export class EtniaEnum extends Enum {
  constructor() {
    super(
      new Map<string, string>([
        ["BRANCO", "Branco"],
        ["PARDO", "Pardo"],
        ["NEGRO", "Negro"],
        ["OUTROS", "Outros"],
      ]),
    );
  }
}
